import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createLogger, INFO } from 'browser-bunyan';

import { LaunchDarklyFlags } from 'app/core/services/launch-darkly/launch-darkly.types';
import { getFeatureFlagObservable } from 'app/core/state';
import { isInDev } from 'app/core/utilities/env-util';
import { AppState } from 'app/types';
import { isBrowser, isJestRunning } from 'app/utils';
import { CustomConsoleFormattedStream } from 'app/utils/logging/console-stream';
import { ServerStream } from 'app/utils/logging/server-stream';

type JSONValue = string | number | boolean | null | JSONValue[] | { [key: string]: JSONValue };
type Context = Record<string, JSONValue>;

type StreamType = 'console' | 'server';

const logContext: Context = {};

class Logger {
  public static shared = new Logger();
  public log: ReturnType<typeof createLogger>;
  private serverLogging = false;
  private consoleLogging = false;

  public constructor() {
    this.log = createLogger({
      name: 'logger',
      streams: [],
    });

    if (isInDev()) {
      this.addConsoleStream();
    }
  }

  public toggleConsoleLogging(): void {
    if (this.consoleLogging) {
      this.removeConsoleStream();
    } else {
      this.addConsoleStream();
    }
  }

  public toggleServerLogging(): void {
    if (this.serverLogging) {
      this.removeServerStream();
    } else {
      this.addServerStream();
    }
  }

  public addConsoleStream(): void {
    if (isJestRunning()) {
      return;
    }
    if (this.consoleLogging) {
      return;
    }
    const stream = new CustomConsoleFormattedStream({ logByLevel: true });

    // @ts-expect-error This is wrong in the typings for the library, it takes a stream argument
    this.log.addStream({ level: INFO, stream, _type: 'console' });
    this.consoleLogging = true;
    this.log.info('Enabled console log stream');
  }

  public addServerStream(): void {
    if (isJestRunning() || !isBrowser) {
      return;
    }

    if (this.serverLogging) {
      return;
    }
    const stream = new ServerStream();

    // @ts-expect-error This is wrong in the typings for the library, it takes a stream argument
    this.log.addStream({ level: INFO, stream, _type: 'server' });
    this.serverLogging = true;
  }

  public removeConsoleStream() {
    this.removeStream('console');
    this.consoleLogging = false;
  }

  public removeServerStream() {
    this.removeStream('server');
    this.serverLogging = false;
  }

  private removeStream(type: StreamType) {
    this.log.info(`Disabling ${type} log stream`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error The streams property stores the current streams the logger has. Bunyan doesn't provide an interface for removal
    this.log.streams = this.log.streams.filter(({ _type, stream }) => {
      // Ensure the removed stream is stopped if it provides that interface
      if (_type === type && 'stop' in stream && typeof stream.stop === 'function') {
        stream.stop();
      }
      return _type !== type;
    });
  }
}

/**
 * Safely stringify log arguments.
 * @param args An array of arguments to be logged
 * @returns {args: string[], errors: string[]}
 */
const processLogArgs = (args: unknown[]): { args: string[]; errors: string[] } => {
  const errors: string[] = [];
  const argStr = args.map((o) => {
    // Error objects need special handling to not become empty objects at log time
    if (o instanceof Error) {
      const errStr = o.toString();
      errors.push(errStr);
      return errStr;
    }
    return JSON.stringify(o);
  });

  return { args: argStr, errors };
};

type LogMethod = (arg0: string | Context, ...args: unknown[]) => void;

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private logger?: Logger;

  public constructor(store: Store<AppState>) {
    if (!isBrowser()) {
      return;
    }
    this.logger = Logger.shared;
    getFeatureFlagObservable(store, LaunchDarklyFlags.console_logging).subscribe((consoleLogging) => {
      if (consoleLogging) {
        this.logger?.addConsoleStream();
      } else {
        this.logger?.removeConsoleStream();
      }
    });
    getFeatureFlagObservable(store, LaunchDarklyFlags.server_logging).subscribe((serverLogging) => {
      if (serverLogging) {
        this.logger?.addServerStream();
      } else {
        this.logger?.removeServerStream();
      }
    });
  }

  public toggleConsole(): void {
    this.logger?.toggleConsoleLogging();
  }

  public toggleServerLogging(): void {
    this.logger?.toggleServerLogging();
  }

  public setContextAttribute(name: string, value: JSONValue): void {
    logContext[name] = value;
  }

  // level 10
  public trace(msg: string, ...args: unknown[]): void;
  public trace(context: Context, msg: string, ...args: unknown[]): void;
  public trace(arg0: string | Context, ...args: unknown[]): void {
    const logFunc = this.logger?.log.trace.bind(this.logger?.log);
    if (logFunc) {
      this.log(logFunc, arg0, ...args);
    }
  }

  // level 20
  public debug(msg: string, ...args: unknown[]): void;
  public debug(context: Context, msg: string, ...args: unknown[]): void;
  public debug(arg0: string | Context, ...args: unknown[]): void {
    const logFunc = this.logger?.log.debug.bind(this.logger.log);
    if (logFunc) {
      this.log(logFunc, arg0, ...args);
    }
  }

  // level 30
  public info(msg: string, ...args: unknown[]): void;
  public info(context: Context, msg: string, ...args: unknown[]): void;
  public info(arg0: string | Context, ...args: unknown[]): void {
    const logFunc = this.logger?.log.info.bind(this.logger.log);
    if (logFunc) {
      this.log(logFunc, arg0, ...args);
    }
  }

  // level 40
  public warn(msg: string, ...args: unknown[]): void;
  public warn(context: Context, msg: string, ...args: unknown[]): void;
  public warn(arg0: string | Context, ...args: unknown[]): void {
    const logFunc = this.logger?.log.warn.bind(this.logger.log);
    if (logFunc) {
      this.log(logFunc, arg0, ...args);
    }
  }

  // level 50
  public error(msg: string, ...args: unknown[]): void;
  public error(context: Context, msg: string, ...args: unknown[]): void;
  public error(arg0: string | Context, ...args: unknown[]): void {
    const logFunc = this.logger?.log.error.bind(this.logger.log);
    if (logFunc) {
      this.log(logFunc, arg0, ...args);
    }
  }

  // level 60
  public fatal(msg: string, ...args: unknown[]): void;
  public fatal(context: Context, msg: string, ...args: unknown[]): void;
  public fatal(arg0: string | Context, ...args: unknown[]): void {
    const logFunc = this.logger?.log.fatal.bind(this.logger.log);
    if (logFunc) {
      this.log(logFunc, arg0, ...args);
    }
  }

  public log(logMethod: LogMethod, arg0: string | Context, ...args: unknown[]): void {
    const { args: stringArgs, errors } = processLogArgs(args);
    let { context } = this;
    if (errors.length) {
      context = { ...context, errors };
    }

    if (typeof arg0 === 'string') {
      logMethod(context, arg0, args);
      return;
    }

    const [msg, ...logArgs] = stringArgs;
    logMethod({ ...context, ...arg0 }, msg, ...logArgs);
  }

  private get context(): Record<string, JSONValue> {
    return logContext;
  }
}
