import User from 'entity/User';
import { MirrorType } from 'messages/websocket.messages';

/**
 * Construct a mirror ID string.
 *
 * @param user The mirror user
 * @param type The type of mirror subscription
 * @returns A mirrorId for that user and type
 */
export const createMirrorId = (user: User, type: MirrorType): string => {
  return `${user.userId}:${type}`;
};

/**
 * Get userId from mirror id.
 *
 * @param mirrorId The mirror user
 */
export const getUserIdByMirrorId = (mirrorId: string): string => {
  return mirrorId.split(':')[0];
};

/**
 * Construct a recording ID string.
 *
 * @param socialId The Id of the current social
 * @param companyId The id of the current company
 * @returns A recordingId
 */
export const createRecordingId = ({ companyId, socialId }: { socialId: string; companyId: string }): string => {
  return `${companyId}/${socialId}_${Date.now()}/recording`;
};
