import { Action, on } from '@ngrx/store';

import { createImmerReducer } from 'app/utils/createImmerReducer';

import * as actions from './actions';
import { ScootaversePageState } from './types';

export const SCOOTAVERSE_PAGE_SIZE = 20;
export const ROOM_PAGE_SIZE = 20;

export const initialState: ScootaversePageState = {
  scootaverses: {
    data: [],
    page: 0,
    size: SCOOTAVERSE_PAGE_SIZE,
    loading: false,
    hasMore: true,
  },
  rooms: {
    data: [],
    page: 0,
    size: ROOM_PAGE_SIZE,
    loading: false,
    hasMore: true,
  },
  scootaverseId: '',
};

const _reducer = createImmerReducer(
  initialState,

  on(actions.INIT, (_: ScootaversePageState) => {
    return initialState;
  }),

  //SCOOTAVERSE ACTIONS
  on(actions.FETCH_SCOOTAVERSES_REQ, (state: ScootaversePageState, { page, size }) => {
    const { scootaverses } = state;
    if (page === 0) {
      scootaverses.hasMore = true;
      scootaverses.data = [];
    }
    scootaverses.page = page;
    scootaverses.size = size;
    scootaverses.loading = true;
    return state;
  }),

  on(actions.FETCH_SCOOTAVERSES_RSP, (state: ScootaversePageState, { roomSets }) => {
    const { scootaverses } = state;

    if (roomSets.length < scootaverses.size) {
      scootaverses.hasMore = false;
    }

    if (scootaverses.page === 0) {
      scootaverses.data = roomSets;
    } else {
      scootaverses.data = [...scootaverses.data, ...roomSets];
    }
    scootaverses.loading = false;
    return state;
  }),

  on(actions.FETCH_SCOOTAVERSES_ERR, (state: ScootaversePageState) => {
    const { scootaverses } = state;
    scootaverses.loading = false;
    return state;
  }),

  // ROOMS ACTIONS
  on(actions.SET_SELECTED_SCOOTAVERSE, (state: ScootaversePageState, { scootaverseId }) => {
    state.scootaverseId = scootaverseId;
    return state;
  }),
);

export const scootaversePageReducer = (
  state: ScootaversePageState | undefined,
  action: Action,
): ReturnType<typeof _reducer> => _reducer(state, action);
