<!-- For User Icon -->
<div
  class="flex items-center justify-center"
  *ngIf="user && addPicture == false"
  [ngStyle]="{ width: responsive ? '100%' : sizeProp100, height: responsive ? '100%' : sizeProp100 }"
>
  <img
    class="profile-picture"
    [ngStyle]="{
      width: responsive ? '100%' : sizeProp100,
      height: responsive ? '100%' : sizeProp100,
      'border-color': '#ffffff',
      border: profileBorder
    }"
    *ngIf="user.profileImageURI && !imageHasError; else showAvatar"
    alt="{{ user.name }} profile picture"
    [src]="size ? (user.profileImageURI | imageSize: size : size) : user.profileImageURI"
    (error)="onImgError($event)"
  />
  <ng-template #showAvatar>
    <div
      class="avatar-header flex items-center justify-center"
      [ngStyle]="
        isReady
          ? {
              'background-color': avatarBackground,
              width: responsive ? '100%' : sizeProp100,
              height: responsive ? '100%' : sizeProp100,
              border: profileBorder,
              'line-height': sizeProp90
            }
          : null
      "
      attr.aria-label="{{ user.name }} profile avatar"
    >
      <div [ngStyle]="isReady ? { height: sizeProp50 } : null">
        <p
          *ngIf="user.firstName || user.lastName"
          class="avatar-text"
          [ngStyle]="{ 'font-size': initialsSizing, 'line-height': initialsSizing }"
        >
          {{ user.firstName ? user.firstName[0] : '' }}{{ user.lastName ? user.lastName[0] : '' }}
        </p>
      </div>
    </div>
  </ng-template>
</div>

<!-- For Complete Profile appearance -->
<div *ngIf="addPicture">
  <div *ngIf="user">
    <img
      class="profile-picture"
      title="profile picture"
      [ngStyle]="{
        width: sizeProp100,
        height: sizeProp100,
        border: profileBorder
      }"
      *ngIf="user.profileImageURI && !imageHasError; else showAvatar"
      [src]="user.profileImageURI"
      (error)="onImgError($event)"
    />
    <ng-template #showAvatar>
      <div
        class="avatar-header"
        [ngStyle]="
          isReady
            ? {
                'background-color': avatarBackground,
                width: sizeProp100,
                height: sizeProp100,
                border: profileBorder,
                'line-height': sizeProp90
              }
            : null
        "
        aria-label="profile avatar"
      >
        <svg width="60" height="60" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M54.5504 19.8459C54.5504 9.39067 46.0765 0.916668 35.6212 0.916668C25.166 0.916668 16.692 9.39067 16.692 19.8459C16.692 20.3791 16.692 22.4676 16.692 23.0008C16.692 33.4561 25.166 41.9301 35.6212 41.9301C46.0765 41.9301 54.5504 33.4561 54.5504 23.0008C54.5504 22.4676 54.5504 20.3791 54.5504 19.8459ZM69.1417 61.763C64.4757 55.6993 48.2534 48.8595 35.6149 48.8595C22.9765 48.8595 6.76041 55.6962 2.09435 61.763C-0.789213 65.5141 1.95869 70.9437 6.68785 70.9437C6.68785 70.9437 59.8191 70.9437 64.5482 70.9437C69.2774 70.9437 72.0253 65.5141 69.1417 61.763Z"
            fill="white"
          />
        </svg>
        <img title="plus icon" src="assets/images/Plus.svg" class="plus-icon" />
      </div>
    </ng-template>
  </div>
</div>
