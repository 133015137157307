import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMaskModule } from 'ngx-mask';

import { CommonsModule } from '../commons/commons.module';

import { AppVersionComponent } from './app-version/app-version.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { DotsComponent } from './layout/dots/dots.component';
import { PageLayoutComponent } from './layout/page-layout/page-layout.component';
import { NavItemsComponent } from './nav-items/nav-items.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { ScootHeaderComponent } from './scoot-header/scoot-header.component';
import { AwsService } from './services/aws.service';
import { FooterService } from './services/footer.service';
import { NavigationMenuService } from './services/navigation-menu.service';
import { CoreEffects } from './state/effects';
import { SystemMessageComponent } from './system-message/system-message.component';
import { ImageSizePipe } from './utilities/image-size-pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatDialogModule,
    MatCardModule,
    MatSelectModule,
    MatMenuModule,
    NgxMaskModule,
    CommonsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatRippleModule,
    EffectsModule.forFeature([CoreEffects]),
    MarkdownModule.forRoot(),
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    DotsComponent,
    PageLayoutComponent,
    ProfilePictureComponent,
    ImageSizePipe,
    SystemMessageComponent,
    PageNotFoundComponent,
    AppVersionComponent,
    // Scootaverse Core Components
    ScootHeaderComponent,
    NavItemsComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    DotsComponent,
    PageLayoutComponent,
    ProfilePictureComponent,
    ImageSizePipe,
    SystemMessageComponent,
    PageNotFoundComponent,
    AppVersionComponent,
    // Scootaverse Core Components
    ScootHeaderComponent,
    NavItemsComponent,
  ],
  providers: [FooterService, AwsService, NavigationMenuService],
})
export class CoreModule {}
