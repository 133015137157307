import { Injectable } from '@angular/core';

import { Loggable } from 'app/utils/logging/loggable';

@Injectable()
export class LocalStorageService extends Loggable {
  public constructor() {
    super();
    this.setDisplayName('LocalStorageService');
  }

  public store(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public retrieve<T>(key: string, rehydrate: (value: string) => T): T | undefined {
    try {
      const item = localStorage.getItem(key);
      if (!item) {
        return undefined;
      }

      return rehydrate(item);
    } catch (e) {
      this.error('Error retrieving item', e);
      return undefined;
    }
  }

  public clearItem(key: string): void {
    localStorage.removeItem(key);
  }
}
