import { createAction, props } from '@ngrx/store';

import CTAItem from 'entity/CTAItem';
import GuestUserRoomSettings from 'entity/GuestUserRoomSettings';
import NavigationItem from 'entity/NavigationItem';
import Social from 'entity/Social';
import SocialArtifact from 'entity/SocialArtifact';
import SocialRoomSet from 'entity/SocialRoomSet';
import SocialSettings from 'entity/SocialSettings';
import StartingPoint from 'entity/StartingPoint';
import User from 'entity/User';
import { BaseUser, SocialEventType, SocialState } from 'messages/websocket.messages';

import { StageSizeType } from '../../performer-participant/performer-participant.types';
import { GalleryTabs } from '../../presentation/presentation-drawer/gallery/constants';
import { VisualIndicatorInfo } from '../../services/ring-colors/ring-colors.service';
import { PresenterVideoQuality } from '../../types/media';

import { ConnectedRoomCounts, PopOutMode, SubscriptionStatus, VideoEffect } from './types';

export const INIT = createAction('Social/INIT');

export const LEAVE = createAction('Social/LEAVE');

export const RECEIVE_SHARED_STATE = createAction(
  'Social/app/RECEIVE_SHARED_STATE',
  props<{ sharedState: SocialState; socialEvent: SocialEventType; region?: string }>(),
);

// TODO: once Emilio's work is in, we may elect to rename actions to better describe flow
export const LOAD_SOCIAL = createAction('Social/app/LOAD_SOCIAL', props<{ social: Social }>());
export const UPDATE_SOCIAL = createAction('Social/app/UPDATE_SOCIAL', props<{ social: Social }>());
export const JOIN_SOCIAL = createAction('Social/app/JOIN_SOCIAL', props<{ social: Social }>());
export const START_SOCIAL = createAction('Social/app/START_SOCIAL', props<{ social: Social }>());
export const LEAVE_SOCIAL = createAction('Social/app/LEAVE_SOCIAL');
export const ROOM_STARTED = createAction('Social/app/ROOM_STARTED', props<{ websocketUrl: string }>());

export const CHANGE_PLAYLIST = createAction('Social/app/CHANGE_PLAYLIST', props<{ playlistId: string }>());

export const UPDATE_CONNECTED_ROOM_COUNT = createAction(
  'Social/app/UPDATE_CONNECTED_ROOM_COUNT',
  props<{ updatedRoomCounts: ConnectedRoomCounts }>(),
);
export const SELECT_CONNECTED_ROOM = createAction('Social/app/SELECT_CONNECTED_ROOM', props<{ social: Social }>());

export const LOAD_CONNECTED_ROOM_SET = createAction(
  'Social/app/LOAD_CONNECTED_ROOM_SET',
  props<{ set?: SocialRoomSet }>(),
);
export const LOAD_CONNECTED_ROOM_SET_ERR = createAction(
  'Social/app/LOAD_CONNECTED_ROOM_SET_ERR',
  props<{ error: unknown }>(),
);

export const ADD_FULL_CLUSTER = createAction('Social/app/ADD_FULL_CLUSTER', props<{ groupId: number }>());
export const REMOVE_FULL_CLUSTER = createAction('Social/app/REMOVE_FULL_CLUSTER', props<{ groupId: number }>());

export const CHANGE_SUBSCRIPTION_STATUS = createAction(
  'Social/app/CHANGE_SUBSCRIPTION_STATUS',
  props<{ status: SubscriptionStatus }>(),
);

export const REMOVE_SUBSCRIPTION = createAction('Social/app/REMOVE_SUBSCRIPTION', props<{ subscriptionId: string }>());

// A participant has joined
export const ADD_PARTICIPANT = createAction(
  'Social/app/ADD_PARTICIPANT',
  props<{ userId: string; camera: boolean; mic: boolean }>(),
);

export const SET_PARTICIPANT_VOLUME = createAction(
  'Social/app/SET_PARTICIPANT_VOLUME',
  props<{ volume: number; userId: string }>(),
);

export const SET_MIX_VOLUME = createAction('Social/app/SET_MIX_VOLUME', props<{ volume: number }>());

// A participant has left
export const REMOVE_PARTICIPANT = createAction('Social/app/REMOVE_PARTICIPANT', props<{ userId: string }>());

// All participants have been removed
export const REMOVE_ALL_PARTICIPANTS = createAction('Social/app/REMOVE_ALL_PARTICIPANTS');

// Indicates the user has changed their audio mute state.
export const PARTICIPANT_AUDIO_MUTE_CHANGE = createAction(
  'Social/app/PARTICIPANT_AUDIO_MUTE_CHANGE',
  props<{ userId: string; muted: boolean }>(),
);

// Indicates the user has changed their video mute state.
export const PARTICIPANT_VIDEO_MUTE_CHANGE = createAction(
  'Social/app/PARTICIPANT_VIDEO_MUTE_CHANGE',
  props<{ userId: string; muted: boolean }>(),
);

// Toggle Chat Menu
export const TOGGLE_CHAT = createAction('Social/app/TOGGLE_CHAT', props<{ enabled: boolean }>());

export const SET_AUDIENCE = createAction('Social/app/SET_AUDIENCE', props<{ audience: User[] }>());
export const SET_GALLERY = createAction('Social/app/SET_GALLERY', props<{ gallery: User[] | BaseUser[] }>());

export const SET_ARTIFACTS = createAction('Social/app/SET_ARTIFACTS', props<{ artifacts: SocialArtifact[] }>());
export const SET_CTA_ITEMS = createAction('Social/app/SET_CTA_ITEMS', props<{ ctaItems: CTAItem[] }>());
export const SET_NAVIGATION_ITEMS = createAction(
  'Social/app/SET_NAVIGATION_ITEMS',
  props<{ navItems: NavigationItem[] }>(),
);
export const SET_PRESENTATION_STAGE_SIZE = createAction(
  'Social/app/SET_PRESENTATION_STAGE_SIZE',
  props<{ stageSize: StageSizeType }>(),
);

// #region room settings
export const SET_SOCIAL_SETTINGS = createAction(
  'Social/app/SET_SOCIAL_SETTINGS',
  props<{ settings: SocialSettings }>(),
);
export const FETCH_ROOM_SETTINGS_REQ = createAction(
  'Social/app/FETCH_ROOM_SETTINGS_REQ',
  props<{ socialId: string }>(),
);
export const FETCH_ROOM_SETTINGS_RSP = createAction(
  'Social/app/FETCH_ROOM_SETTINGS_RSP',
  props<{ settings: SocialSettings }>(),
);
export const FETCH_ROOM_SETTINGS_ERR = createAction('Social/app/FETCH_ROOM_SETTINGS_ERR', props<{ error: unknown }>());

export const SAVE_ROOM_SETTINGS_REQ = createAction(
  'Social/app/SAVE_ROOM_SETTINGS_REQ',
  props<{ socialId: string; settings: SocialSettings }>(),
);
export const SAVE_ROOM_SETTINGS_RSP = createAction(
  'Social/app/SAVE_ROOM_SETTINGS_RSP',
  props<{ settings: SocialSettings }>(),
);
export const SAVE_ROOM_SETTINGS_ERR = createAction('Social/app/SAVE_ROOM_SETTINGS_ERR', props<{ error: unknown }>());

export const PUBLISH_STATE_CHANGE = createAction(
  'Social/app/PUBLISH_STATE_CHANGE',
  props<{ connectionState: RTCPeerConnectionState }>(),
);
// #endregion

//#region Guest Room Settings
export const FETCH_GUEST_ROOM_SETTINGS_REQ = createAction(
  'Social/app/FETCH_GUEST_ROOM_SETTINGS_REQ',
  props<{ socialId: string }>(),
);

export const FETCH_GUEST_ROOM_SETTINGS_RSP = createAction(
  'Social/app/FETCH_GUEST_ROOM_SETTINGS_RSP',
  props<{ settings: GuestUserRoomSettings }>(),
);

export const FETCH_GUEST_ROOM_SETTINGS_ERR = createAction(
  'Social/app/FETCH_GUEST_ROOM_SETTINGS_ERR',
  props<{ error: unknown }>(),
);

export const USE_CUSTOMER_API = createAction('Social/app/USE_CUSTOMER_API', props<{ api: boolean }>());
// #endregion

// #region Video Effects
export const TOGGLE_VIDEO_EFFECTS = createAction('Social/app/TOGGLE_VIDEO_EFFECTS', props<{ enabled: boolean }>());

export const APPLY_VIDEO_EFFECTS = createAction('Social/app/APPLY_VIDEO_EFFECTS', props<{ effect: VideoEffect }>());
// #endregion

// #region Presenter Video Quality
export const OVERRIDE_QUALITY = createAction(
  'Social/app/OVERRIDE_QUALITY',
  props<{ quality: PresenterVideoQuality }>(),
);

export const CLEAR_QUALITY_OVERRIDE = createAction('Social/app/CLEAR_QUALITY_OVERRIDE');
// #endregion

// #region Room Set
export const UPDATE_ROOM_SET_REQ = createAction('Social/app/UPDATE_ROOM_SET_REQ', props<{ roomSet: SocialRoomSet }>());
export const UPDATE_ROOM_SET_RSP = createAction('Social/app/UPDATE_ROOM_SET_RSP', props<{ roomSet: SocialRoomSet }>());
export const UPDATE_ROOM_SET_ERR = createAction('Social/app/UPDATE_ROOM_SET_ERR', props<{ error: unknown }>());

export const GET_STARTING_POINT_REQ = createAction(
  'Social/app/GET_STARTING_POINT_REQ',
  props<{ socialRoomSetId: string }>(),
);
export const GET_STARTING_POINT_RSP = createAction(
  'Social/app/GET_STARTING_POINT_RSP',
  props<{ startingPoint: StartingPoint }>(),
);
export const GET_STARTING_POINT_ERR = createAction('Social/app/GET_STARTING_POINT_ERR', props<{ error: unknown }>());

export const UPDATE_STARTING_POINT_REQ = createAction(
  'Social/app/UPDATE_STARTING_POINT_REQ',
  props<{ socialRoomSetId: string; startingPoint: StartingPoint }>(),
);
export const UPDATE_STARTING_POINT_RSP = createAction(
  'Social/app/UPDATE_STARTING_POINT_RSP',
  props<{ socialRoomSetId: string; startingPoint: StartingPoint }>(),
);
export const UPDATE_STARTING_POINT_ERR = createAction(
  'Social/app/UPDATE_STARTING_POINT_ERR',
  props<{ error: unknown }>(),
);

export const TOGGLE_BADGE_OPEN = createAction(
  'Social/app/TOGGLE_BADGE_OPEN',
  props<{ open: boolean; userId: string; isSelectedInViewport?: boolean; ringColor?: string }>(),
);

export const TOGGLE_SEARCH_PARTICIPANTS = createAction(
  'Social/app/TOGGLE_SEARCH_PARTICIPANTS',
  props<{ open: boolean }>(),
);

export const TOGGLE_MAP_OPEN = createAction('Social/app/TOGGLE_MAP_OPEN', props<{ open: boolean; userId: string }>());

export const UPDATE_POP_OUT = createAction('Social/app/UPDATE_POP_OUT', props<{ open: boolean; mode: PopOutMode }>());

export const UPDATE_BADGING_MODAL = createAction('Social/app/UPDATE_BADGING_MODAL', props<{ open: boolean }>());

export const VISUAL_INDICATOR = createAction(
  'Social/app/VISUAL_INDICATOR',
  props<{ visualIndicatorInfo: VisualIndicatorInfo }>(),
);

export const UPDATE_USER_IS_SPEAKING = createAction(
  'Social/app/UPDATE_USER_IS_SPEAKING',
  props<{ isSpeaking: boolean }>(),
);

export const UPDATE_ACTIVE_SPEAKERS = createAction(
  'Social/app/UPDATE_ACTIVE_SPEAKERS',
  props<{ activeSpeakers: string[] }>(),
);

export const UPDATE_WIDE_RECORDING = createAction('Social/app/UPDATE_WIDE_RECORDING', props<{ enabled: boolean }>());

export const UPDATE_GALLERY_TABS = createAction(
  'Social/app/UPDATE_GALLERY_TABS',
  props<{ galleryTabs: GalleryTabs[] }>(),
);
// #endregion
