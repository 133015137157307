import User from 'entity/User';

export const isTestUser = (user: User): boolean => {
  const email = user.emailAddress;
  const id = 'test-0001110';
  return email?.startsWith(id) ?? false;
};

const devHosts = ['bluezebra.preciate.com', 'dev.social-ops.net', 'preciatetab.ngrok.io', 'preciateweb.ngrok.io'];

const stagingHosts = ['staging.social-ops.net'];

const localHosts = ['localhost'];

export const isLocalEnv = (): boolean => localHosts.includes(window.location?.hostname);
export const isDevEnv = (): boolean => devHosts.includes(window.location?.hostname);
export const isStagingEnv = (): boolean => stagingHosts.includes(window.location?.hostname);

/**
 * Determine if we are in a non-prod environment
 */
export const isInDev = (): boolean => isLocalEnv() || isDevEnv() || isStagingEnv();

export const isInMicrosoftTeams = (): boolean => window.location?.pathname?.includes('msteams');
