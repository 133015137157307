import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler, Injector } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { combineReducers, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RecaptchaModule } from 'ng-recaptcha';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule, ToastNoAnimationModule } from 'ngx-toastr';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonsModule } from './commons/commons.module';
import { CoreModule } from './core/core.module';
import { coreReducer } from './core/state';
import { scootaversePageReducer } from './modules/scootaverse/state';
import { socialAppReducer } from './modules/social/state/app';
import { homePageReducer } from './modules/social/state/home-page';
import { mediaReducer } from './modules/social/state/media';
import { recordingPageReducer } from './recordings/state/recording-page';
import { GlobalErrorHandler } from './services/GlobalErrorHandler';
import { LocalStorageService } from './services/localStorage.service';
import { TermsService } from './services/terms.service';
import { SharedModule } from './shared/shared.module';
import { AppModuleServices } from './utils/app-module-util';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'preciate-app' }),
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    CommonsModule,
    DragDropModule,
    RecaptchaModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    ToastNoAnimationModule.forRoot(),
    StoreModule.forRoot({
      social: combineReducers({
        app: socialAppReducer,
        media: mediaReducer,
        homePage: homePageReducer,
        scootaversePage: scootaversePageReducer,
      }),
      recordingPage: recordingPageReducer,
      core: coreReducer,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
    EffectsModule.forRoot(),
    SharedModule,
    MatProgressBarModule,
  ],
  providers: [
    LocalStorageService,
    TermsService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: DeviceDetectorService,
      useClass: DeviceDetectorService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(private injector: Injector) {
    AppModuleServices.injector = this.injector;
  }
}
