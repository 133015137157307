// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import Image, { processImage } from "entity/Image";
import RequestStatus, { processRequestStatus } from "entity/RequestStatus";

import { QueryParams, RestController, RestRequest } from "./RestController";

export default class ImageController extends RestController {

    apiKeyUploadTiledImage(imageBytes: any, userId: string, socialThemeId: string, width?: number, height?: number, roomWidth?: number, roomHeight?: number, scaled?: boolean, deleteAll?: boolean): Promise<RequestStatus> {
        const queryParams: QueryParams = { userId: userId, socialThemeId: socialThemeId };
        if (width !== undefined) {
          queryParams.width = width;
        }
        if (height !== undefined) {
          queryParams.height = height;
        }
        if (roomWidth !== undefined) {
          queryParams.roomWidth = roomWidth;
        }
        if (roomHeight !== undefined) {
          queryParams.roomHeight = roomHeight;
        }
        if (scaled !== undefined) {
          queryParams.scaled = scaled;
        }
        if (deleteAll !== undefined) {
          queryParams.deleteAll = deleteAll;
        }
        let request = new RestRequest("/api/v1/image/api/key/upload/tiled", "POST", imageBytes, queryParams);
        return this.request(request, processRequestStatus) as Promise<RequestStatus>;
    }

    uploadBase64Image(imageName: string, type: string, width: number, height: number, base64: string): Promise<Image> {
        let request = new RestRequest("/api/v1/image/upload/base64/" + imageName + "/" + type + "/" + width + "/" + height + "", "POST", base64, undefined);
        return this.request(request, processImage) as Promise<Image>;
    }

    uploadImage(imageName: string, type: string, width: number, height: number, data: any): Promise<Image> {
        let request = new RestRequest("/api/v1/image/upload/" + imageName + "/" + type + "/" + width + "/" + height + "", "POST", data, undefined);
        return this.request(request, processImage) as Promise<Image>;
    }

    uploadSafeBase64Image(imageName: string, type: string, width: number, height: number, base64: string): Promise<Image> {
        let request = new RestRequest("/api/v1/image/upload/safe/base64/" + imageName + "/" + type + "/" + width + "/" + height + "", "POST", base64, undefined);
        return this.request(request, processImage) as Promise<Image>;
    }

    uploadTiledImage(socialThemeId: string, width: number, height: number, scaled: boolean, imageBytes: any): Promise<RequestStatus> {
        let request = new RestRequest("/api/v1/image/upload/tiled", "POST", imageBytes, { socialThemeId: socialThemeId, width: width, height: height, scaled: scaled });
        return this.request(request, processRequestStatus) as Promise<RequestStatus>;
    }

}
