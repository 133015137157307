import { createAction, props } from '@ngrx/store';

import SocialRecording from 'entity/SocialRecording';
import { PagingData } from 'rest/Paging';

export const INIT = createAction('RecordingPage/INIT');

export const FETCH_RECORDINGS_REQ = createAction('RecordingPage/FETCH_RECORDINGS_REQ', props<PagingData>());
export const FETCH_RECORDINGS_RSP = createAction(
  'RecordingPage/FETCH_RECORDINGS_RSP',
  props<{ recordings: SocialRecording[] }>(),
);
export const FETCH_RECORDINGS_ERR = createAction('RecordingPage/FETCH_SAVED_ROOMS_ERR', props<{ error: unknown }>());
