<scoot-header></scoot-header>
<div
  class="items-center error-page-container p-16"
  [ngStyle]="{
    height: 'calc(100vh - 48px)',
    top: '48px'
  }"
  role="main"
>
  <img src="assets/images/logos/scoot_logo_primary.png" alt="Scoot Logo" width="250" />
  <p class="weight-bold text-center letter-m font-2xl m-t-24">
    Oops! We can’t seem to find the page you are looking for.
  </p>
  <button
    routerLink="/"
    class="button bg-color-secondary text-color-white m-t-24"
    matRipple
    [matRippleColor]="'rgba(255, 255, 255, 0.2)'"
    data-testid="home-button"
  >
    Go Home
  </button>
  <a
    href="https://help.scoot.app/"
    data-testid="help-button"
    target="_blank"
    rel="noopener"
    class="link-button m-t-16 text-color-secondary"
  >
    Visit our help center
  </a>
  <img src="assets/images/illustrations/blue-dark-bottom-waves.svg" class="bottom-waves" alt="Background waves" />
</div>
