import { Injectable } from '@angular/core';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { catchError, from, Observable, of, ReplaySubject, Subject, switchMap, take, tap, concatMap, map } from 'rxjs';

import { coreActions } from 'app/core/state';
import { AppState } from 'app/types';
import { Loggable } from 'app/utils/logging/loggable';
import ClientConfiguration from 'entity/ClientConfiguration';
import User from 'entity/User';
import UserController from 'rest/UserController';

import { isDataPassUser } from './guest-auth.service';

@Injectable({
  providedIn: 'root',
})
export class CognitoService extends Loggable {
  public authenticated = false;
  private authSetupSubject = new ReplaySubject<boolean>(1);
  private authSetup$ = this.authSetupSubject.asObservable();

  private authSubject = new Subject<CognitoUser | undefined>();
  public auth$ = this.authSubject.asObservable();

  public constructor(private store: Store<AppState>) {
    super();
    this.setDisplayName('CognitoService');
  }

  public init(config: ClientConfiguration) {
    if (this.isGuestRoute) {
      this.authSubject.next(undefined);
      return;
    }

    Auth.configure({
      region: config.cognitoWebRegion,
      userPoolId: config.cognitoPoolId,
      userPoolWebClientId: config.cognitoWebClientId,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    });
    this.authSetupSubject.next(true);
    this.authSetupSubject.complete();

    // Skip auth if the user is provided via data pass. Sign out of existing users.
    if (isDataPassUser()) {
      this.signOut();
      return;
    }

    from(Auth.currentAuthenticatedUser())
      .pipe(
        tap((user) => {
          this.info('currentAuthenticatedUser', user);
        }),
        tap((user) => {
          this.userAuthenticated(user);
        }),
        catchError((err) => {
          this.trace('caught error', err);
          return of(undefined);
        }),
      )
      .subscribe((user) => this.authSubject.next(user));
  }

  public async getAuthToken(raw = false): Promise<string> {
    try {
      const session = await Auth.currentSession();
      return raw ? session.getIdToken().getJwtToken() : `Bearer ${session.getIdToken().getJwtToken()}`;
    } catch (e) {
      this.error('getAuthToken', e);
      return '';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async getAuth(): Promise<any> {
    try {
      const session = await Auth.currentUserPoolUser();
      return session;
    } catch (e) {
      this.error('getAuthToken', e);
      return '';
    }
  }

  public signIn(username: string, password: string): Observable<CognitoUser> {
    this.store.dispatch(coreActions.AUTH_PROCESSING());
    return this.authSetup$.pipe(
      switchMap(() => from(Auth.signIn(username, password) as Promise<CognitoUser>)),
      tap((user) => {
        this.userAuthenticated(user);
        this.authSubject.next(user);
      }),
    );
  }

  private get isGuestRoute(): boolean {
    return window?.location.pathname === '/web/guest';
  }

  private userAuthenticated(user: CognitoUser) {
    if (this.isGuestRoute) {
      this.authSubject.next(undefined);
      return;
    }
    const token = user.getSignInUserSession()?.getIdToken()?.getJwtToken();
    this.authenticated = !!token;
  }

  public signOut(): Observable<void> {
    this.authenticated = false;
    this.authSubject.next(undefined);
    return this.authSetup$.pipe(switchMap(() => from(Auth.signOut())));
  }

  public createAWSUser({
    appToken,
    firstName,
    lastName,
    password,
    recaptchaToken,
    username,
  }: {
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    recaptchaToken: string;
    appToken?: string;
  }): Observable<ISignUpResult> {
    this.store.dispatch(coreActions.AUTH_PROCESSING());
    return this.authSetup$.pipe(
      switchMap(() =>
        from(
          Auth.signUp({
            username,
            password,
            attributes: { name: `${firstName} ${lastName}` },
            validationData: { recaptchaToken, appToken },
          }),
        ),
      ),
    );
  }

  // Auth.confirmSignUp returns Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public verifySignUpConfirmationCode(username: string, code: string): Observable<any | ClientConfiguration> {
    return this.authSetup$.pipe(
      switchMap(() => from(Auth.confirmSignUp(username, code, { forceAliasCreation: true }))),
    );
  }

  public resendSignUpConfirmation(username: string): Observable<string> {
    return this.authSetup$.pipe(switchMap(() => from(Auth.resendSignUp(username))));
  }

  /**
   * After a user has been verified, create the user, and emit the user as signed in
   * Auth.signIn returns any, but in reality its a CognitoUser
   */
  public createVerifiedUser(username: string, password: string): Observable<{ cognitoUser: CognitoUser; user: User }> {
    return this.authSetup$.pipe(
      switchMap(() => from(Auth.signIn(username, password))),
      tap((cognitoUser) => this.userAuthenticated(cognitoUser)),
      concatMap((cognitoUser) => from(new UserController().createUser()).pipe(map((user) => ({ cognitoUser, user })))),
      tap(({ cognitoUser }) => this.authSubject.next(cognitoUser)),
    );
  }

  public forgotPassword(username: string): Observable<unknown> {
    return this.authSetup$.pipe(switchMap(() => from(Auth.forgotPassword(username))));
  }

  public forgotPasswordSubmit(username: string, code: string, newPassword: string): Observable<string> {
    return this.authSetup$.pipe(switchMap(() => from(Auth.forgotPasswordSubmit(username, code, newPassword))));
  }

  public reinitAuth() {
    this.signOut().pipe(take(1)).subscribe();
    this.authSubject.next(undefined);
  }
}
