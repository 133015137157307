import { createSelector } from '@ngrx/store';

import { LaunchDarklyFlagValues } from 'app/core/services/launch-darkly/launch-darkly.types';
import { coreSelectors } from 'app/core/state';
import { getFeatureFlags, getUserEntity } from 'app/core/state/selectors';
import { GuestSettingsNames } from 'app/customer-api/protocol';
import { AppState } from 'app/types';
import assertDefined from 'app/utils/assert-defined';
import assertNever from 'app/utils/assert-never';
import { isUndefined } from 'app/utils/stream-util';
import CTAItem from 'entity/CTAItem';
import GuestUserRoomSettings from 'entity/GuestUserRoomSettings';
import NavigationItem from 'entity/NavigationItem';
import Social from 'entity/Social';
import SocialArtifact from 'entity/SocialArtifact';
import SocialRoomSet from 'entity/SocialRoomSet';
import { COMPANY_BANNER } from 'entity/SocialSettings';
import SocialSettings from 'entity/SocialSettings';
import SocialTheme from 'entity/SocialTheme';
import StartingPoint from 'entity/StartingPoint';
import User from 'entity/User';
import {
  SocialEvent,
  SocialEventType,
  Presentation,
  Convo,
  Toast,
  SocialState,
  ScreenShare,
  ScreenShareStatus,
  MirrorType,
  Presenter,
  PresenterType,
  ConvoPhase,
  Livestreams,
  InteractionMode,
  PresentationSettings,
  BaseUser,
} from 'messages/websocket.messages';

import { StageSizeType } from '../../performer-participant/performer-participant.types';
import { GalleryTabs } from '../../presentation/presentation-drawer/gallery/constants';
import { BadgingState } from '../../services/badging/badging-state';
import { createMirrorId } from '../../services/presentation/util';
import { VisualIndicatorInfo } from '../../services/ring-colors/ring-colors.service';
import { SideContainerType } from '../../social-menu/enums/SideContainerType';
import { MiniMapState } from '../../social-mini-map/mini-map-state';
import { MAX_PRESENTERS } from '../../social-state-machine/social-state.constants';
import {
  getVideoResolution,
  PresenterVideoQuality,
  RESOLUTION_SMALL,
  RESOLUTION_STANDARD,
  VideoResolution,
} from '../../types/media';
import { getAudioEnabled, getVideoEnabled } from '../media/selectors';

import { initialState } from './reducer';
import {
  ExtendedPresenter,
  ParticipantsState,
  ParticipantMediaState,
  SocialAppState,
  VideoEffect,
  GroupScreenShare,
  FindParticipantState,
  PopOutState,
} from './types';

import { ConnectedRoomCounts, CrossRoomPresentationStatus, UserSubscriptionsState } from '.';

export const getSocialState = (state: AppState): SocialAppState => state?.social?.app ?? initialState;

export const getRegion = createSelector(getSocialState, (state): string | undefined => state.region);

export const getCurrentSocial = createSelector(
  getSocialState,
  (state: SocialAppState): Social | undefined => state?.socialEntity ?? undefined,
);

export const isRoomOpen = createSelector(getCurrentSocial, (social): boolean => !!social?.isOpen);

export const getTheme = createSelector(getCurrentSocial, (social): SocialTheme | undefined => social?.theme);

export const getRoomSize = createSelector(getCurrentSocial, (social): number | undefined => social?.startingWidth);

export const isInSocial = (state: AppState): boolean => !!getSocialSharedState(state);

// Get the ID of the joined social, if the user has joined one.
export const getJoinedSocialId = createSelector(getCurrentSocial, isInSocial, (social, inSocial): string | undefined =>
  inSocial ? social?.socialId : undefined,
);

export const isJoined = createSelector(getSocialState, (state: SocialAppState): boolean => state.joined);

export const getJoinedUser = createSelector(isJoined, coreSelectors.getUserEntity, (joined, user): User | undefined =>
  joined ? user : undefined,
);

export const getGroupId = createSelector(getSocialState, (state: SocialAppState): number | undefined => state.groupId);

export const getPublishState = createSelector(
  getSocialState,
  (state: SocialAppState): RTCPeerConnectionState | undefined => state.publishConnectionState,
);

export const isPublishing = createSelector(
  getPublishState,
  (publishState: RTCPeerConnectionState | undefined): boolean => publishState === 'connected',
);

// #region Connected Room
export const getCurrentConnectedRoomSet = (state: AppState): SocialRoomSet | undefined =>
  getSocialState(state)?.connectedRoomSetEntity ?? undefined;
export const getCurrentConnectedRoomSetId = createSelector(
  getCurrentConnectedRoomSet,
  (roomSet): string | undefined => roomSet?.socialRoomSetId,
);

export const getCurrentConnectedRoomCounts = (state: AppState): ConnectedRoomCounts =>
  getSocialState(state)?.connectedRoomCounts ?? undefined;

/**
   * NOTE: This selector, on it's own, is not trustworthy. I reccomend using it in conjunction with getCurrentConnectedRoomSet.length
   *
    combineLatest([
      this.store.select(socialAppSelectors.isConnectedRoom),
      this.store.select(socialAppSelectors.getCurrentConnectedRoomSet),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([isConnectedRoom, currentConnectedRoomCounts]) => {
        this.isConnectedRoom =
          isConnectedRoom && !!currentConnectedRoomCounts?.socials && currentConnectedRoomCounts?.socials?.length > 1;
      });
    */
export const isConnectedRoom = createSelector(
  getCurrentSocial,
  (social: Social | undefined): boolean => !!social?.connectedRoom,
);

export const getStartingPoint = (state: AppState): StartingPoint | undefined =>
  getSocialState(state)?.startingPoint ?? undefined;

// #endregion

export const getSocialSessionId = createSelector(
  getCurrentSocial,
  (social: Social | undefined): string | undefined => social?.sessionId ?? undefined,
);

export const getCurrentSocialId = createSelector(getCurrentSocial, (social): string | undefined => social?.socialId);

// Get the social ID and if the user is authenticated.
export const getAuthenticatedSocialId = createSelector(
  getCurrentSocial,
  coreSelectors.isAuthenticated,
  (social, authenticated): { socialId?: string; authenticated: boolean } => ({
    socialId: social?.socialId,
    authenticated,
  }),
);

export const getSocialSharedState = (state: AppState): SocialState | undefined => getSocialState(state)?.shared;

export const getSocialEvent = (state: AppState): SocialEvent | undefined => getSocialSharedState(state)?.socialEvent;

export const isEventActive = createSelector(getSocialEvent, (event): boolean => {
  if (!event) {
    return false;
  }

  return event.eventType !== SocialEventType.NONE;
});

export const isPresentationActiveInAnotherRoom = createSelector(getSocialSharedState, (shared): boolean => {
  if (!shared || !shared.presentations || !shared.presentations.map) {
    return false;
  }
  return !!Object.keys(shared.presentations.map).length;
});

export const presentationActive = createSelector(getSocialSharedState, (shared) => {
  if (!shared || !shared.presentations || !shared.presentations.map) {
    return {};
  }
  return shared.presentations.map;
});

export const navigationMarkersEnabled = (state: AppState): boolean => {
  return getSocialSharedState(state)?.navigationMarkersEnabled ?? false;
};

// #region Presentation
export const getPresentation = createSelector(getSocialEvent, (socialEvent?: SocialEvent): Presentation | undefined =>
  socialEvent?.eventType === SocialEventType.PRESENTATION ? socialEvent : undefined,
);

export const getPresenterIds = createSelector(getPresentation, (presentation?: Presentation): string[] =>
  presentation ? Object.keys(presentation.presenters) : [],
);

export const getRequestedPresenterIds = createSelector(getPresentation, (presentation?: Presentation): string[] =>
  presentation ? presentation.requestedPresenterIds : [],
);

export const getParticipants = createSelector(
  getSocialState,
  (state: SocialAppState): ParticipantsState => state.participants,
);

export const getPresentationStageSize = createSelector(
  getSocialState,
  (state: SocialAppState): StageSizeType => state.presentationStageSize ?? StageSizeType.SMALL,
);

/**
 * Determines if the presentation is active
 */
export const isPresentationActive = createSelector(
  getPresentation,
  (presentation?: Presentation): boolean => !!presentation,
);

/**
 * Determines if the presentation is active and the user is publishing
 */
export const isPresentationActiveAndPublishing = createSelector(
  isPublishing,
  isPresentationActive,
  (publishing: boolean, _isPresentationActive: boolean): boolean => _isPresentationActive && publishing,
);

/**
 * Determines if the current user is the presenter of the current presentation.
 */
export const isUserPresenting = createSelector(
  coreSelectors.getUserEntity,
  getPresenterIds,
  (user: User | undefined, presenterIds: string[]): boolean => {
    const { userId } = user ?? {};
    if (isUndefined(userId)) {
      return false;
    }
    return presenterIds.includes(userId);
  },
);

/**
 * Determines if the presentation is being recorded
 */
export const isPresentationRecording = createSelector(
  getPresentation,
  (presentation?: Presentation): boolean => presentation?.record ?? false,
);

/**
 * Get the current recording id
 */
export const getRecordingId = createSelector(
  getPresentation,
  (presentation?: Presentation): string | undefined => presentation?.recordingId,
);

/**
 * Determines if the current user is the presenter of the current presentation and the presentation is being recorded.
 */
export const isUserPresentingAndRecorded = createSelector(
  coreSelectors.getUserEntity,
  getPresenterIds,
  isPresentationRecording,
  (user: User | undefined, presenterIds: string[], recording): boolean => {
    const { userId } = user ?? {};
    return !!userId && presenterIds.includes(userId) && recording;
  },
);

/**
 * Determines if the presentation is cross-room
 */
export const isCrossRoomPresentation = createSelector(
  getPresentation,
  (presentation?: Presentation): boolean => presentation?.crossRoom ?? false,
);

/**
 * Determines if the user is presenting cross-room
 */
export const isUserCrossRoomPresenter = createSelector(
  isUserPresenting,
  isCrossRoomPresentation,
  (isPresenting: boolean, isCrossRoom: boolean): boolean => isPresenting && isCrossRoom,
);

export const getRestrictedEvent = createSelector(
  getSocialState,
  (state: SocialAppState): SocialEventType => state.restrictedEventType ?? SocialEventType.NONE,
);

export const getCrossRoomPresentationStatus = createSelector(
  isUserPresenting,
  isCrossRoomPresentation,
  getRestrictedEvent,
  getPresentation,
  (
    isPresenting: boolean,
    isCrossRoom: boolean,
    restrictedEvent: SocialEventType,
    presentation: Presentation | undefined,
  ): CrossRoomPresentationStatus => ({
    isPresenting,
    isCrossRoom,
    restrictedEvent,
    presentation,
  }),
);

export const getSpeakingParticipantIds = createSelector(getPresentation, (presentation?: Presentation) =>
  Object.keys(presentation?.speakingParticipants ?? {}),
);

export const getSpeakingParticipantValues = createSelector(getPresentation, (presentation?: Presentation) =>
  Object.values(presentation?.speakingParticipants ?? {}),
);

/**
 * Determines if the current user is the presenter of the current presentation.
 */
export const isUserSpeaking = createSelector(
  coreSelectors.getUserEntity,
  getSpeakingParticipantIds,
  (user: User | undefined, speakingParticipants: string[]): boolean => {
    const { userId } = user ?? {};
    if (isUndefined(userId)) {
      return false;
    }
    return speakingParticipants.includes(userId);
  },
);

export const getPresentationSettings = createSelector(
  getPresentation,
  (presentation?: Presentation): PresentationSettings | undefined => presentation?.settings,
);

export const getPresentationInteractionMode = createSelector(
  getPresentationSettings,
  (settings?: PresentationSettings): InteractionMode | undefined => settings?.interactionMode,
);

export const isPushToTalkActive = createSelector(
  getPresentationInteractionMode,
  (interactionMode?: InteractionMode): boolean =>
    interactionMode === InteractionMode.MANY_PUSH_TO_SPEAK || interactionMode === InteractionMode.SINGLE_PUSH_TO_SPEAK,
);

export const isSinglePushToTalkActive = createSelector(
  getPresentationInteractionMode,
  (interactionMode?: InteractionMode): boolean => interactionMode === InteractionMode.SINGLE_PUSH_TO_SPEAK,
);

export const isQueuedQAndAActive = createSelector(
  getPresentationInteractionMode,
  (interactionMode?: InteractionMode): boolean => interactionMode === InteractionMode.QUEUED_Q_AND_A,
);

export const isOpenModeActive = createSelector(
  getPresentationInteractionMode,
  (interactionMode?: InteractionMode): boolean => interactionMode === InteractionMode.OPEN,
);

export const isNoAudienceParticipationActive = createSelector(
  getPresentationInteractionMode,
  (interactionMode?: InteractionMode): boolean => interactionMode === InteractionMode.NONE,
);

export const getPresentersNames = createSelector(
  getSocialEvent,
  getParticipants,
  getPresentation,
  (socialEvent?: SocialEvent): string => {
    switch (socialEvent?.eventType) {
      case SocialEventType.PRESENTATION:
        if (Object.values(socialEvent.presenters).length > 0) {
          return Object.values(socialEvent.presenters)
            .map((p) => `${p.firstName}${p.lastName ? ' ' : ''}${p.lastName || ''}`)
            .join(', ');
        } else {
          return 'Presentation';
        }

      // There is no presenter for the banner phase of a CONVO
      case SocialEventType.CONVO:
        return socialEvent.phase === ConvoPhase.BANNER_PHASE
          ? ''
          : `${socialEvent.presenter.firstName} ${socialEvent.presenter.lastName || ''}`;

      case SocialEventType.TOAST:
        return `${socialEvent.presenter.firstName} ${socialEvent.presenter.lastName || ''}`;

      default:
        return '';
    }
  },
);

/**
 * Get the presentation mix volume setting
 */
export const getMixVolume = createSelector(getSocialState, (state: SocialAppState): number => state.mixVolume);

/**
 * Determine if the user should be present in the presentation mix.
 */
export const isUserAudioInMix = createSelector(
  coreSelectors.getUserEntity,
  isPublishing,
  isUserPresenting,
  getPresentationInteractionMode,
  getSpeakingParticipantIds,
  (
    user: User | undefined,
    _isPublishing: boolean,
    _isUserPresenting: boolean,
    interactionMode: InteractionMode | undefined,
    speakingParticipantIds: string[],
  ): boolean => {
    if (!user) {
      return false;
    }
    assertDefined(user.userId);

    // This should not happen, but its possible for the mode to be undefined.
    if (isUndefined(interactionMode)) {
      return false;
    }

    // If the user is not publishing, they are not in the mix.
    if (!_isPublishing) {
      return false;
    }

    // Presenters are always in the mix
    if (_isUserPresenting) {
      return true;
    }

    // Respect the interaction mode when determining if an audience member is in the mix.
    switch (interactionMode) {
      case InteractionMode.OPEN:
        return true;
      case InteractionMode.MANY_PUSH_TO_SPEAK:
      case InteractionMode.SINGLE_PUSH_TO_SPEAK:
      case InteractionMode.QUEUED_Q_AND_A:
        return speakingParticipantIds.includes(user.userId);
      case InteractionMode.NONE:
        return false;
      default:
        assertNever(interactionMode);
    }
  },
);

export const getRaisedHandCount = createSelector(
  getPresentation,
  (presentation?: Presentation): number => presentation?.queuedToSpeakIds?.length ?? 0,
);

export const getQueuedToSpeakIds = createSelector(
  getPresentation,
  (presentation?: Presentation): string[] => presentation?.queuedToSpeakIds ?? [],
);

export const isQueuedToSpeak = createSelector(
  getQueuedToSpeakIds,
  getUserEntity,
  (queuedToSpeakIds: string[], user?: User): boolean => {
    const { userId } = user ?? {};
    if (!userId) {
      return false;
    }

    return queuedToSpeakIds.includes(userId);
  },
);

// #endregion Presentation

// #region Convo
export const getConvo = createSelector(getSocialEvent, (socialEvent?: SocialEvent): Convo | undefined =>
  socialEvent?.eventType === SocialEventType.CONVO ? socialEvent : undefined,
);

export const getConvoUser = createSelector(getConvo, (convo?: Convo): Presenter | undefined => convo?.presenter);

export const isConvoInBanner = createSelector(
  getConvo,
  (convo?: Convo): boolean => convo?.phase === ConvoPhase.BANNER_PHASE,
);

export const isConvoInAnnounce = createSelector(
  getConvo,
  (convo?: Convo): boolean => convo?.phase === ConvoPhase.ANNOUNCE_PHASE,
);

export const getConvoDetails = createSelector(
  getConvo,
  getUserEntity,
  getVideoEnabled,
  getParticipants,
  getPresentation,
  (
    convo: Convo | undefined,
    user: User | undefined,
    videoEnabled: boolean,
    participants: ParticipantsState,
  ): ExtendedPresenter | undefined => {
    if (convo?.phase !== ConvoPhase.ANNOUNCE_PHASE) {
      return undefined;
    }
    let videoMuted = false;

    const { userId: presenterId } = convo?.presenter;
    const isLocal = presenterId === user?.userId;
    // Mute state for the local user is tracked in the media slice, whereas remove users are in the participants map
    videoMuted = isLocal ? !videoEnabled : participants[presenterId]?.videoMuted ?? false;
    return { ...convo.presenter, isLocal, videoMuted };
  },
);
// #endregion Convo

// #region Toast
export const getToast = createSelector(getSocialEvent, (socialEvent?: SocialEvent): Toast | undefined =>
  socialEvent?.eventType === SocialEventType.TOAST ? socialEvent : undefined,
);
export const isToastHappening = createSelector(getToast, (toast?: Toast): boolean => !!toast);
export const isToastActive = createSelector(getToast, (toast?: Toast): boolean => toast?.toastIsActive ?? false);

export const getToaster = createSelector(getToast, (toast?: Toast): Presenter | undefined => toast?.presenter);

export const isUserToaster = createSelector(
  coreSelectors.getUserEntity,
  getToaster,
  (user: User | undefined, toaster: Presenter | undefined): boolean => {
    const { userId } = user ?? {};
    return !!userId && toaster?.userId === userId;
  },
);

export const getToastStart = createSelector(getToast, (toast?: Toast): string | undefined => toast?.startTime);

export const getToasterDetails = createSelector(
  getToast,
  getUserEntity,
  getVideoEnabled,
  getParticipants,
  getPresentation,
  (
    toast: Toast | undefined,
    user: User | undefined,
    videoEnabled: boolean,
    participants: ParticipantsState,
  ): ExtendedPresenter | undefined => {
    if (!toast?.toastIsActive) {
      return undefined;
    }
    let videoMuted = false;

    const { userId: presenterId } = toast?.presenter;
    const isLocal = presenterId === user?.userId;
    // Mute state for the local user is tracked in the media slice, whereas remove users are in the participants map
    videoMuted = isLocal ? !videoEnabled : participants[presenterId]?.videoMuted ?? false;
    return { ...toast.presenter, isLocal, videoMuted };
  },
);

// #endregion Toast

export const activeAnnouncement = createSelector(getConvo, getToast, (convo?: Convo, toast?: Toast): boolean => {
  return (convo?.phase === ConvoPhase.ANNOUNCE_PHASE || toast?.toastIsActive) ?? false;
});

export const getLocalEventPresenters = createSelector(
  getPresentation,
  getConvo,
  getToast,
  getJoinedSocialId,
  (presentation?: Presentation, convo?: Convo, toast?: Toast, socialId?: string): string[] => {
    const presentationPresenters = Object.values(presentation?.presenters ?? {})
      .filter(({ socialId: userSocialId }) => isUndefined(userSocialId) || socialId === userSocialId)
      .map(({ userId }) => userId);
    const convoPresenters = convo?.phase === ConvoPhase.ANNOUNCE_PHASE ? [convo.presenter.userId] : [];
    const toastPresenters = toast?.toastIsActive ? [toast.presenter.userId] : [];
    return [...presentationPresenters, ...convoPresenters, ...toastPresenters];
  },
);

export const getCrossRoomEventPresenters = createSelector(
  getPresentation,
  getConvo,
  getToast,
  getJoinedSocialId,
  (presentation?: Presentation, convo?: Convo, toast?: Toast, socialId?: string): string[] => {
    const presentationPresenters = Object.values(presentation?.presenters ?? {})
      .filter(({ socialId: userSocialId }) => socialId !== userSocialId)
      .map(({ userId }) => userId);
    const convoPresenters = convo?.phase === ConvoPhase.ANNOUNCE_PHASE ? [convo.presenter.userId] : [];
    const toastPresenters = toast?.toastIsActive ? [toast.presenter.userId] : [];
    return [...presentationPresenters, ...convoPresenters, ...toastPresenters];
  },
);

// #region ScreenShare
export const getScreenShareState = createSelector(
  getSocialSharedState,
  (socialState?: SocialState): { status: ScreenShareStatus; screenShare?: ScreenShare } => ({
    status: socialState?.screenShareStatus ?? ScreenShareStatus.NONE,
    screenShare: socialState?.screenShare,
  }),
);

export const getScreenSharePresenter = createSelector(
  getScreenShareState,
  (screenShareState): Presenter | undefined => screenShareState.screenShare?.presenter,
);

export const getScreenSharePresenterId = createSelector(
  getScreenShareState,
  (screenShareState): string | undefined => screenShareState.screenShare?.presenter?.userId,
);

// for use in V0, presenter does not see their own stream
export const getRemoteScreenSharePresenter = createSelector(
  getUserEntity,
  getScreenSharePresenter,
  (user?: User, presenter?: Presenter): Presenter | undefined => {
    if (!user || !presenter) {
      return undefined;
    }
    return user?.userId !== presenter?.userId ? presenter : undefined;
  },
);

// for use in V1, presenter does see their own stream
export const getRemoteScreenSharePresenterV1 = createSelector(
  getUserEntity,
  getScreenSharePresenter,
  (user?: User, presenter?: Presenter): Presenter | undefined => {
    if (!user || !presenter) {
      return undefined;
    }
    return presenter;
  },
);

export const isScreenShareActive = createSelector(
  getScreenShareState,
  getScreenSharePresenterId,
  (screenShareState, screenSharePresenterId) =>
    screenShareState.status === ScreenShareStatus.ACTIVE && !!screenSharePresenterId,
);

export const isUserSharingScreen = createSelector(
  coreSelectors.getUserEntity,
  getScreenSharePresenterId,
  isScreenShareActive,
  (user, screenSharePresenterId, screenShareActive): boolean => {
    const { userId } = user ?? {};
    return !!userId && screenSharePresenterId === userId && screenShareActive;
  },
);

export const isUserSharingScreenAndRecorded = createSelector(
  isUserSharingScreen,
  isPresentationRecording,
  (userSharingScreen, recording): boolean => userSharingScreen && recording,
);

/**
 * Determines if the current user is sharing their screen cross-room.
 */
export const isUserCrossRoomScreenSharing = createSelector(
  isUserSharingScreen,
  isCrossRoomPresentation,
  (userSharingScreen, isCrossRoom): boolean => userSharingScreen && isCrossRoom,
);

export const isScreenShareActiveWithoutPresentation = createSelector(
  isPresentationActive,
  isScreenShareActive,
  (presentation, screenShare): boolean => {
    if (!presentation && screenShare) {
      return true;
    }
    return false;
  },
);
// #endregion ScreenShare

// #region Group ScreenShare

/* Group Shares are weird. They are keyed by the presenter's userId.
They can be opt in, depending on version. Three things to consider:
1. Is the user a presenter?
2. Is the user a group member?
3. Is the user a viewer?
If the user is a presenter, they will have a group share with their userId as the key.
If the user is a group member, they will have a group share with the presenter's userId in their group.
If the user is a viewer, they will have a group share with the their userId in the viewerUserIds array.
to determine if a user is a group member, consider something like the following:

combineLatest([this.sceneService.primaryGroupMembers$, this.store.select(socialAppSelectors.getGroupShareIds)])
  .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
  .subscribe(([group, groupShareIds]) => {
    let active = false;
    if (
      // is user presenter?
      (this.user && this.user.userId && groupShareIds.includes(this.user.userId)) ||
      // is someone in my group presenter?
      this.group.some((r) => groupShareIds.includes(r.userId))
    ) {
      active = true;
    }
    this.groupParticipantScreenShareActive = active;
  });

*/
export const getGroupScreenShares = createSelector(getSocialSharedState, (socialState): GroupScreenShare => {
  const groupShares = socialState?.groupScreenShares?.map ?? {};
  return groupShares;
});

export const getGroupShareIds = createSelector(getGroupScreenShares, (screenShares): string[] => {
  if (!screenShares) {
    return [];
  }
  const ids = Object.keys(screenShares);
  return ids;
});

export const getMyGroupScreenShare = createSelector(
  getGroupScreenShares,
  getGroupShareIds,
  getUserEntity,
  (screenShares, ids, user): ScreenShare | undefined => {
    const { userId } = user ?? {};
    if (!userId) {
      return;
    }

    if (!screenShares || !ids) {
      return;
    }

    // is presenter?
    if (ids?.includes(userId)) {
      return screenShares[userId];
    }

    // Check if the user is a participant
    for (const id of ids) {
      const screenShare = screenShares[id];
      if (screenShare?.viewerUserIds?.includes(userId)) {
        return screenShare;
      }
    }
  },
);

// returns all users who have started or joined a group screen share
export const getGroupScreenShareUsers = createSelector(
  getGroupScreenShares,
  getGroupShareIds,
  (screenShares, ids): string[] => {
    const groupShareUsers: string[] = [];
    ids.forEach((id) => {
      const screenShare = screenShares[id];
      if (screenShare && screenShare.viewerUserIds) {
        // Add presenter and participants
        groupShareUsers.push(id, ...screenShare.viewerUserIds);
      }
    });
    return groupShareUsers;
  },
);

export const isGroupShareRecording = createSelector(
  getMyGroupScreenShare,
  (screenShare): boolean => !!screenShare?.recordingId,
);

export const getGroupShareRecordingId = createSelector(getMyGroupScreenShare, (screenShare): string => {
  return screenShare?.recordingId ?? '';
});

export const isUserSharingScreenToGroup = createSelector(
  getGroupScreenShares,
  getUserEntity,
  (screenShares, user): boolean => {
    const { userId } = user ?? {};
    if (!userId) {
      return false;
    }

    if (!screenShares) {
      return false;
    }

    const ids = Object.keys(screenShares);
    return ids.includes(userId);
  },
);

export const getMyGroupShareParticipants = createSelector(
  getMyGroupScreenShare,
  (screenShare): string[] | undefined => {
    return screenShare?.viewerUserIds;
  },
);

// #endregion Group ScreenShare
//
export const getBannedUserIds = createSelector(getSocialSharedState, (socialState): string[] => {
  if (!socialState) {
    return [];
  }
  return Object.keys(socialState.readOnly.bannedUsers);
});

export const isBannedUser = createSelector(
  coreSelectors.getUserId,
  getBannedUserIds,
  (userId, bannedUserIds): boolean => {
    if (!userId) {
      return false;
    }

    return bannedUserIds.includes(userId);
  },
);

export const getAlternateHosts = createSelector(
  getCurrentSocial,
  (social: Social | undefined): User[] => social?.alternateHosts ?? [],
);

export const getFullClusters = createSelector(
  getSocialState,
  (state: SocialAppState): number[] => state.fullClusters ?? [],
);

export const getSubscriptionStatus = createSelector(
  getSocialState,
  (state: SocialAppState): UserSubscriptionsState | undefined => state.subscriptions,
);

export const getPresenterDetails = createSelector(
  getCurrentSocialId,
  getUserEntity,
  getParticipants,
  getVideoEnabled,
  getAudioEnabled,
  getPresentation,
  (
    socialId: string | undefined,
    user: User | undefined,
    participants: ParticipantsState,
    videoEnabled: boolean,
    audioEnabled: boolean,
    presentation?: Presentation,
  ): ExtendedPresenter[] => {
    return Object.values(presentation?.presenters ?? {}).map((presenter) => {
      let videoMuted = false;
      let audioMuted = false;
      let { userId: presenterId } = presenter;
      const isLocal = presenter.userId === user?.userId;
      const isMirror = presenter.socialId !== socialId;
      const mirrorId = createMirrorId(presenter, MirrorType.PUBLISHER);

      // If the presenter is from a cross-room presentation, mute state is stored in the mirror id
      presenterId = isMirror ? mirrorId : presenterId;

      // Mute state for the local user is tracked in the media slice, whereas remove users are in the participants map
      videoMuted = isLocal ? !videoEnabled : participants[presenterId]?.videoMuted ?? false;
      audioMuted = isLocal ? !audioEnabled : participants[presenterId]?.audioMuted ?? false;

      return {
        ...presenter,
        isLocal,
        videoMuted,
        audioMuted,
      };
    });
  },
);

/**
 * Determine participant media state for all users.
 */
export const getParticipantMediaState = createSelector(
  getUserEntity,
  getParticipants,
  getVideoEnabled,
  getAudioEnabled,
  (
    user: User | undefined,
    participants: ParticipantsState,
    videoEnabled: boolean,
    audioEnabled: boolean,
  ): Record<string, ParticipantMediaState> => {
    if (user) {
      assertDefined(user.userId);
      return {
        ...participants,
        [user.userId]: { videoMuted: !videoEnabled, audioMuted: !audioEnabled },
      };
    }
    return participants;
  },
);

export const isPresentationFull = createSelector(getPresentation, (presentation: Presentation | undefined): boolean => {
  const presenters = presentation?.presenters ?? {};
  const requestedPresenters = presentation?.requestedPresenterIds ?? [];
  return Object.keys(presenters).length + requestedPresenters.length >= MAX_PRESENTERS;
});

export const getAudience = createSelector(getSocialState, (state: SocialAppState): User[] => state.audience ?? []);

export const getGallery = createSelector(
  getSocialState,
  (state: SocialAppState): User[] | BaseUser[] => state.gallery ?? [],
);

export const getGalleryTabs = createSelector(
  getSocialState,
  (state: SocialAppState): GalleryTabs[] => state.galleryTabs,
);

export const getArtifacts = createSelector(
  getSocialState,
  (state: SocialAppState): SocialArtifact[] => state.artifacts,
);

export const getCTAItems = createSelector(getSocialState, (state: SocialAppState): CTAItem[] => state.ctaItems);

export const getNavItems = createSelector(getSocialState, (state: SocialAppState): NavigationItem[] => state.navItems);

// #region livestreams
export const getQueuedLivestreams = createSelector(
  getSocialSharedState,
  (socialState?: SocialState): Livestreams => socialState?.livestreams ?? {},
);

export const isLivestreamActive = createSelector(getPresentation, (presentation: Presentation | undefined): boolean => {
  const presenters = presentation?.presenters ?? {};
  const livestream = Object.keys(presenters).some((p: string) => {
    const type = presenters[p].presenterType;
    return type === PresenterType.LIVE_STREAM;
  });
  return livestream;
});

export const getActiveLivestreamsIds = createSelector(
  getPresentation,
  getCurrentSocialId,
  (presentation: Presentation | undefined): string[] =>
    Object.values(presentation?.presenters || {})
      .filter((p) => p.presenterType === PresenterType.LIVE_STREAM)
      .map((p) => p.userId),
);

export const getActiveLivestreams = createSelector(
  getPresentation,
  (presentation: Presentation | undefined): Presenter[] =>
    Object.values(presentation?.presenters || {}).filter((p) => p.presenterType === PresenterType.LIVE_STREAM),
);

export const isLivestreamCrossRoom = createSelector(
  isLivestreamActive,
  isCrossRoomPresentation,
  (isActive: boolean, isCrossRoom: boolean): boolean => isActive && isCrossRoom,
);
// #endregion livestreams

// #region Room Settings
export const getRoomSettings = createSelector(
  getSocialState,
  (state: SocialAppState): SocialSettings | undefined => state.roomSettings,
);

export const getPresenterQualityOverride = createSelector(
  getSocialState,
  (state: SocialAppState): PresenterVideoQuality | undefined => state.presenterQualityOverride,
);

export const getPresenterResolution = createSelector(
  getRoomSettings,
  getPresenterQualityOverride,
  (
    roomSettings: SocialSettings | undefined,
    presenterQualityOverride: PresenterVideoQuality | undefined,
  ): VideoResolution => {
    if (presenterQualityOverride) {
      return getVideoResolution(presenterQualityOverride);
    }

    const { settings } = roomSettings ?? {};
    if (!settings) {
      return RESOLUTION_STANDARD;
    }
    if (typeof settings.presenterVideoQuality !== 'string') {
      return RESOLUTION_STANDARD;
    }
    return getVideoResolution(settings.presenterVideoQuality);
  },
);

export const getPublishVideoBitrate = createSelector(
  getPresenterResolution,
  isUserPresenting,
  getFeatureFlags,
  (videoQuality: VideoResolution, isPresenting: boolean, featureFlags: LaunchDarklyFlagValues | undefined): number => {
    if (!featureFlags?.hd_presenter) {
      return RESOLUTION_SMALL.bitrate;
    }
    if (!isPresenting) {
      return RESOLUTION_SMALL.bitrate;
    }
    return videoQuality.bitrate;
  },
);

export const getShowCompanyBanner = createSelector(
  getRoomSettings,
  (roomSettings?: SocialSettings): boolean => !!roomSettings?.settings?.[COMPANY_BANNER],
);

// #endregion Room Settings

export const isInRoom = createSelector(getCurrentSocial, (social?: Social): boolean => !!social);

// #region Video Effects
export const isVideoEffectEnabled = createSelector(
  getSocialState,
  (state: SocialAppState): boolean => state.videoEffect.enabled,
);

export const getVideoEffect = createSelector(getSocialState, (state: SocialAppState): VideoEffect => state.videoEffect);
// #endregion Video Effects

// #region customer api settings
export const customerAPIEnabled = createSelector(
  getSocialState,
  (state: SocialAppState): boolean => !!state.customerAPIEnabled,
);

export const getGuestRoomSettings = createSelector(
  getSocialState,
  (state: SocialAppState): GuestUserRoomSettings | undefined => state.guestRoomSettings,
);

// Host
export const isGuestHost = createSelector(getGuestRoomSettings, (guestSettings?: GuestUserRoomSettings): boolean => {
  const settings = guestSettings?.settings ?? {};
  const bool = Object.keys(settings).some((s: string) => {
    return s === GuestSettingsNames.HOST;
  });
  return bool;
});

// Mini Map
export const isGuestMiniMapStateApplied = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): boolean => {
    const settings = guestSettings?.settings ?? {};
    const bool = Object.keys(settings).some((s: string) => {
      return s === GuestSettingsNames.SHOW_MAP;
    });
    return bool;
  },
);

export const getGuestMiniMapState = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): MiniMapState | undefined => {
    const settings = guestSettings?.settings ?? {};
    const value = settings[GuestSettingsNames.SHOW_MAP];
    if (typeof value !== 'string') {
      return;
    }
    switch (value) {
      case 'closed':
        return MiniMapState.CLOSED;
      case 'opened':
        return MiniMapState.OPENED;
      case 'expanded':
        return MiniMapState.EXPANDED;
      case 'mobile':
        return MiniMapState.MOBILE;
      case 'none':
        return MiniMapState.NONE;
      default:
        return undefined;
    }
  },
);

// Menu
export const setGuestMenuState = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): boolean => {
    const settings = guestSettings?.settings ?? {};
    const bool = Object.keys(settings).some((s: string) => {
      return s === GuestSettingsNames.SHOW_MENU;
    });
    return bool;
  },
);

export const getGuestMenuState = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): SideContainerType | undefined => {
    const settings = guestSettings?.settings ?? {};
    const value = settings[GuestSettingsNames.SHOW_MENU];
    if (typeof value !== 'string') {
      return undefined;
    }
    switch (value) {
      case 'none':
        return SideContainerType.NONE;
      case 'chat':
        return SideContainerType.CHAT;
      case 'action_menu':
        return SideContainerType.ACTION_MENU;
      default:
        return undefined;
    }
  },
);

export const isGuestZoomLevelApplied = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): boolean => {
    const settings = guestSettings?.settings ?? {};
    const bool = Object.keys(settings).some((s: string) => {
      return s === GuestSettingsNames.ZOOM_LEVEL;
    });
    return bool;
  },
);

export const getGuestZoomLevel = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): number => {
    const settings = guestSettings?.settings ?? {};
    const value = settings[GuestSettingsNames.ZOOM_LEVEL];
    if (typeof value === 'number') {
      return value;
    }
    return 1.0;
  },
);

// Camera
export const isGuestCameraStateApplied = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): boolean => {
    const settings = guestSettings?.settings ?? {};
    const bool = Object.keys(settings).some((s: string) => {
      return s === GuestSettingsNames.MUTE_CAMERA;
    });
    return bool;
  },
);

export const muteGuestCamera = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): boolean => {
    const settings = guestSettings?.settings ?? {};
    const value = settings[GuestSettingsNames.MUTE_CAMERA];
    return value === 'True';
  },
);

// Microphone
export const isGuestMicStateApplied = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): boolean => {
    const settings = guestSettings?.settings ?? {};
    const bool = Object.keys(settings).some((s: string) => {
      return s === GuestSettingsNames.MUTE_MICROPHONE;
    });
    return bool;
  },
);

export const muteGuestMic = createSelector(getGuestRoomSettings, (guestSettings?: GuestUserRoomSettings): boolean => {
  const settings = guestSettings?.settings ?? {};
  const value = settings[GuestSettingsNames.MUTE_MICROPHONE];
  return value === 'True';
});

// Blur Effect
export const isGuestBlurEffectApplied = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): boolean => {
    const settings = guestSettings?.settings ?? {};
    const value = settings[GuestSettingsNames.VIDEO_EFFECTS_MODE];
    if (typeof value !== 'string') {
      return false;
    }
    const { mode } = JSON.parse(value);
    return mode === 'blur';
  },
);

// Video FPS
export const getGuestVideoFPS = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): number | undefined => {
    const settings = guestSettings?.settings ?? {};
    const value = settings[GuestSettingsNames.VIDEO_FPS];
    if (typeof value === 'number') {
      return value;
    }
    return undefined;
  },
);

export const getGuestRoomPosition = createSelector(
  getGuestRoomSettings,
  (guestSettings?: GuestUserRoomSettings): { x: number; y: number } | undefined => {
    const settings = guestSettings?.settings ?? {};
    const value = settings[GuestSettingsNames.ROOM_POSITION];
    if (typeof value !== 'string') {
      return;
    }
    try {
      const { x, y } = JSON.parse(value);
      if (typeof x === 'number' && typeof y === 'number') {
        return { x, y };
      }
    } catch (e) {
      return undefined;
    }
  },
);

// #endregion customer api settings

// #region chat menu
export const isChatEnabled = createSelector(getSocialState, (state: SocialAppState): boolean => state.chatEnabled);
// #endregion chat menu

/**
 * Determines if a user is the owner of the room or has been added to the host array
 */
export const isHost = createSelector(
  coreSelectors.getUserEntity,
  getCurrentSocial,
  getSocialSharedState,
  isGuestHost,
  (user, social, socialState, _isGuestHost): boolean => {
    const { userId } = user ?? {};
    if (!userId) {
      return false;
    }

    // The room owner is always a host
    const { userId: ownerId } = social ?? {};
    if (userId === ownerId) {
      return true;
    }

    // If the user is a guest host, they are always a host
    if (_isGuestHost) {
      return true;
    }

    // If the user is not the owner of the room, check the hosts and alternate hosts array from shared state and social
    const alternateHostIds = social?.alternateHosts?.map((host) => host.userId);
    const hosts = [...(socialState?.hosts || []), ...(alternateHostIds || [])] ?? [];
    return hosts.includes(userId);
  },
);

export const getHostIdList = createSelector(getSocialSharedState, (socialState): string[] => {
  // If the user is not the owner of the room, check the hosts and alternate hosts array from shared state and social
  return socialState?.hosts ?? [];
});

/**
 * Get the users that the current user may admit to the room.
 *
 * A user must be a host to admit other users to the room. If the user is not a host, the list is always empty.
 */
export const getWaitingUsers = createSelector(isHost, getSocialSharedState, (_isHost, socialState): BaseUser[] => {
  if (!_isHost) {
    return [];
  }
  return Object.values(socialState?.readOnly.waitingUsers ?? {});
});

export const isBadgeOpen = createSelector(getSocialState, (state: SocialAppState): BadgingState => state.badgeOpen);

export const isSearchOpen = createSelector(
  getSocialState,
  (state: SocialAppState): boolean => state.searchParticipantsOpen,
);

export const shouldOpenMap = createSelector(
  getSocialState,
  (state: SocialAppState): FindParticipantState => state.shouldOpenMap,
);

export const isPopOut = createSelector(getSocialState, (state: SocialAppState): PopOutState => state.isPopOut);

export const isBadgingModalOpen = createSelector(
  getSocialState,
  (state: SocialAppState): boolean => state.isBadgingModalOpen,
);

export const getVisualIndicatorInfo = createSelector(
  getSocialState,
  (state: SocialAppState): VisualIndicatorInfo => state.visualIndicatorInfo ?? {},
);

export const getActiveSpeakers = createSelector(
  getSocialState,
  (state: SocialAppState): string[] => state.activeSpeakers ?? [],
);

export const isUserActiveSpeaker = createSelector(
  coreSelectors.getUserEntity,
  getActiveSpeakers,
  (user: User | undefined, activeSpeakers: string[]): boolean => {
    const { userId } = user ?? {};
    if (isUndefined(userId)) {
      return false;
    }
    return activeSpeakers.includes(userId);
  },
);

export const isWideRecording = createSelector(
  getSocialState,
  (state: SocialAppState): boolean => state.isWideRecording ?? false,
);

export const userIsSpeaking = createSelector(
  getSocialState,
  (state: SocialAppState): boolean => state.userIsSpeaking ?? false,
);
