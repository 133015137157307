export enum VideoCodecType {
  VP8 = 'VP8',
  VP9 = 'VP9',
  H264 = 'H.264',
  AV1 = 'AV1',
}

export enum VideoCodecMimeType {
  VP8 = 'video/VP8',
  VP9 = 'video/VP9',
  H264 = 'video/H264',
  AV1 = 'video/AV1',
}

export interface VideoCodec {
  mimeType: VideoCodecMimeType;
  sdpFormatFilter: string;
}

/**
 * See: https://www.w3.org/TR/webrtc-svc/#dependencydiagrams*
 */
export enum ScalabilityMode {
  NONE = 'none',
  L1T1 = 'L1T1',
  L1T2 = 'L1T2',
  L1T3 = 'L1T3',
  L2T1 = 'L2T1',
  L2T2 = 'L2T2',
  L2T3 = 'L2T3',
  L3T1 = 'L3T1',
  L3T2 = 'L3T2',
  L3T3 = 'L3T3',
  L2T1h = 'L2T1h',
  L2T2h = 'L2T2h',
  L2T3h = 'L2T3h',
  L3T1h = 'L3T1h',
  L3T2h = 'L3T2h',
  L3T3h = 'L3T3h',
  S2T1 = 'S2T1',
  S2T2 = 'S2T2',
  S2T3 = 'S2T3',
  S2T1h = 'S2T1h',
  S2T2h = 'S2T2h',
  S2T3h = 'S2T3h',
  S3T1 = 'S3T1',
  S3T2 = 'S3T2',
  S3T3 = 'S3T3',
  S3T1h = 'S3T1h',
  S3T2h = 'S3T2h',
  S3T3h = 'S3T3h',
  L2T2_KEY = 'L2T2_KEY',
  L2T2_KEY_SHIFT = 'L2T2_KEY_SHIFT',
  L2T3_KEY = 'L2T3_KEY',
  L2T3_KEY_SHIFT = 'L2T3_KEY_SHIFT',
  L3T1_KEY = 'L3T1_KEY',
  L3T2_KEY = 'L3T2_KEY',
  L3T2_KEY_SHIFT = 'L3T2_KEY_SHIFT',
  L3T3_KEY = 'L3T3_KEY',
  L3T3_KEY_SHIFT = 'L3T3_KEY_SHIFT',
}
/**
 * See PRESENTER_VIDEO_QUALITY room setting
 */
export enum PresenterVideoQuality {
  QUALITY_STANDARD = 'STANDARD',
  QUALITY_720P = '720p',
  QUALITY_1080P = '1080p',
}

// preserving the existing values for backwards compatibility
export const PresenterVideoQualityWithLabels: PresenterVideoQualityWithLabel[] = [
  {
    key: PresenterVideoQuality.QUALITY_STANDARD,
    labels: {
      standard: 'Standard Definition',
      hd_presenter: 'Standard Definition',
      hd_presenter_1080p: 'Standard Definition',
    },
  },
  {
    key: PresenterVideoQuality.QUALITY_720P,
    labels: {
      standard: 'UNAVAILABLE',
      hd_presenter: 'High Definition',
      hd_presenter_1080p: 'HD 720p',
    },
  },
  {
    key: PresenterVideoQuality.QUALITY_1080P,
    labels: {
      standard: 'UNAVAILABLE',
      hd_presenter: 'UNAVAILABLE',
      hd_presenter_1080p: 'HD 1080p',
    },
  },
];

export interface PresenterVideoQualityWithLabel {
  key: string;
  labels: {
    standard: string;
    hd_presenter: string;
    hd_presenter_1080p: string;
  };
}

export interface VideoResolution {
  width: number;
  height: number;
  bitrate: number;
}

export const RESOLUTION_SMALL: VideoResolution = {
  width: 220,
  height: 200,
  bitrate: 300_000,
};

export const RESOLUTION_1080P: VideoResolution = {
  width: 1920,
  height: 1080,
  bitrate: 2_097_152,
};

export const RESOLUTION_720P: VideoResolution = {
  width: 1280,
  height: 720,
  bitrate: 1_048_576,
};

export const RESOLUTION_STANDARD: VideoResolution = {
  width: 960,
  height: 540,
  bitrate: 512_000,
};

export const getVideoResolution = (quality: PresenterVideoQuality | string): VideoResolution => {
  switch (quality) {
    case PresenterVideoQuality.QUALITY_STANDARD:
      return RESOLUTION_STANDARD;
    case PresenterVideoQuality.QUALITY_1080P:
      return RESOLUTION_1080P;
    case PresenterVideoQuality.QUALITY_720P:
      return RESOLUTION_720P;
    default:
      return RESOLUTION_STANDARD;
  }
};
