import { createSelector } from '@ngrx/store';

import { AppState } from 'app/types';
import assertNever from 'app/utils/assert-never';
import User from 'entity/User';
import LicenseType from 'enums/LicenseType';

import { LaunchDarklyFlagValues } from '../services/launch-darkly/launch-darkly.types';

import { initialState } from './reducer';
import { CoreAppState } from './types';

export const getCoreState = (state: AppState): CoreAppState => state?.core ?? initialState;

// #region User
export const getUserEntity = createSelector(getCoreState, (state: CoreAppState): User | undefined => {
  return state.user && state.user.userId ? state.user : undefined;
});
export const isTestUser = (state: AppState): boolean => getCoreState(state).isTestUser;
export const isGuestUser = (state: AppState): boolean => getCoreState(state).isGuestUser;
export const getGeoMusicAllowed = (state: AppState): boolean => getCoreState(state).geoMusicAllowed ?? false;
export const isAuthenticated = createSelector(getUserEntity, (user: User | undefined): boolean => !!user);
export const getUser = createSelector(getUserEntity, isTestUser, isGuestUser, (user, isTestUserValue, isGuest) => ({
  user,
  isTestUser: isTestUserValue,
  isGuest,
}));

export const getUserId = createSelector(
  getUser,
  (userEntity: { user: User | undefined; isTestUser: boolean; isGuest: boolean }): string | undefined =>
    userEntity?.user?.userId,
);
// #endregion User

// #region Authentication
export const getAuthenticationState = createSelector(getCoreState, (state: CoreAppState) => state.authenticationState);
export const getUserAuthenticationState = createSelector(
  getAuthenticationState,
  getUser,
  (state, { isGuest, user }) => ({
    state,
    user,
    isGuest,
  }),
);

// #endregion Authentication

// #region Company
export const getCompany = createSelector(getCoreState, (state) => state.company);

export const getAccountOwner = createSelector(getCompany, (company): string | undefined => company?.ownerUserId);

export const isAccountOwner = createSelector(getUserEntity, getCompany, (user, company): boolean => {
  const { userId } = user ?? {};
  if (!userId) {
    return false;
  }
  return userId === company?.ownerUserId;
});

export const getUserAndAccount = createSelector(getUser, getCompany, (user, company) => {
  return { user, company };
});
// #endregion Company

// #region User Profile
export const getUserProfile = createSelector(getCoreState, (state) => state.userProfile);

export const getUserAndProfileAndAccountInfo = createSelector(
  getUser,
  getCompany,
  getUserProfile,
  (user, company, userProfile) => {
    return { user, company, userProfile };
  },
);
// #endregion User Profile

// #region License
export const getLicenseType = createSelector(getCoreState, (state) => state.license);

export const getLicense = createSelector(getLicenseType, (type): string => {
  let license = 'Basic';
  const licenseType = type ?? LicenseType.BASIC;
  switch (licenseType) {
    case LicenseType.BASIC:
      license = 'Basic';
      break;
    case LicenseType.TEAM:
      license = 'Team';
      break;
    case LicenseType.BUSINESS:
      license = 'Business';
      break;
    case LicenseType.PREMIUM:
      license = 'Premium';
      break;
    case LicenseType.ENTERPRISE:
      license = 'Enterprise';
      break;
    default:
      assertNever(licenseType);
  }
  return license;
});

// #endregion License

// #region Config
export const getConfig = createSelector(getCoreState, (state) => state.config);
export const getConfigErrorCount = createSelector(getCoreState, (state) => state.configErrorCount);
// #endregion Config

// #region Feature Flags
export const getFeatureFlags = createSelector(getCoreState, (state): LaunchDarklyFlagValues | undefined => state.flags);
// #endregion Feature Flags

// #region LaunchDarkly
/**
 * Get bundled authentication status for launch darkly targeting
 */
export const getLaunchDarklyAuthenticationState = createSelector(
  getUserAuthenticationState,
  getCompany,
  (userAuthenticationState, company) => {
    return { ...userAuthenticationState, company };
  },
);
// #endregion LaunchDarkly
