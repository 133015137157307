export enum AmplitudeEvents {
  WEB_PAGE_LOAD = 'WebPageLoad',
  NETWORK_FEED_DISPLAYED = 'NetWorkFeedDisplayed',
  SIGN_UP_CONFIRM_ACCOUNT = 'SignUpConfirmAccount',
  REGISTRATION_USER_SIGN_UP = 'RegistrationUserSignup',
  SIGN_UP_JOIN_NOW = 'SignUpJoinNow',
  CONTENT_LIKED = 'ContentLiked',
  CONTENT_SHARED = 'ContentShared',

  // THIRD PARTY SIGN IN
  IDENTITY_PROVIDER_SIGN_IN = 'IdentityProviderSignin',
  IDENTITY_PROVIDER_CREATE_ACCOUNT = 'IdentityProviderCreateAccount',
  IDENTITY_PROVIDER_BACK_TO_SIGN_IN = 'IdentityProviderBackToSignIn',

  // CREATE SOCIAL FUNNEL
  CREATE_SOCIAL_HOST_SOCIAL_CLICKED = 'CreateSocialHostSocialClicked',
  CREATE_SOCIAL_LINK_COPIED = 'CreateSocialLinkCopied',
  CREATE_SOCIAL_SOCIAL_SETTINGS_OPENED = 'CreateSocialSocialSettingsOpened',
  CREATE_SOCIAL_SOCIAL_NAME_GIVEN = 'CreateSocialSocialNameGiven',
  CREATE_SOCIAL_PLAYLIST_SET = 'CreateSocialPlaylistSet',
  CREATE_SOCIAL_LINK_SETTINGS_CHANGED = 'CreateSocialLinkSettingsChanged',
  CREATE_SOCIAL_THEME_SELECTED = 'CreateSocialThemeSelected',
  CREATE_SOCIAL_SOCIAL_STARTED = 'CreateSocialSocialStarted',
  CREATE_SOCIAL_SOCIAL_SAVED = 'CreateSocialSocialSaved',
  UPCOMING_SOCIALS_VIEW_MY_SOCIALS_CLICKED = 'UpcomingSocialsViewMySocialsClicked',
  UPCOMING_SOCIALS_SOCIAL_DELETED = 'UpcomingSocialsSocialDeleted',
  UPCOMING_SOCIALS_EDIT_SOCIAL = 'UpcomingSocialsEditSocial',
  UPCOMING_SOCIALS_SOCIAL_SETTINGS_CHANGED = 'UpcomingSocialsSocialSettingsChanged',
  UPCOMING_SOCIALS_SOCIAL_STARTED = 'UpcomingSocialsSocialStarted',

  // SOCIAL PERFORMER FUNNEL
  SOCIAL_CLICK_PRESENT = 'SocialClickPresentMenuOption',
  SOCIAL_CLICK_JOIN_PRESENTATION = 'SocialClickJoinPresentationMenuOption',
  SOCIAL_OPEN_SELECT_PERFORMER_MODAL = 'SocialOpenSelectPerformerModal',
  SOCIAL_SELECT_PERFORMER = 'SocialSelectPerformer',
  SOCIAL_HOST_ENDS_PERFORMANCE = 'SocialHostEndsPerformance',
  SOCIAL_PERFORMER_ENDS_PERFORMANCE = 'SocialPerformerEndsPerformance',
  SOCIAL_PERFORMER_CHANGES_CROWD_VOLUME = 'SocialPerformerChangesCrowdVolume',
  SOCIAL_USER_CHANGES_PERFORMER_VOLUME = 'SocialUserChangesPerformerVolume',
  SOCIAL_PERFORMER_VIEW_RESIZED = 'SocialPerformerViewResized',
  SOCIAL_PRESENTER_CLICKS_SHARE_SCREEN = 'SocialPresenterClicksShareScreen',
  SOCIAL_PRESENTER_SELECTS_SCREEN = 'SocialPresenterSelectsScreen',
  SOCIAL_PRESENTER_SCREEN_SHARE_ENDED = 'SocialPresenterScreenShareEnded',

  // GROUP SCREEN SHARE
  SOCIAL_GROUP_CLICKS_SHARE_SCREEN = 'SocialGroupClicksShareScreen',
  SOCIAL_GROUP_SELECTS_SCREEN = 'SocialGroupSelectsScreen',
  SOCIAL_GROUP_SCREEN_SHARE_ENDED = 'SocialGroupScreenShareEnded',
  SOCIAL_GROUP_SHARE_VIEW_RESIZED = 'SocialGRoupShareViewResized',

  // SOCIAL GENERAL EVENTS
  SOCIAL_FIRST_MOVEMENT = 'SocialFirstMovement',
  SOCIAL_OPEN_HELP_MODAL = 'SocialOpenHelpModal',
  SOCIAL_NAVIGATED_TO_HELP_CENTER = 'SocialNavigatedToHelpCenter',
  SOCIAL_NAVIGATED_TO_TICKET_SUBMISSION = 'SocialNavigatedToTicketSubmission',
  SOCIAL_CHANGE_VOLUME = 'SocialChangeVolume',
  SOCIAL_HEADER_CLICK_LEARN_MORE = 'SocialHeaderClickLearnMore',
  SOCIAL_MINI_MAP_CLICKED = 'SocialMiniMapClicked',
  SOCIAL_OPTIONS_CLICKED = 'SocialOptionsClicked',
  SOCIAL_LEAVE_SOCIAL_BUTTON = 'SocialLeaveSocialButton',
  SOCIAL_LEAVE_SOCIAL_BROWSER_CLOSE = 'SocialLeaveSocialBrowserClose',

  // PRESENTATION AUDIO SETTINGS
  AUDIO_MODES_PRESET_CLICKED = 'AudioModesPresetClicked',
  AUDIO_MODES_ADVANCED_SETTINGS_CHANGED = 'AudioModesAdvancedSettingsChanged',
  AUDIO_MODES_SETTINGS__SAVED = 'AudioModesSettingsSaved',
  AUDIO_MODES_HAND_DISMISSED = 'AudioModesHandDismissed',
  AUDIO_MODES_HAND_CALLED_ON = 'AudioModesHandCalledOn',
  AUDIO_MODES_DISABLE_QA_CLICKED = 'AudioModesDisableQ&AClicked',
  AUDIO_MODES_RAISED_HAND = 'AudioModesRaisedHand',
  AUDIO_MODES_LOWER_HAND = 'AudioModesLowerHand',
  AUDIO_MODES_SPEAK_TO_ROOM = 'AudioModesSpeakToRoom',

  // SOCIAL SHARE MODAL
  SOCIAL_SHARE_MODAL_PROMPTED = 'SocialShareModalPrompted',
  SOCIAL_SHARE_LINK = 'SocialShareLink',
  SOCIAL_SHARE_MODAL_DISMISSED = 'SocialShareModalDismissed',

  // SOCIAL BADGE
  SOCIAL_BADGE_LINK_CLICKED = 'SocialBadgeLinkClicked',

  // HOST ADMISSION
  SOCIAL_CLICK_GUEST_LIST = 'SocialClickGuestList',
  SOCIAL_DESIGNATE_NEW_HOST = 'SocialDesignateNewHost',
  SOCIAL_NEW_USER_IS_MADE_A_HOST = 'SocialNewUserIsMadeAHost',
  SOCIAL_REMOVE_USER = 'SocialRemoveUser',
  SOCIAL_USER_IS_KICKED_OUT = 'SocialUserIsKickedOut',

  // SOCIAL TOAST FUNNEL
  SOCIAL_TOAST_CLICK_MAKE_A_TOAST = 'SocialToastClickMakeAToast',
  SOCIAL_TOAST_START_TOAST = 'SocialToastStartToast',
  SOCIAL_TOAST_CANCEL_TOAST = 'SocialToastCancelToast',

  // SOCIAL CONVO FUNNEL
  SOCIAL_CONVO_CLICK_START_CONVO = 'SocialConvoClickStartConvo',
  SOCIAL_CONVO_SELECT_CATEGORY = 'SocialConvoSelectCategory',
  SOCIAL_CONVO_START_CONVO_CLICKED = 'SocialConvoStartConvoClicked',
  SOCIAL_CONVO_DISPLAY_TOASTER = 'SocialConvoDisplayToaster',
  SOCIAL_CONVO_DISPLAY_CONVO = 'SocialConvoDisplayConvo',
  SOCIAL_CONVO_TOGGLE_CONVO_DISPLAY = 'SocialConvoToggleConvoDisplay',
  SOCIAL_CONVO_END_CONVO = 'SocialConvoEndConvo',

  // SOCIAL WAITING ROOM
  SOCIAL_ENTER_WAITING_ROOM = 'SocialEnterWaitingRoom',
  WAITING_ROOM_CHANGE_DEVICE_INPUT = 'WaitingRoomChangeDeviceInput',
  WAITING_ROOM_CLICK_LEAVE = 'WaitingRoomClickLeave',
  SOCIAL_WAITING_ROOM_ENTER_SOCIAL = 'SocialWaitingRoomEnterSocial',
  SOCIAL_WAITING_ROOM_ENTERED_TUTORIAL = 'SocialWaitingRoomEnteredTutorial',
  SOCIAL_WAITING_ROOM_FINISHED_TUTORIAL = 'SocialWaitingRoomFinishedTutorial',

  // SOCIAL CREATE ACCOUNT
  SOCIAL_GUEST_CLICKS_CREATE_ACCOUNT = 'SocialGuestClicksCreateAccount',
  SOCIAL_GUEST_CLICKS_NO_THANKS = 'SocialGuestClicksNoThanks',
  SOCIAL_LOAD_LANDING_PAGE = 'SocialLoadLandingPage',
  SOCIAL_LANDING_PAGE_PROMPT_JOIN = 'SocialLandingPagePromptJoin',

  // SOCIAL GUEST
  SOCIAL_LOAD_GUEST_PAGE = 'SocialLoadGuestPage',
  SOCIAL_RESOLVE_LINK_CLICKED = 'SocialResolveLinkClicked',
  SOCIAL_CLICK_SIGN_IN_SIGN_UP = 'SocialClickSignInSignUp',
  SOCIAL_CLICK_CONTINUE_AS_GUEST = 'SocialClickContinueAsGuest',

  // SOCIAL TEMPLATES
  CREATE_SOCIAL_TEMPLATE_CLICKED = 'CreateSocialTemplateClicked',

  //SOCIAL ZOOM
  SOCIAL_CLICK_ZOOM_BUTTON = 'SocialClickZoomButton',
  SOCIAL_CLICK_AUTO_ZOOM_BUTTON = 'SocialClickAutoZoomButton',

  // SOCIAL ARTIFACTS
  SOCIAL_ARTIFACTS_CLICKED = 'SocialArtifactsClicked',
  SOCIAL_ARTIFACTS_ENABLED_FOR_PARTICIPANT = 'SocialArtifactsEnabledForParticipant',
  SOCIAL_NAVIGATION_ITEMS_CLICKED = 'SocialNavigationItemsClicked',

  //SOCIAL CHAT
  SOCIAL_CHAT_CLICK_CHAT_BUTTON = 'SocialChatClickChatButton',
  SOCIAL_CHAT_RECIPIENT_SELECTED = 'SocialChatRecipientSelected',
  SOCIAL_CHAT_SEND_MESSAGE = 'SocialChatSendMessage',
  SOCIAL_CHAT_CLICK_CHAT_SIDEBAR = 'SocialChatClickChatSidebarOption',

  // SOCIAL MENU SETTINGS
  SOCIAL_CLICK_PRESENTATION_SETTINGS_SIDEBAR = 'SocialClickPresentationSettingsSidebar',
  SOCIAL_CLICK_ROOM_SETTINGS_SIDEBAR = 'SocialClickRoomSettingsSidebar',

  // PHOTO BOOTH
  SOCIAL_PHOTO_BOOTH_CLICKED = 'SocialPhotoBoothClicked',
  SOCIAL_PHOTO_BOOTH_SNAP_PHOTOS_CLICKED = 'SocialPhotoBoothSnapPhotosClicked',
  SOCIAL_PHOTO_BOOTH_PHOTO_TAKEN = 'SocialPhotoBoothPhotoTaken',
  SOCIAL_PHOTO_BOOTH_PHOTO_PREVIEW_MODAL_SHOWN = 'SocialPhotoBoothPhotoPreviewModalShown',
  SOCIAL_PHOTO_BOOTH_CLICK_DOWNLOAD = 'SocialPhotoBoothClickDownload',
  SOCIAL_PHOTO_BOOTH_CLICK_SHARE = 'SocialPhotoBoothClickShare',
  SOCIAL_PHOTO_BOOTH_SELECT_SHARE_TO = 'SocialPhotoBoothSelectShareTo',

  // SOCIAL HOME PAGE
  SOCIAL_LANDING_HOW_TO_GUIDE_CLICKED = 'SocialLandingPage_HowToGuideClicked',
  SOCIAL_LANDING_CONTACT_SALES_CLICKED = 'SocialLandingPage_ContactSalesClicked',
  SOCIAL_LANDING_LEARN_MORE_CLICKED = 'SocialLandingPage_LearnMoreClicked',
  SOCIAL_LANDING_SAVED_ROOMS_EXPANDED = 'SocialLandingPage_SavedRoomsExpanded',
  SOCIAL_LANDING_SAVED_ROOMS_MINIMIZED = 'SocialLandingPage_SavedRoomsMinimized',

  // AUDIENCE VIEW
  AUDIENCE_VIEW_RECORDING_STARTED = 'AudienceViewRecordingStarted',
  AUDIENCE_VIEW_RECORDING_STOPPED = 'AudienceViewRecordingStopped',
  AUDIENCE_VIEW_SHARE_SCREEN_CLICKED = 'AudienceViewShareScreenClicked',
  AUDIENCE_VIEW_MANAGE_PRESENTER_CLICKED = 'AudienceViewManagePresenterClicked',
  AUDIENCE_VIEW_AUDIO_SETTINGS_CLICKED = 'AudienceViewAudioSettingsClicked',
  AUDIENCE_VIEW_PAGINATION_CHANGED = 'AudienceViewPaginationChanged',

  // TUTORIAL
  TUTORIAL_VIEWED = 'TutorialViewed',
  TIPS_FOR_A_GREAT_EXPERIENCE_VIEWED = 'TipsForAGreatExperienceViewed',

  // CTA ITEMS
  CTA_ITEM_CLICKED = 'CTAItemClicked',

  // TOOL BAR
  TOOL_BAR_PRESENTATION_CLICKED = 'ToolBarPresentationClicked',
  TOOL_BAR_CHAT_CLICKED = 'ToolBarChatClicked',
  TOOL_BAR_PRESENT_CLICKED = 'ToolBarPresentClicked',
  TOOL_BAR_JOIN_PRESENTATION_CLICKED = 'ToolBarJoinPresentationClicked',
  TOOL_BAR_SHARE_SCREEN_CLICKED = 'ToolBarShareScreenClicked',
  TOOL_BAR_GROUP_ACTIVITIES_CLICKED = 'ToolBarGroupActivitiesClicked',
  TOOL_BAR_GROUP_SCREEN_SHARE_CLICKED = 'ToolBarGroupScreenShareClicked',
  TOOL_BAR_MAP_CLICKED = 'ToolBarMapClicked',
  TOOL_BAR_SEARCH_CLICKED = 'ToolBarSearchClicked',
  TOOL_BAR_MENU_CLICKED = 'ToolBarMenuClicked',

  // SEARCH
  SEARCH_RESULT_CLICKED = 'SearchResultClicked',
  SEARCH_INPUT_DISMISSED_CLICKED = 'SearchInputDismissedClicked',

  // MENU
  MENU_CHAT_CLICKED = 'MenuChatClicked',
  MENU_VIDEO_EFFECTS_CLICKED = 'MenuVideoEffectsClicked',
  MENU_EDIT_SMART_BADGE_CLICKED = 'MenuEditSmartBadgeClicked',
  MENU_COPY_INVITE_LINK_CLICKED = 'MenuCopyInviteLinkClicked',
  MENU_TOAST_CLICKED = 'MenuToastClicked',
  MENU_ICEBREAKER_CLICKED = 'MenuIcebreakerClicked',
  MENU_GUEST_SMART_BADGE_CLICKED = 'MenuGuestSmartBadgeClicked',
  MENU_GUEST_CHAT_CLICKED = 'MenuGuestChatClicked',
  MENU_ROOM_MUSIC_CLICKED = 'MenuRoomMusicClicked',
  MENU_DISMISS_CLICKED = 'MenuDismissClicked',

  // PARTICIPANT BUBBLE

  PARTICIPANT_BUBBLE_SMART_BADGE_CLICKED = 'ParticipantBubbleSmartBadgeClicked',
  PARTICIPANT_BUBBLE_SPEAKER_CLICKED = 'ParticipantBubbleSpeakerClicked',
  PARTICIPANT_BUBBLE_CHAT_CLICKED = 'ParticipantBubbleChatClicked',

  // SMART BADGE

  SMART_BADGE_LINKEDIN_CLICKED = 'SmartBadgeLinkedInClicked',
  SMART_BADGE_FIND_CLICKED = 'SmartBadgeFindClicked',
  SMART_BADGE_TELEPORT_CLICKED = 'SmartBadgeTeleportClicked',
  SMART_BADGE_CHAT_CLICKED = 'SmartBadgeChatClicked',
  SMART_BADGE_DISMISS_CLICKED = 'SmartBadgeDismissClicked',
  SMART_BADGE_OPENED = 'SmartBadgeOpened',

  // PRESENTATION DRAWER

  PRESENTATION_DRAWER_CLICKED = 'PresentationDrawerClicked',

  // GALLERY

  GALLERY_PARTICIPANT_SMART_BADGE_CLICKED = 'GalleryParticipantSmartBadgeClicked',
  GALLERY_PAGINATION_CLICKED = 'GalleryPaginationClicked',

  // PRESENTATION

  PRESENTATION_PRESENTER_SMART_BADGE_CLICKED = 'PresentationPresenterSmartBadgeClicked',
  PRESENTATION_SCREEN_SHARE_SIZE_CLICKED = 'PresentationScreenShareSizeClicked',
  PRESENTATION_PRESENTERS_VISIBLE_CLICKED = 'PresentationPresentersVisibleClicked',
  PRESENTATION_SPEAKING_SMART_BADGE_CLICKED = 'PresentationSpeakingSmartBadgeClicked',
  PRESENTATION_STARTED = 'PresentationStarted',
  PRESENTATION_ENDED = 'PresentationEnded',
  PRESENTATION_PRESENTER_STOP_PRESENTING_CLICKED = 'PresentationPresenterStopPresentingClicked',
}
export default AmplitudeEvents;
