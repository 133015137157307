import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { canActivateTermsGuard } from '../core/guards/terms-guard.service';

import { UpdatedTermsBlockerComponent } from './updated-terms-blocker/updated-terms-blocker.component';

const routes: Routes = [
  {
    path: 'updated-terms',
    component: UpdatedTermsBlockerComponent,
    canActivate: [canActivateTermsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRouterModule {}
