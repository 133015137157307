import { Action, ActionCreator, ActionReducer, createReducer, ReducerTypes } from '@ngrx/store';
import produce from 'immer';

export const immerReducer =
  <State, Next>(reducer: (state: State, next: Next) => State) =>
  (state: State, next: Next): State =>
    produce(state, (draft: State) => reducer(draft, next));

export const createImmerReducer = <State, T extends Action = Action>(
  initialState: State,
  ...ons: ReducerTypes<State, ActionCreator[]>[]
): ActionReducer<State, T> => {
  const reducer = createReducer(initialState, ...ons);
  return (state: State = initialState, action: T) => immerReducer<State, T>(reducer)(state, action);
};
