import {
  ANYONE_CAN_CHANGE_MUSIC,
  ANYONE_CAN_PRESENT,
  ANYONE_CAN_SHARE,
  CONVO,
  PHOTO_BOOTH,
  PRESENT,
  SHARE_LINK,
  TOAST,
} from 'entity/SocialSettings';

import { SocialSettingsType } from '../social-settings/social-settings.type';

export const DEFAULT_SOCIAL_SETTINGS: SocialSettingsType = {
  [ANYONE_CAN_CHANGE_MUSIC]: true,
  [ANYONE_CAN_PRESENT]: true,
  [ANYONE_CAN_SHARE]: true,
  [CONVO]: true,
  [PHOTO_BOOTH]: true,
  [PRESENT]: true,
  [SHARE_LINK]: true,
  [TOAST]: true,
};

export default DEFAULT_SOCIAL_SETTINGS;
