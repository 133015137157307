// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import GuestUser, { processGuestUser } from "entity/GuestUser";
import GuestUserMetadata from "entity/GuestUserMetadata";
import GuestUserRoomSettings from "entity/GuestUserRoomSettings";
import OneTimeBearer from "entity/OneTimeBearer";
import User from "entity/User";

import { RestController, RestRequest } from "./RestController";

export default class GuestController extends RestController {

    addGuestEmail(emailAddress: string): Promise<void> {
        let request = new RestRequest("/api/v1/guest/user/email", "POST", null, { emailAddress: emailAddress });
        return this.requestVoid(request);
    }

    createGuestUser(user: User): Promise<GuestUser> {
        let request = new RestRequest("/api/v1/guest/user", "POST", user, undefined);
        return this.request(request, processGuestUser) as Promise<GuestUser>;
    }

    /*
     Use guest user bearer to generate a one time use token (bearer)
     */

    createOneTimeToken(tokenSeconds: number): Promise<OneTimeBearer> {
        let request = new RestRequest("/api/v1/guest/one/time/token", "POST", null, { tokenSeconds: tokenSeconds });
        return this.request(request, null) as Promise<OneTimeBearer>;
    }

    /*
     Use refresh token (refreshBearer) to get a guest user with an updated access token (bearer).
     */

    getGuestUser(): Promise<GuestUser> {
        let request = new RestRequest("/api/v1/guest/user", "GET", null, undefined);
        return this.request(request, processGuestUser) as Promise<GuestUser>;
    }

    getGuestUserMetadata(): Promise<GuestUserMetadata> {
        let request = new RestRequest("/api/v1/guest/user/metadata", "GET", null, undefined);
        return this.request(request, null) as Promise<GuestUserMetadata>;
    }

    getGuestUserRoomSettings(socialId: string): Promise<GuestUserRoomSettings> {
        let request = new RestRequest("/api/v1/guest/user/room/setting", "GET", null, { socialId: socialId });
        return this.request(request, null) as Promise<GuestUserRoomSettings>;
    }

    /*
     Use one time token (bearer) to get a guest user and fresh bearers
     */

    getGuestUserWithOneTimeToken(): Promise<GuestUser> {
        let request = new RestRequest("/api/v1/guest/user/one/time", "GET", null, undefined);
        return this.request(request, processGuestUser) as Promise<GuestUser>;
    }

    saveGuestUserMetadata(metadata: GuestUserMetadata): Promise<void> {
        let request = new RestRequest("/api/v1/guest/user/metadata", "POST", metadata, undefined);
        return this.requestVoid(request);
    }

    saveGuestUserRoomSettings(socialId: string, userId: string, settings: GuestUserRoomSettings): Promise<void> {
        let request = new RestRequest("/api/v1/guest/user/room/setting", "POST", settings, { socialId: socialId, userId: userId });
        return this.requestVoid(request);
    }

}
