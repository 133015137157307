import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class DeepLinkNavService {
  //Works with No Query Params or One Query Param
  public async navToDeepLink(stateURL: string, router: Router): Promise<void> {
    if (stateURL != null) {
      const deCodedState = decodeURI(stateURL);
      const deepRoute = deCodedState.substring(1);

      if (this.deepLinkHasQuery(deepRoute)) {
        const qIndex = deepRoute.indexOf('?');
        const newRoute = deepRoute.slice(0, qIndex);
        const qParams = deepRoute.slice(qIndex + 1);
        const pIndex = qParams.indexOf('=');
        const pKey = qParams.slice(0, pIndex);
        const pValue = qParams.slice(pIndex + 1);

        await this.navWithQueryParams(router, newRoute, pKey, pValue);
      } else {
        await router.navigate([deepRoute]);
      }
    }
  }

  private deepLinkHasQuery(deepRoute: string): boolean {
    if (deepRoute.includes('?')) {
      return true;
    } else {
      return false;
    }
  }

  private async navWithQueryParams(router: Router, routeToNav: string, key: string, param: string): Promise<void> {
    switch (key) {
      case 'magicCode':
        await router.navigate([routeToNav], { queryParams: { magicCode: param } });
        break;
      case 'groups':
        await router.navigate([routeToNav], { queryParams: { groups: param } });
        break;
      case 'create_group':
        await router.navigate([routeToNav], { queryParams: { create_group: param } });
        break;
      default:
        await router.navigate([routeToNav]);
        break;
    }
  }
}
