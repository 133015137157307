import { on } from '@ngrx/store';

import { createImmerReducer } from 'app/utils/createImmerReducer';

import * as actions from './actions';
import { MediaState } from './types';

export const initialState: MediaState = {
  audioEnabled: true,
  videoEnabled: true,

  readableCameras: {},

  cameras: [],
  mics: [],
  speakers: [],

  selectedCamera: null,
  selectedMic: null,
  selectedSpeaker: null,
};

export const mediaReducer = createImmerReducer(
  initialState,
  on(actions.MUTE_AUDIO, (state: MediaState) => {
    state.audioEnabled = false;
    return state;
  }),
  on(actions.UNMUTE_AUDIO, (state: MediaState) => {
    state.audioEnabled = true;
    return state;
  }),
  on(actions.MUTE_VIDEO, (state: MediaState) => {
    state.videoEnabled = false;
    return state;
  }),
  on(actions.UNMUTE_VIDEO, (state: MediaState) => {
    state.videoEnabled = true;
    return state;
  }),

  on(actions.AVAILABLE_CAMERAS_CHANGED, (state: MediaState, { cameras }) => {
    state.cameras = cameras;
    cameras.forEach((camera) => {
      state.readableCameras[camera.deviceId] = state.readableCameras[camera.deviceId] ?? true;
    });
    return state;
  }),

  on(actions.AVAILABLE_MICS_CHANGED, (state: MediaState, { mics }) => {
    state.mics = mics;
    return state;
  }),

  on(actions.AVAILABLE_SPEAKERS_CHANGED, (state: MediaState, { speakers }) => {
    state.speakers = speakers;
    return state;
  }),

  on(actions.CAMERA_SELECTED, (state: MediaState, { camera }) => {
    state.selectedCamera = camera;
    return state;
  }),

  on(actions.MIC_SELECTED, (state: MediaState, { mic }) => {
    state.selectedMic = mic;
    return state;
  }),

  on(actions.SPEAKER_SELECTED, (state: MediaState, { speaker }) => {
    state.selectedSpeaker = speaker;
    return state;
  }),

  on(actions.CAMERA_READABLE, (state: MediaState, { deviceId, readable }) => {
    state.readableCameras[deviceId] = readable;
    if (!readable && state.selectedCamera?.deviceId === deviceId) {
      state.videoEnabled = false;
    }
    return state;
  }),
);
