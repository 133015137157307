import { Injectable } from '@angular/core';

import Image from 'entity/Image';
import ImageController from 'rest/ImageController';

@Injectable({
  providedIn: 'root',
})
export class SocialMediaShareService {
  public async uploadImage(type: string, width: number, height: number, base64: string): Promise<Image> {
    const name = `social_group_photo_${Date.now()}`;
    return await new ImageController().uploadSafeBase64Image(name, type, width, height, base64);
  }
}
