// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

export const WEBSOCKET_MESSAGE_VERSION = '1.2';

import SocialSettings from 'entity/SocialSettings';

export enum IncomingMessageType {
  BADGE_DATA = 'badge-data',
  CC = 'cc',
  CHATHIST = 'chathist',
  CHATMSG = 'chatmsg',
  CHATRES = 'chatres',
  CLOSE_PEER = 'close-peer',
  CON = 'con',
  DATA = 'data',
  E = 'e',
  ENDED = 'ended',
  ERROR = 'error',
  FAVORITE_RESULT = 'favorite-result',
  FR = 'fr',
  INIT_STATE = 'init-state',
  INITPOS = 'initpos',
  LEFT = 'left',
  LS_ENDED = 'ls-ended',
  LS_FAILED = 'ls-failed',
  LS_OFFER = 'ls-offer',
  LS_PUBLISHED = 'ls-published',
  LS_STARTED = 'ls-started',
  LS_UNPUBLISHED = 'ls-unpublished',
  MEDIA = 'media',
  MIRROR_HUP = 'mirror-hup',
  MIRROR_OFFER = 'mirror-offer',
  MIRROR_STARTED = 'mirror-started',
  MIRROR_STATE = 'mirror-state',
  MOVED = 'moved',
  MOVEMENT_END_DURATION = 'movement-end-duration',
  MUSIC_CANDIDATE = 'music-candidate',
  MUSINF = 'musinf',
  NS = 'ns',
  OFFER = 'offer',
  PARTICIPANT_COUNT_UPDATE = 'participant-count-update',
  PARTICIPANTS = 'participants',
  PUBANS = 'pubans',
  PUBCAN = 'pubcan',
  PUBLISH = 'publish',
  PUBLISHING_HANGUP = 'publishing-hangup',
  PUBLISHING_STARTED = 'publishing-started',
  REACTION = 'reaction',
  REMOVE_JOINED_ANOTHER = 'remove-joined-another',
  RESIZE = 'resize',
  RING_COLORS = 'ring-colors',
  RTCCFG = 'rtccfg',
  SEARCH_RESULT = 'search-result',
  SERVER_DOWN = 'server-down',
  SERVER_ERROR = 'server-error',
  SESSION_CREATED = 'session-created',
  SESSION_TIMEOUT = 'session-timeout',
  SETTINGS_CHANGED = 'settings-changed',
  SHR_ENDED = 'shr-ended',
  SHR_HAS_ENDED = 'shr-has-ended',
  SHR_PUBANS = 'shr-pubans',
  SHR_PUBCAN = 'shr-pubcan',
  SHR_STARTED = 'shr-started',
  SHR_SUBCAN = 'shr-subcan',
  SHR_SUBOFF = 'shr-suboff',
  SLOW = 'slow',
  STARTED = 'started',
  STARTING_POINT_FULL = 'starting-point-full',
  STATE_CHANGE = 'state-change',
  STATE_CHANGE_ACK = 'state-change-ack',
  STATE_CHANGE_ERROR = 'state-change-error',
  STATE_DUMP_REQUEST = 'state-dump-request',
  STATE_NOT_CHANGED = 'state-not-changed',
  STR_OFFER = 'str-offer',
  SUBCAN = 'subcan',
  SUBHUB = 'subhub',
  SUBOFF = 'suboff',
  TU_AUDIO = 'tu-audio',
  TU_CMD = 'tu-cmd',
  UPDATE_USER_GROUP = 'update-user-group',
  USER = 'user',
  USER_LEFT = 'user-left',
  USERS = 'users',
  VERSION_CHECKED = 'version-checked',
}

export enum OutgoingMessageType {
  ADD_FAVORITE = 'add-favorite',
  ANSWER = 'answer',
  ARRAY_ADD_UNIQUE = 'array-add-unique',
  ARRAY_MULTI_ADD_UNIQUE = 'array-multi-add-unique',
  ARRAY_MULTI_REMOVE = 'array-multi-remove',
  ARRAY_REMOVE = 'array-remove',
  AS = 'as',
  CANDIDATE = 'candidate',
  CHATGET = 'chatget',
  CHATMODERATE = 'chatmoderate',
  CHATSEND = 'chatsend',
  CTA_CLICKED = 'cta-clicked',
  DATA = 'data',
  E = 'e',
  END_MIRROR = 'end-mirror',
  END_RECORDING = 'end-recording',
  GET_BADGE_DATA = 'get-badge-data',
  GET_MOVEMENT_END_DURATION = 'get-movement-end-duration',
  GET_USER = 'get-user',
  GET_USERS = 'get-users',
  LOG = 'log',
  LS_ANSWER = 'ls-answer',
  LS_CANDIDATE = 'ls-candidate',
  LS_END = 'ls-end',
  LS_PUB = 'ls-pub',
  LS_START = 'ls-start',
  LS_SUB = 'ls-sub',
  LS_UNPUB = 'ls-unpub',
  LS_UNSUB = 'ls-unsub',
  MAP_ADD = 'map-add',
  MAP_MULTI_ADD = 'map-multi-add',
  MAP_MULTI_REMOVE = 'map-multi-remove',
  MAP_REMOVE = 'map-remove',
  MIRROR_ANSWER = 'mirror-answer',
  MIRROR_CAN = 'mirror-can',
  MIRROR_SUB = 'mirror-sub',
  MIRROR_UNSUB = 'mirror-unsub',
  OVERALL_STATS = 'overall-stats',
  PROCESS_RECORDING = 'process-recording',
  PROPERTY_CHECKED = 'property-checked',
  PROPERTY_UNCHECKED = 'property-unchecked',
  PUB_BITRATE = 'pub-bitrate',
  PUB_STATS = 'pub-stats',
  PUBCAN = 'pubcan',
  PUBOFF = 'puboff',
  RECONNECT_MUSIC_PEER = 'reconnect-music-peer',
  REMOVE_FAVORITE = 'remove-favorite',
  SEARCH = 'search',
  SEND_TU_CMD = 'send-tu-cmd',
  SESSION = 'session',
  SET_MOVEMENT_END_DURATION = 'set-movement-end-duration',
  SET_REACTION = 'set-reaction',
  SET_TU_AUDIO = 'set-tu-audio',
  SET_VOLUME = 'set-volume',
  SHR = 'shr',
  SHRCAN = 'shrcan',
  SHREND = 'shrend',
  SHRSUB = 'shrsub',
  SHRSUBANS = 'shrsubans',
  SHRSUBCAN = 'shrsubcan',
  SHRUNSUB = 'shrunsub',
  SPEECH_TEXT = 'speech-text',
  START_MIRROR = 'start-mirror',
  START_RECORDING = 'start-recording',
  STATE_DUMP = 'state-dump',
  STR_ANSWER = 'str-answer',
  STR_CANDIDATE = 'str-candidate',
  STR_FWD = 'str-fwd',
  STR_MIX = 'str-mix',
  STR_STOP_FWD = 'str-stop-fwd',
  STR_SUB = 'str-sub',
  STR_UNSUB = 'str-unsub',
  SUB = 'sub',
  SUB_STATS = 'sub-stats',
  SUB_SUBSTREAM = 'sub-substream',
  SUBANS = 'subans',
  SUBCAN = 'subcan',
  SUBCFG = 'subcfg',
  TELEPORT = 'teleport',
  UNSUB = 'unsub',
  VERSION_CHECK = 'version-check',
}

export enum ChatStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ClientType {
  WEB_APP = 'WEB_APP',
  IOS_APP = 'IOS_APP',
}

export enum ConvoPhase {
  ANNOUNCE_PHASE = 'ANNOUNCE_PHASE',
  BANNER_PHASE = 'BANNER_PHASE',
}

export enum InteractionMode {
  MANY_PUSH_TO_SPEAK = 'MANY_PUSH_TO_SPEAK',
  SINGLE_PUSH_TO_SPEAK = 'SINGLE_PUSH_TO_SPEAK',
  QUEUED_Q_AND_A = 'QUEUED_Q_AND_A',
  OPEN = 'OPEN',
  NONE = 'NONE',
}

export enum MediaType {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
}

export enum MirrorType {
  PUBLISHER = 'PUBLISHER',
  SCREEN_SHARE = 'SCREEN_SHARE',
  LIVE_STREAM = 'LIVE_STREAM',
}

export enum MixModeType {
  IN_ROOM = 'IN_ROOM',
  CROSS_ROOM = 'CROSS_ROOM',
}

export enum PresentationMode {
  DISABLED = 'DISABLED',
  OPTIMIZED = 'OPTIMIZED',
  UNRESTRICTED = 'UNRESTRICTED',
}

export enum PresenterType {
  PRESENTER = 'PRESENTER',
  LIVE_STREAM = 'LIVE_STREAM',
  SCREEN_SHARE = 'SCREEN_SHARE',
}

export enum RecordingType {
  PUBLISHER = 'PUBLISHER',
  SCREEN_SHARE = 'SCREEN_SHARE',
  GROUP_SCREEN_SHARE = 'GROUP_SCREEN_SHARE',
  LIVE_STREAM = 'LIVE_STREAM',
  AUDIO_MIX = 'AUDIO_MIX',
}

export enum ScreenShareStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
}

export enum SocialEndType {
  HOST = 'HOST',
  TIME = 'TIME',
  EMPTY = 'EMPTY',
}

export enum SocialEventType {
  CONVO = 'CONVO',
  PRESENTATION = 'PRESENTATION',
  TOAST = 'TOAST',
  NONE = 'NONE',
  NEVER = 'NEVER',
}

export enum StatsTimeType {
  MILLISECONDS = 'MILLISECONDS',
  SECONDS = 'SECONDS',
  MICROSECONDS = 'MICROSECONDS',
  NANOSECONDS = 'NANOSECONDS',
}

export enum StatsValueType {
  DOUBLE = 'DOUBLE',
  BIGINT = 'BIGINT',
  VARCHAR = 'VARCHAR',
  BOOLEAN = 'BOOLEAN',
  TIMESTAMP = 'TIMESTAMP',
  MULTI = 'MULTI',
}

export enum UpdateType {
  CURRENT = 'CURRENT',
  ALL = 'ALL',
  OTHERS = 'OTHERS',
}

export interface TypedIncomingMessage {
  t: IncomingMessageType;
}
export interface TypedOutgoingMessage {
  t: OutgoingMessageType;
  txId?: string;
}

export interface AudioTrack {
  audioTrackId: string;
  trackKey: string;
  folder: string;
  fileSize: number;
  opusKey: string;
  opusSize: number;
  title: string;
  artists: string;
  composer: string;
  genre: string;
  album: string;
  duration: number;
  marketingLabel: string;
}

export interface BadgeDataValue {
  propertyName: string;
  value: string;
  multiValues?: string[];
  featured?: boolean;
}

export interface BaseUser {
  userId: string;
  firstName?: string;
  lastName?: string;
  profileImageURI?: string;
  profileImageBackground?: string;
  ringColor?: string;
  socialId?: string;
  cameraOn?: boolean;
  micOn?: boolean;
  publishing?: boolean;
  speaking?: boolean;
  reaction?: string;
}

export interface BlockingSocialState {
  socialId: string;
  state: SocialState;
}

export interface ClusterCenter {
  c: number;
  x: number;
  y: number;
  overfull?: boolean;
}

export interface Conversation {
  conversationId?: number;
  title?: string;
  description?: string;
}

export interface Convo {
  eventType: SocialEventType.CONVO;
  presenter: Presenter;
  phase: ConvoPhase;
  convoMessage: string;
  conversation: Conversation;
}

export interface EmbeddedMedia {
  url: string;
  // Use playing flag to play and pause
  playing: boolean;
  // Time when playing started in ms since epoch. Resume playing should update this.
  startTime: number;
  // How long media has been playing in ms. Seek based on startTime and playingTime.
  playtingTime: number;
}

export interface GroupScreenShares {
  map?: { [userId: string]: ScreenShare };
}

export interface Livestreams {
  queuedLivestreams?: { [streamId: string]: Presenter };
}

export interface MirrorState {
  mirrorId: string;
  audio: boolean;
  video: boolean;
  publishPeerTime: number;
}

export interface NeverEvent {
  eventType: SocialEventType.NEVER;
}

export interface NoneEvent {
  eventType: SocialEventType.NONE;
}

export interface ParticipantMovement {
  u: string;
  x: number;
  y: number;
  c: number;
  w?: boolean;
  teleported?: boolean;
}

export interface ParticipantPosition {
  userId: string;
  x: number;
  y: number;
  // ClusterId
  c: number;
  // If the user is waiting to join the group because it is full.
  w: boolean;
}

export interface ParticipantState {
  user: BaseUser;
  x: number;
  y: number;
  camera: boolean;
  mic: boolean;
  publishPeerTime: number;
}

export interface PeerCandidate {
  candidate: string;
  sdpMid: string;
  sdpMLineIndex: number;
}

export interface PeerJsep {
  type: RTCSdpType;
  sdp: string;
}

export interface Presentation {
  eventType: SocialEventType.PRESENTATION;
  presenters: { [userId: string]: Presenter };
  requestedPresenterIds: string[];
  speakingParticipants?: { [userId: string]: BaseUser };
  queuedToSpeakIds?: string[];
  mode?: PresentationMode;
  settings?: PresentationSettings;
  presentationCreator: string;
  crossRoom?: boolean;
  record?: boolean;
  recordingId?: string;
}

export interface PresentationSettings {
  defaultFullScreen: boolean;
  requireFullScreen: boolean;
  allowGroupConversations: boolean;
  interactionMode: InteractionMode;
}

export interface Presentations {
  map?: { [socialId: string]: String };
}

export interface Presenter {
  userId: string;
  firstName?: string;
  lastName?: string;
  isGuest: boolean;
  title?: string;
  description?: string;
  presenterType?: PresenterType;
  socialId?: string;
  media?: EmbeddedMedia;
}

export interface ReadOnlyState {
  bannedUsers: { [userId: string]: BaseUser };
  waitingUsers: { [userId: string]: BaseUser };
}

export interface RingColor {
  colorNumber: number;
  color: string;
  description: string;
}

export interface ScreenShare {
  presenter?: Presenter;
  groupId?: string;
  recordingId?: string;
  isGlobal?: boolean;
  viewerUserIds?: string[];
}

export interface SocialState {
  eventType: SocialEventType;
  socialEvent: SocialEvent;
  navigationMarkersEnabled: boolean;
  screenShareStatus: ScreenShareStatus;
  screenShare?: ScreenShare;
  livestreams?: Livestreams;
  groupScreenShares?: GroupScreenShares;
  presentations?: Presentations;
  hosts: string[];
  bannedUsers: string[];
  readOnly: ReadOnlyState;
}

export interface StatsDimension {
  Name: string;
  Value: string;
}

export interface StatsRecord {
  Dimensions: StatsDimension[];
  MeasureName: string;
  MeasureValue?: string;
  MeasureValueType: StatsValueType;
  Time: string;
  TimeUnit: StatsTimeType;
  MeasureValues?: StatsValue[];
}

export interface StatsValue {
  Name: string;
  Value: string;
  Type: StatsValueType;
}

export interface SvcSettings {
  bitrate: number;
  toastBitrate: number;
  hdBitrate: number;
  shareBitrate: number;
  scalabilityMode: string;
  toastScalabilityMode?: string;
  hdScalabilityMode: string;
  shareScalabilityMode: string;
}

export interface Toast {
  eventType: SocialEventType.TOAST;
  presenter: Presenter;
  startTime: string;
  message: string;
  toastIsActive: boolean;
}

// Incoming messages

export interface BadgeDataMessage extends TypedIncomingMessage {
  t: IncomingMessageType.BADGE_DATA;
  userId: string;
  data: BadgeDataValue[];
}

export interface ChatHistoryMessage extends TypedIncomingMessage {
  t: IncomingMessageType.CHATHIST;
  messages: ChatMessage[];
}

export interface ChatMessage extends TypedIncomingMessage {
  t: IncomingMessageType.CHATMSG;
  // The user who sent the message
  fromId: string;
  // The UserID(s) of the recipients of the message. 'all' indicates everyone in the room, 'all-rooms' is everyone and 'hosts' is all the hosts
  userIds: string | string[];
  group: string;
  // Status of the message. Pending message needs to be approved by a moderator.
  status?: ChatStatus;
  // User id of the moderator doing the status change
  moderatorId?: string;
  // The message timestamp when it was originally sent as pending
  sourceDate: number;
  privacy: string;
  text: string;
  // The message timestamp in unix milliseconds.
  sentDate: number;
}

export interface ChatResultMessage extends TypedIncomingMessage {
  t: IncomingMessageType.CHATRES;
  sentTo: string[];
  notSentTo: string[];
  status?: ChatStatus;
}

export interface ClosePeerMessage extends TypedIncomingMessage {
  t: IncomingMessageType.CLOSE_PEER;
  userId: string;
  pt: number;
}

export interface ClosedCaptionMessage extends TypedIncomingMessage {
  t: IncomingMessageType.CC;
  userId: string;
  text: string;
}

export interface ConnectedMeesage extends TypedIncomingMessage {
  t: IncomingMessageType.CON;
  userId: string;
  user: BaseUser;
  x: number;
  y: number;
  camera: boolean;
  mic: boolean;
  publishPeerTime: number;
}

export interface DataMessage extends TypedIncomingMessage {
  t: IncomingMessageType.DATA;
  d: string;
}

export interface ErrorMessage extends TypedIncomingMessage {
  t: IncomingMessageType.ERROR;
  code: number;
  error: string;
}

export interface FavoriteResultMessage extends TypedIncomingMessage {
  t: IncomingMessageType.FAVORITE_RESULT;
  userId: string;
  add: boolean;
  error: boolean;
  msg: string;
}

export interface FrameRateMessage extends TypedIncomingMessage {
  t: IncomingMessageType.FR;
  frameRate: number;
}

export interface InitialPositionMessage extends TypedIncomingMessage {
  t: IncomingMessageType.INITPOS;
  type: string;
  x: number;
  y: number;
  cluster: number;
  clusterX: number;
  clusterY: number;
  waiting: boolean;
  worldWidth: number;
  worldHeight: number;
  positions: ParticipantPosition[];
}

export interface InitialStateMessage extends TypedIncomingMessage {
  t: IncomingMessageType.INIT_STATE;
  state: SocialState;
  participants: ParticipantState[];
  mirrors?: MirrorState[];
  region: string;
}

export interface LiveStreamEndedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.LS_ENDED;
  userId: string;
  url: string;
  streamId: string;
  normalEnd: boolean;
}

export interface LiveStreamFailedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.LS_FAILED;
  streamId: string;
  url: string;
  error: string;
}

export interface LiveStreamOfferMessage extends TypedIncomingMessage {
  t: IncomingMessageType.LS_OFFER;
  streamId: string;
  d: PeerJsep;
  peerTime: number;
}

export interface LiveStreamPublishedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.LS_PUBLISHED;
  userId: string;
  url: string;
  streamId: string;
}

export interface LiveStreamStartedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.LS_STARTED;
  userId: string;
  url: string;
  streamId: string;
}

export interface LiveStreamUnpublishedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.LS_UNPUBLISHED;
  userId: string;
  url: string;
  streamId: string;
}

export interface MediaMessage extends TypedIncomingMessage {
  t: IncomingMessageType.MEDIA;
  userId: string;
  mediaType: MediaType;
  receiving: boolean;
}

export interface MirrorHangupMessage extends TypedIncomingMessage {
  t: IncomingMessageType.MIRROR_HUP;
  mirrorId: string;
  pt: number;
}

export interface MirrorStartedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.MIRROR_STARTED;
  mirrorId: string;
  audio: boolean;
  video: boolean;
  mirrorPeerTime: number;
}

export interface MirrorStateMessage extends TypedIncomingMessage {
  t: IncomingMessageType.MIRROR_STATE;
  mirrorId: string;
  audio: boolean;
  video: boolean;
}

export interface MirrorSubscribeOfferMessage extends TypedIncomingMessage {
  t: IncomingMessageType.MIRROR_OFFER;
  mirrorId: string;
  d: PeerJsep;
  pt: number;
}

export interface MovedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.MOVED;
  p: ParticipantMovement[];
  c: ClusterCenter[];
}

export interface MovementEndDurationMessage extends TypedIncomingMessage {
  t: IncomingMessageType.MOVEMENT_END_DURATION;
  duration: number;
}

export interface MusicOfferMessage extends TypedIncomingMessage {
  t: IncomingMessageType.OFFER;
  type: string;
  sdp: string;
  rtcConfig: string;
}

export interface MusicServerCandidateMessage extends TypedIncomingMessage {
  t: IncomingMessageType.MUSIC_CANDIDATE;
  candidate: string;
  sdpMid: string;
  sdpMLineIndex: number;
}

export interface NotifySpeakingMessage extends TypedIncomingMessage {
  t: IncomingMessageType.NS;
  userId: string;
  speaking: boolean;
}

export interface ParticipantCountUpdate extends TypedIncomingMessage {
  t: IncomingMessageType.PARTICIPANT_COUNT_UPDATE;
  socialId: string;
  count: number;
}

export interface ParticipantLeftJson extends TypedIncomingMessage {
  t: IncomingMessageType.LEFT;
  userId: string;
}

export interface ParticipantListMessage extends TypedIncomingMessage {
  t: IncomingMessageType.PARTICIPANTS;
  d: ParticipantState[];
}

export interface PlayingMessage extends TypedIncomingMessage {
  t: IncomingMessageType.MUSINF;
  type: string;
  playing: boolean;
  track: AudioTrack;
}

export interface PublishAnswerMessage extends TypedIncomingMessage {
  t: IncomingMessageType.PUBANS;
  d: PeerJsep;
  pt: number;
}

export interface PublishServerCandidateMessage extends TypedIncomingMessage {
  t: IncomingMessageType.PUBCAN;
  d: PeerCandidate;
  pt: number;
}

export interface PublishingHangupMessage extends TypedIncomingMessage {
  t: IncomingMessageType.PUBLISHING_HANGUP;
  userId: string;
  publishPeerTime: number;
}

export interface PublishingStartedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.PUBLISHING_STARTED;
  userId: string;
  publishPeerTime: number;
}

export interface ReactionMessage extends TypedIncomingMessage {
  t: IncomingMessageType.REACTION;
  userId: string;
  reaction?: string;
}

export interface RemoveJoinedAnotherMessage extends TypedIncomingMessage {
  t: IncomingMessageType.REMOVE_JOINED_ANOTHER;
}

export interface ResizeMessage extends TypedIncomingMessage {
  t: IncomingMessageType.RESIZE;
  width: number;
  height: number;
}

export interface RingColorsMessage extends TypedIncomingMessage {
  t: IncomingMessageType.RING_COLORS;
  indicatorLocation: string;
  colors: RingColor[];
}

export interface RtcConfigMessage extends TypedIncomingMessage {
  t: IncomingMessageType.RTCCFG;
  d: string;
}

export interface SearchResultMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SEARCH_RESULT;
  value: string;
  afterUserId?: string;
  results: BaseUser[];
}

export interface ServerDownMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SERVER_DOWN;
  serverIndex: number;
  relatedMessage: TypedOutgoingMessage;
}

export interface ServerErrorMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SERVER_ERROR;
  errorText: string;
  relatedMessage: TypedOutgoingMessage;
}

export interface SessionCreatedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SESSION_CREATED;
  videoServerIndex: number;
  videoServer: string;
  mixStreamId: number;
}

export interface SessionTimeoutMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SESSION_TIMEOUT;
}

export interface SettingsChangedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SETTINGS_CHANGED;
  settings: SocialSettings;
}

export interface SharingAnswerMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SHR_PUBANS;
  d: PeerJsep;
}

export interface SharingEndedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SHR_ENDED;
  userId: string;
}

export interface SharingHasEndedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SHR_HAS_ENDED;
  relatedMessage: TypedOutgoingMessage;
}

export interface SharingOfferMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SHR_SUBOFF;
  subId: string;
  groupShare: boolean;
  d: PeerJsep;
}

export interface SharingPublishServerCandidateMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SHR_PUBCAN;
  d: PeerCandidate;
  pt: number;
}

export interface SharingStartedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SHR_STARTED;
  userId: string;
  groupShare: boolean;
}

export interface SharingSubscriptionCandidateMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SHR_SUBCAN;
  d: PeerCandidate;
  pt: number;
}

export interface SlowLinkMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SLOW;
  time: number;
  slow: boolean;
}

export interface SocialEndedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.ENDED;
  reason: SocialEndType;
}

export interface StartPublishMessage extends TypedIncomingMessage {
  t: IncomingMessageType.PUBLISH;
  svc?: SvcSettings;
}

export interface StartedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.STARTED;
  currentServer: boolean;
  websocketUrl: string;
}

export interface StartingPointFullMessage extends TypedIncomingMessage {
  t: IncomingMessageType.STARTING_POINT_FULL;
}

export interface StateChangeAckMessage extends TypedIncomingMessage {
  t: IncomingMessageType.STATE_CHANGE_ACK;
  name: string;
  value: unknown;
  tx: unknown;
}

export interface StateChangeErrorMessage extends TypedIncomingMessage {
  t: IncomingMessageType.STATE_CHANGE_ERROR;
  name: string;
  error: string;
  tx: string;
}

export interface StateChangeMessage extends TypedIncomingMessage {
  t: IncomingMessageType.STATE_CHANGE;
  name: string;
  value?: unknown;
  tx: string;
  state: SocialState;
}

export interface StateDumpRequestMessage extends TypedIncomingMessage {
  t: IncomingMessageType.STATE_DUMP_REQUEST;
  namePostfix: string;
}

export interface StateNotChangedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.STATE_NOT_CHANGED;
  name: string;
  check?: unknown;
  value?: unknown;
  current?: unknown;
  tx: unknown;
  blocking?: BlockingSocialState[];
}

export interface StreamingOfferMessage extends TypedIncomingMessage {
  t: IncomingMessageType.STR_OFFER;
  d: PeerJsep;
}

export interface SubscribeOfferMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SUBOFF;
  subId: string;
  d: PeerJsep;
  pt: number;
}

export interface SubscriptionCandidateMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SUBCAN;
  subId: string;
  d: PeerCandidate;
  pt: number;
}

export interface SubscriptionHangupMessage extends TypedIncomingMessage {
  t: IncomingMessageType.SUBHUB;
  subId: string;
  pt: number;
}

export interface TestUserAudioMessage extends TypedIncomingMessage {
  t: IncomingMessageType.TU_AUDIO;
  muted: boolean;
}

export interface TestUserCommandMessage extends TypedIncomingMessage {
  t: IncomingMessageType.TU_CMD;
  command: string;
  userId?: string;
  data: string;
}

export interface TrackStateMessage extends TypedIncomingMessage {
  t: IncomingMessageType.E;
  userId: string;
  type: string;
  kind: string;
}

export interface UpdateUserGroupMessage extends TypedIncomingMessage {
  t: IncomingMessageType.UPDATE_USER_GROUP;
  cluster?: number;
  x?: number;
  y?: number;
}

export interface UserLeftMessage extends TypedIncomingMessage {
  t: IncomingMessageType.USER_LEFT;
  userId: string;
}

export interface UserMessage extends TypedIncomingMessage {
  t: IncomingMessageType.USER;
  userId: string;
  user: BaseUser;
}

export interface UsersMessage extends TypedIncomingMessage {
  t: IncomingMessageType.USERS;
  afterUserId?: string;
  users: BaseUser[];
}

export interface VersionCheckedMessage extends TypedIncomingMessage {
  t: IncomingMessageType.VERSION_CHECKED;
  minVersion: string;
  compatible: boolean;
}

// Outgoing messages

export interface AddFavoriteMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.ADD_FAVORITE;
  userId: string;
}

export interface ArrayAddUniqueMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.ARRAY_ADD_UNIQUE;
  name: string;
  value: unknown;
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface ArrayMultiAddUniqueMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.ARRAY_MULTI_ADD_UNIQUE;
  name: string;
  values: Array<unknown>;
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface ArrayMultiRemoveMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.ARRAY_MULTI_REMOVE;
  name: string;
  values: Array<unknown>;
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface ArrayRemoveMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.ARRAY_REMOVE;
  name: string;
  value: unknown;
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface CTAItemClickedMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.CTA_CLICKED;
  CTAItemId: number;
}

export interface ChatGetMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.CHATGET;
  beforeDate: number;
  maxCount: number;
}

export interface ChatModerateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.CHATMODERATE;
  fromUserId: string;
  text: string;
  sentDate: number;
  approve: boolean;
}

export interface ChatSendMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.CHATSEND;
  group: string;
  privacy: string;
  text: string;
  userIds: string | string[];
  sentDate: number;
}

export interface EndLiveStreamMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.LS_END;
  streamId: string;
}

export interface EndMirrorMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.END_MIRROR;
  socialId?: string;
  mirrorId: string;
  type: MirrorType;
}

export interface EndRecordingMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.END_RECORDING;
  type: RecordingType;
  groupShareUserId?: string;
}

export interface EndSharingMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SHREND;
  socialId: string;
  sharingUserId?: string;
  groupShare?: boolean;
}

export interface GetBadgeDataMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.GET_BADGE_DATA;
  userId: string;
}

export interface GetMovementEndDurationMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.GET_MOVEMENT_END_DURATION;
}

export interface GetUserMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.GET_USER;
  userId: string;
}

export interface GetUsersMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.GET_USERS;
  afterUserId?: string;
  maxCount: number;
}

export interface LiveStreamAnswerMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.LS_ANSWER;
  streamId: string;
  answer: PeerJsep;
  pt: number;
}

export interface LiveStreamCandidateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.LS_CANDIDATE;
  streamId: string;
  d: PeerCandidate;
  pt: number;
}

export interface LiveStreamSubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.LS_SUB;
  streamId: string;
  pt: number;
}

export interface LiveStreamUnsubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.LS_UNSUB;
  streamId: string;
  pt: number;
}

export interface LogMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.LOG;
  m: string;
  p: Array<unknown>;
}

export interface MapAddMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.MAP_ADD;
  key: string;
  name: string;
  value: unknown;
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface MapMultiAddMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.MAP_MULTI_ADD;
  name: string;
  values: Record<string, unknown>;
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface MapMultiRemoveMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.MAP_MULTI_REMOVE;
  name: string;
  keys: string[];
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface MapRemoveMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.MAP_REMOVE;
  name: string;
  key: string;
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface MirrorCandidateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.MIRROR_CAN;
  mirrorId: string;
  d: PeerCandidate;
  pt: number;
}

export interface MirrorSubscribeAnswerMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.MIRROR_ANSWER;
  mirrorId: string;
  answer: PeerJsep;
  pt: number;
}

export interface MirrorSubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.MIRROR_SUB;
  mirrorId: string;
  a: boolean;
  v: boolean;
  pt: number;
}

export interface MirrorUnsubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.MIRROR_UNSUB;
  mirrorId: string;
  pt: number;
}

export interface MusicAnswerMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.ANSWER;
  type: string;
  sdp: string;
}

export interface MusicCandidateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.CANDIDATE;
  candidate: string;
  sdpMid: string;
  sdpMLineIndex: number;
}

export interface OverallStatsMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.OVERALL_STATS;
  Records: StatsRecord[];
}

export interface ProcessRecordingMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.PROCESS_RECORDING;
  recordingId: string;
}

export interface PropertyCheckedStateChangeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.PROPERTY_CHECKED;
  name: string;
  value: unknown;
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface PropertyUncheckedStateChangeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.PROPERTY_UNCHECKED;
  name: string;
  value: unknown;
  checkProperty?: string;
  check?: unknown;
  checkAll?: boolean;
  tx: string;
  changes?: Record<string, unknown>;
  updateType?: UpdateType;
}

export interface PublishBitrateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.PUB_BITRATE;
  bitrate: number;
  peerTime: number;
}

export interface PublishCandidateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.PUBCAN;
  d: PeerCandidate;
  pt: number;
}

export interface PublishLiveStreamMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.LS_PUB;
  socialId?: string;
  streamId: string;
}

export interface PublishOfferMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.PUBOFF;
  offer: PeerJsep;
  pt: number;
  bitrate?: number;
}

export interface PublishStatsMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.PUB_STATS;
  Records: StatsRecord[];
}

export interface ReconfigureMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SUBCFG;
  subId: string;
  a: boolean;
  v: boolean;
  pt: number;
}

export interface ReconnectMusicPeerMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.RECONNECT_MUSIC_PEER;
}

export interface RemoveFavoriteMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.REMOVE_FAVORITE;
  userId: string;
}

export interface SearchMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SEARCH;
  value: string;
  afterUserId?: string;
  maxCount: number;
  onlyPresent?: boolean;
  onlyFavorites?: boolean;
}

export interface SendDataMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.DATA;
  d: string;
}

export interface SendTestUserCommandMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SEND_TU_CMD;
  command: string;
  userId?: string;
  data: string;
}

export interface SessionMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SESSION;
  user: BaseUser;
  reconnect: boolean;
  camera: boolean;
  mic: boolean;
  publishPeerTime: number;
}

export interface SetMovementEndDurationMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SET_MOVEMENT_END_DURATION;
  duration: number;
}

export interface SetReactionMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SET_REACTION;
  reaction?: string;
}

export interface SetTestUserAdioMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SET_TU_AUDIO;
  muted: boolean;
}

export interface SetVolumeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SET_VOLUME;
  userId: string;
  volume: number;
}

export interface SharingPublishCandidateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SHRCAN;
  userId: string;
  d: PeerCandidate;
  groupShare?: boolean;
}

export interface SharingSubscribeAnswerMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SHRSUBANS;
  sharingUserId?: string;
  groupShare?: boolean;
  answer: PeerJsep;
}

export interface SharingSubscribeCandidateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SHRSUBCAN;
  sharingUserId?: string;
  groupShare?: boolean;
  d: PeerCandidate;
}

export interface SharingSubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SHRSUB;
  sharingUserId?: string;
  groupShare?: boolean;
}

export interface SharingUnsubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SHRUNSUB;
  sharingUserId?: string;
  groupShare?: boolean;
}

export interface SpeakingStatusMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.AS;
  speaking: boolean;
  crossRoom: boolean;
}

export interface SpeechTextMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SPEECH_TEXT;
  text: string;
}

export interface StartLiveStreamMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.LS_START;
  streamId: string;
  url: string;
}

export interface StartMirrorMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.START_MIRROR;
  socialId?: string;
  mirrorId: string;
  type: MirrorType;
}

export interface StartRecordingMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.START_RECORDING;
  recordingId: string;
  type: RecordingType;
  groupShareUserId?: string;
}

export interface StartSharingMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SHR;
  offer: PeerJsep;
  groupShare?: boolean;
  bitrate?: number;
}

export interface StateDumpMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.STATE_DUMP;
  namePostfix: string;
  stateData: string;
}

export interface StreamingAnswerMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.STR_ANSWER;
  answer: PeerJsep;
  pt: number;
}

export interface StreamingCandidateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.STR_CANDIDATE;
  d: PeerCandidate;
  pt: number;
}

export interface StreamingForwardMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.STR_FWD;
}

export interface StreamingMixModeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.STR_MIX;
  mode: MixModeType;
}

export interface StreamingStopForwardingMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.STR_STOP_FWD;
}

export interface StreamingSubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.STR_SUB;
  pt: number;
}

export interface StreamingUnsubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.STR_UNSUB;
  pt: number;
}

export interface SubscribeAnswerMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SUBANS;
  subId: string;
  answer: PeerJsep;
  pt: number;
}

export interface SubscribeCandidateMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SUBCAN;
  subId: string;
  d: PeerCandidate;
  pt: number;
}

export interface SubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SUB;
  subId: string;
  a: boolean;
  v: boolean;
  pt: number;
  fromSocialId?: string;
}

export interface SubscribeSubstreamMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SUB_SUBSTREAM;
  subId: string;
  svc: boolean;
  substream: number;
  temporal: number;
  pt: number;
}

export interface SubscriptionStatsMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.SUB_STATS;
  Records: StatsRecord[];
}

export interface TeleportMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.TELEPORT;
  x: number;
  y: number;
}

export interface TrackStateChangeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.E;
  userId: string;
  type: string;
  kind: string;
}

export interface UnpublishLiveStreamMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.LS_UNPUB;
  socialId?: string;
  streamId: string;
}

export interface UnsubscribeMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.UNSUB;
  subId: string;
  pt: number;
}

export interface VersionCheckMessage extends TypedOutgoingMessage {
  t: OutgoingMessageType.VERSION_CHECK;
  clientType: ClientType;
  version: string;
}

// Unions

export type SocialEvent = Convo | NeverEvent | NoneEvent | Presentation | Toast;

export type IncomingMessage =
  | BadgeDataMessage
  | ChatHistoryMessage
  | ChatMessage
  | ChatResultMessage
  | ClosePeerMessage
  | ClosedCaptionMessage
  | ConnectedMeesage
  | DataMessage
  | ErrorMessage
  | FavoriteResultMessage
  | FrameRateMessage
  | InitialPositionMessage
  | InitialStateMessage
  | LiveStreamEndedMessage
  | LiveStreamFailedMessage
  | LiveStreamOfferMessage
  | LiveStreamPublishedMessage
  | LiveStreamStartedMessage
  | LiveStreamUnpublishedMessage
  | MediaMessage
  | MirrorHangupMessage
  | MirrorStartedMessage
  | MirrorStateMessage
  | MirrorSubscribeOfferMessage
  | MovedMessage
  | MovementEndDurationMessage
  | MusicOfferMessage
  | MusicServerCandidateMessage
  | NotifySpeakingMessage
  | ParticipantCountUpdate
  | ParticipantLeftJson
  | ParticipantListMessage
  | PlayingMessage
  | PublishAnswerMessage
  | PublishServerCandidateMessage
  | PublishingHangupMessage
  | PublishingStartedMessage
  | ReactionMessage
  | RemoveJoinedAnotherMessage
  | ResizeMessage
  | RingColorsMessage
  | RtcConfigMessage
  | SearchResultMessage
  | ServerDownMessage
  | ServerErrorMessage
  | SessionCreatedMessage
  | SessionTimeoutMessage
  | SettingsChangedMessage
  | SharingAnswerMessage
  | SharingEndedMessage
  | SharingHasEndedMessage
  | SharingOfferMessage
  | SharingPublishServerCandidateMessage
  | SharingStartedMessage
  | SharingSubscriptionCandidateMessage
  | SlowLinkMessage
  | SocialEndedMessage
  | StartPublishMessage
  | StartedMessage
  | StartingPointFullMessage
  | StateChangeAckMessage
  | StateChangeErrorMessage
  | StateChangeMessage
  | StateDumpRequestMessage
  | StateNotChangedMessage
  | StreamingOfferMessage
  | SubscribeOfferMessage
  | SubscriptionCandidateMessage
  | SubscriptionHangupMessage
  | TestUserAudioMessage
  | TestUserCommandMessage
  | TrackStateMessage
  | UpdateUserGroupMessage
  | UserLeftMessage
  | UserMessage
  | UsersMessage
  | VersionCheckedMessage;

export type OutgoingMessage =
  | AddFavoriteMessage
  | ArrayAddUniqueMessage
  | ArrayMultiAddUniqueMessage
  | ArrayMultiRemoveMessage
  | ArrayRemoveMessage
  | CTAItemClickedMessage
  | ChatGetMessage
  | ChatModerateMessage
  | ChatSendMessage
  | EndLiveStreamMessage
  | EndMirrorMessage
  | EndRecordingMessage
  | EndSharingMessage
  | GetBadgeDataMessage
  | GetMovementEndDurationMessage
  | GetUserMessage
  | GetUsersMessage
  | LiveStreamAnswerMessage
  | LiveStreamCandidateMessage
  | LiveStreamSubscribeMessage
  | LiveStreamUnsubscribeMessage
  | LogMessage
  | MapAddMessage
  | MapMultiAddMessage
  | MapMultiRemoveMessage
  | MapRemoveMessage
  | MirrorCandidateMessage
  | MirrorSubscribeAnswerMessage
  | MirrorSubscribeMessage
  | MirrorUnsubscribeMessage
  | MusicAnswerMessage
  | MusicCandidateMessage
  | OverallStatsMessage
  | ProcessRecordingMessage
  | PropertyCheckedStateChangeMessage
  | PropertyUncheckedStateChangeMessage
  | PublishBitrateMessage
  | PublishCandidateMessage
  | PublishLiveStreamMessage
  | PublishOfferMessage
  | PublishStatsMessage
  | ReconfigureMessage
  | ReconnectMusicPeerMessage
  | RemoveFavoriteMessage
  | SearchMessage
  | SendDataMessage
  | SendTestUserCommandMessage
  | SessionMessage
  | SetMovementEndDurationMessage
  | SetReactionMessage
  | SetTestUserAdioMessage
  | SetVolumeMessage
  | SharingPublishCandidateMessage
  | SharingSubscribeAnswerMessage
  | SharingSubscribeCandidateMessage
  | SharingSubscribeMessage
  | SharingUnsubscribeMessage
  | SpeakingStatusMessage
  | SpeechTextMessage
  | StartLiveStreamMessage
  | StartMirrorMessage
  | StartRecordingMessage
  | StartSharingMessage
  | StateDumpMessage
  | StreamingAnswerMessage
  | StreamingCandidateMessage
  | StreamingForwardMessage
  | StreamingMixModeMessage
  | StreamingStopForwardingMessage
  | StreamingSubscribeMessage
  | StreamingUnsubscribeMessage
  | SubscribeAnswerMessage
  | SubscribeCandidateMessage
  | SubscribeMessage
  | SubscribeSubstreamMessage
  | SubscriptionStatsMessage
  | TeleportMessage
  | TrackStateChangeMessage
  | UnpublishLiveStreamMessage
  | UnsubscribeMessage
  | VersionCheckMessage;
