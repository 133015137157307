export const isNullOrWhitespace = (input?: string | null): boolean => {
  if (input == null) {
    return true;
  }

  return input.replace(/\s/gi, '').length < 1;
};

export const safeString = (input?: string | null): string => input ?? '';

// return URL with special characters encoded
export const safeURL = (url: string): string => {
  return encodeURI(url);
};

export const legalChars = (url: string): string => {
  return url
    .replace(/[^a-zA-Z0-9]/g, '')
    .toLowerCase()
    .trim();
};

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
