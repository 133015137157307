// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import LogRequestBody from "entity/LogRequestBody";

import { RestController, RestRequest } from "./RestController";

export default class AppLogController extends RestController {

    /*
     Add log entries to cloudwatch. Returns sequence token to use for next request
     */

    cloudwatchLog(body: LogRequestBody): Promise<string> {
        let request = new RestRequest("/api/v1/app/cloudwatch/log", "POST", body, undefined);
        return this.request(request, null) as Promise<string>;
    }

    /*
     Add log entries to user app log
     */

    log(messages: string[]): Promise<void> {
        let request = new RestRequest("/api/v1/app/log", "POST", messages, undefined);
        return this.requestVoid(request);
    }

}
