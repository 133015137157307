/**
 * Get expiry for a JWT.
 * @param token A JWT.
 * @returns The token expiry in seconds (unix time). IF the token is invalid, 0 is returned;
 */
export const getTokenExpiry = (token: string): number => {
  try {
    // JWT is in the format: header.payload.signature
    // The contents are in base64url, not base64. Convert to base64 before decode.
    let payloadBase64 = token.split('.')[1]?.replace(/-/g, '+').replace(/_/g, '/');

    const pad = payloadBase64.length % 4;
    // Padding is invalid, return 0 (invalid token)
    if (pad === 1) {
      return 0;
    }
    if (pad) {
      payloadBase64 += new Array(5 - pad).join('=');
    }

    const jsonPayload = atob(payloadBase64);
    const payload = JSON.parse(jsonPayload);
    const { exp } = payload;
    return typeof exp === 'number' ? exp : 0;
  } catch (e) {
    return 0;
  }
};

export const isTokenExpired = (token: string): boolean => {
  const expiryMs = getTokenExpiry(token) * 1000;
  return expiryMs < Date.now() + 300000;
};
