// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import Paging from "./Paging";
import ResultEntity, { processResultEntityArray } from "entity/ResultEntity";

import { QueryParams, RestController, RestRequest } from "./RestController";

export default class EntityController extends RestController {

    findEntities(text?: string, page?: Paging): Promise<ResultEntity[]> {
        const queryParams: QueryParams = { };
        if (text !== undefined) {
          queryParams.text = text;
        }
        let request = new RestRequest("/api/v1/entity/find", "GET", null, queryParams);
        if (page) request.addPaging("", page);

        return this.request(request, processResultEntityArray) as Promise<ResultEntity[]>;
    }

}
