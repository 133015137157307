import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import DeviceType from 'messages/DeviceType';

@Injectable()
export class FooterService {
  public constructor(private router: Router) {}

  private getMobileOperatingSystem(): DeviceType {
    const userAgent = (navigator?.userAgent || navigator?.vendor || window['opera']) ?? '';

    if (/android/i.test(userAgent)) {
      return DeviceType.ANDROID;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      return DeviceType.IOS;
    }

    return DeviceType.UNKNOWN;
  }

  public async downloadApp(): Promise<void> {
    if (this.getMobileOperatingSystem() === DeviceType.IOS) {
      const linkData = {
        data: {
          path: location.pathname,
        },
      };
      window?.branch?.link?.(linkData, function (_err, link) {
        if (link) {
          window.location.href = link;
        }
      });
    } else {
      await this.router.navigate(['get-app']);
    }
  }
}
