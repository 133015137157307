/* eslint-disable @typescript-eslint/no-unused-vars */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { canActivateAuthGuard } from './core/guards/auth-guard.service';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';

const rootRouting: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/social/social.module').then((mod) => mod.SocialModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/scootaverse/scootaverse.module').then((mod) => mod.ScootaverseModule),
  },
  {
    path: 'welcome',
    loadChildren: () => import('./modules/signin/signin.module').then((mod) => mod.SigninModule),
  },
  {
    path: 'web/admin',
    loadChildren: () => import('./modules/admin/admin.module').then((mod) => mod.AdminModule),
    canActivate: [canActivateAuthGuard],
  },
  {
    path: 'web/settings',
    loadChildren: () => import('./modules/settings/settings.module').then((mod) => mod.SettingsModule),
    canActivate: [canActivateAuthGuard],
  },
  {
    path: 'recordings',
    loadChildren: () => import('./recordings/recordings.module').then((mod) => mod.RecordingsModule),
  },
  {
    path: 'web/guest',
    loadChildren: () => import('./customer-api/modules/customer-api.module').then((mod) => mod.CustomerAPIModule),
  },
  {
    path: 'web/shared',
    loadChildren: () => import('./shared/shared.module').then((mod) => mod.SharedModule),
  },
  {
    path: 'admin/preciate',
    loadChildren: () => import('./modules/preciate-admin/preciate-admin.module').then((mod) => mod.PreciateAdminModule),
  },
  {
    path: 'web/events',
    loadChildren: () => import('./modules/social-events/social-events.module').then((mod) => mod.SocialEventsModule),
  },
  {
    path: 'web/join',
    loadChildren: () => import('./modules/admin/admin.module').then((mod) => mod.AdminModule),
    canActivate: [canActivateAuthGuard],
  },
  {
    path: 'web/summaries',
    loadChildren: () => import('./modules/admin/admin.module').then((mod) => mod.AdminModule),
  },
  {
    path: 'web/social',
    redirectTo: '',
  },
  {
    path: '404',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(rootRouting, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
