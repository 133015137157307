import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { LoadingComponent } from './loading/loading.component';
import { ModalThirdPartySignInCheckerComponent } from './modal-third-party-sign-in-checker/modal-third-party-sign-in-checker.component';
import { BitratePipe } from './pipes/bitrate.pipe';
import { FileSizePipe } from './pipes/filesize.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ChipComponent } from './ui/chip/chip.component';
import { HelpLinkComponent } from './ui/help-link/help-link.component';
import { SelectDropdownComponent } from './ui/select/select-dropdown/select-dropdown.component';
import { SelectOptionComponent } from './ui/select/select-option/select-option.component';
import { SelectComponent } from './ui/select/select.component';

@NgModule({
  declarations: [
    LoadingComponent,
    ModalThirdPartySignInCheckerComponent,
    SelectComponent,
    SelectOptionComponent,
    SelectDropdownComponent,
    ChipComponent,
    HelpLinkComponent,
    BitratePipe,
    FileSizePipe,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltipModule,
    MatProgressBarModule,
  ],
  exports: [
    LoadingComponent,
    ModalThirdPartySignInCheckerComponent,
    SelectComponent,
    SelectOptionComponent,
    SelectDropdownComponent,
    ChipComponent,
    HelpLinkComponent,
    BitratePipe,
    FileSizePipe,
    TruncatePipe,
  ],
})
export class CommonsModule {}
