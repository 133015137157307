import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import Company from '../../../entity/Company';
import User from '../../../entity/User';

@Injectable({
  providedIn: 'root',
})
export class NavigationMenuService {
  // - We set the initial state in BehaviorSubject's constructor
  private readonly _company = new BehaviorSubject<Company>({});

  // Expose the observable$ part of the _todos subject (read only stream)
  public readonly company$ = this._company.asObservable();

  // the getter will return the last value emitted in _todos subject
  private get company(): Company {
    return this._company.getValue();
  }

  // assigning a value to this.todos will push it onto the observable
  // and down to all of its subsribers (ex: this.todos = [])
  private set company(val: Company) {
    this._company.next(val);
  }

  public setCompany(c?: Company): void {
    // we assign a new copy of todos by adding a new todo to it
    this.company = c || {};
  }

  private readonly _user = new BehaviorSubject<User>({});

  public readonly user$ = this._user.asObservable();

  public get user(): User {
    return this._user.getValue();
  }

  public set user(val: User | undefined) {
    this._user.next(val || {});
  }
}
