import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'app-modal-third-party-sign-in-checker',
  templateUrl: './modal-third-party-sign-in-checker.component.html',
  styleUrls: ['./modal-third-party-sign-in-checker.component.scss'],
})
export class ModalThirdPartySignInCheckerComponent implements OnDestroy {
  public closed: Subject<boolean> = new Subject<boolean>();
  public email: string;
  public termsAndConditionsAccepted = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
  ) {
    this.email = data.user.email;
  }

  public shouldCreateAccount(value: boolean): void {
    this.closed.next(value);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
