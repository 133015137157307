import { Action, on } from '@ngrx/store';

import { createImmerReducer } from 'app/utils/createImmerReducer';

import * as actions from './actions';
import { RecordingPageState } from './types';

export const RECORDING_PAGE_SIZE = 20;

export const initialState: RecordingPageState = {
  recordings: {
    data: [],
    page: 0,
    size: RECORDING_PAGE_SIZE,
    loading: false,
    hasMore: true,
  },
};

const _reducer = createImmerReducer(
  initialState,

  on(actions.INIT, (_: RecordingPageState) => {
    return initialState;
  }),

  on(actions.FETCH_RECORDINGS_REQ, (state: RecordingPageState, { page, size }) => {
    const { recordings } = state;
    if (page === 0) {
      recordings.hasMore = true;
      recordings.data = [];
    }
    recordings.page = page;
    recordings.size = size;
    recordings.loading = true;
    return state;
  }),

  on(actions.FETCH_RECORDINGS_RSP, (state: RecordingPageState, { recordings: newRecordings }) => {
    const { recordings } = state;

    if (newRecordings.length < recordings.size) {
      recordings.hasMore = false;
    }

    if (recordings.page === 0) {
      recordings.data = newRecordings;
    } else {
      recordings.data = [...recordings.data, ...newRecordings];
    }
    recordings.loading = false;
    return state;
  }),

  on(actions.FETCH_RECORDINGS_ERR, (state: RecordingPageState) => {
    const { recordings } = state;
    recordings.loading = false;
    return state;
  }),
);

export const recordingPageReducer = (
  state: RecordingPageState | undefined,
  action: Action,
): ReturnType<typeof _reducer> => _reducer(state, action);
