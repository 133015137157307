import { Action, on } from '@ngrx/store';

import { createImmerReducer } from 'app/utils/createImmerReducer';

import { LaunchDarklyFlagValues, LD_FLAG_DEFAULTS } from '../services/launch-darkly/launch-darkly.types';
import { isTestUser } from '../utilities/user-util';

import * as actions from './actions';
import { AuthenticationState, CoreAppState } from './types';
export const initialState: CoreAppState = {
  authenticationState: AuthenticationState.INIT,

  isTestUser: false,
  isGuestUser: false,
  configErrorCount: 0,
  geoMusicAllowed: false,
};

const _reducer = createImmerReducer(
  initialState,
  on(actions.INIT, (state: CoreAppState) => state),

  on(actions.AUTH_COMPLETE, (state: CoreAppState, { status }) => {
    state.authenticationState = status;
    return state;
  }),

  on(actions.AUTH_PROCESSING, (state: CoreAppState) => {
    state.authenticationState = AuthenticationState.PROCESSING;
    return state;
  }),

  on(actions.RESET_AUTH, (state: CoreAppState) => {
    state.authenticationState = AuthenticationState.INIT;
    return state;
  }),

  on(actions.USER_AUTH, (state: CoreAppState, { isGuest, user }) => {
    state.user = user;
    state.isTestUser = isTestUser(user);
    state.isGuestUser = isGuest;
    return state;
  }),

  on(actions.SET_USER, (state: CoreAppState, { user }) => {
    state.user = user;
    state.isTestUser = isTestUser(user);
    return state;
  }),

  on(actions.USER_LOG_OUT, (state: CoreAppState) => {
    state.user = undefined;
    state.company = undefined;
    state.isTestUser = initialState.isTestUser;
    return state;
  }),

  on(actions.SET_COMPANY, (state: CoreAppState, { company }) => {
    state.company = company;
    return state;
  }),

  on(actions.SET_LICENSE, (state: CoreAppState, { license }) => {
    state.license = license;
    return state;
  }),

  on(actions.FETCH_CONFIG_RSP, (state: CoreAppState, { config }) => {
    state.config = config;
    return state;
  }),

  on(actions.FETCH_CONFIG_ERR, (state: CoreAppState) => {
    state.configErrorCount += 1;
    return state;
  }),

  on(actions.FLAG_CHANGE, (state: CoreAppState, { flags }) => {
    const updatedFlags: LaunchDarklyFlagValues = {
      ...(state.flags ?? LD_FLAG_DEFAULTS),
      ...flags,
    };
    state.flags = updatedFlags;
    return state;
  }),

  on(actions.GEO_MUSIC_RSP, (state: CoreAppState, { allowed }) => {
    state.geoMusicAllowed = allowed;
    return state;
  }),

  on(actions.SET_USER_PROFILE, (state: CoreAppState, { userProfile }) => {
    state.userProfile = userProfile;
    return state;
  }),
);

export const coreReducer = (state: CoreAppState | undefined, action: Action): ReturnType<typeof _reducer> =>
  _reducer(state, action);
