import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getAuth, signOut } from 'firebase/auth';
import { ReplaySubject, timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';

import { AuthService } from 'app/services/auth.service';
import { Loggable } from 'app/utils/logging/loggable';
import ResultEntity from 'entity/ResultEntity';
import User from 'entity/User';
import EntityController from 'rest/EntityController';
import Paging from 'rest/Paging';

import { LaunchDarklyFlags } from '../services/launch-darkly/launch-darkly.types';
import { coreActions, coreSelectors, getFeatureFlagObservable } from '../state';

import { NavItems } from './constants';
@Component({
  selector: 'scoot-header',
  templateUrl: './scoot-header.component.html',
  styleUrls: ['./scoot-header.component.scss'],
})
export class ScootHeaderComponent extends Loggable implements OnInit {
  public user?: User;
  public navItems = NavItems;
  public searchControl = new UntypedFormControl();
  public results: ResultEntity[] = [];
  public openProfileDropdown = false;
  public isUserAuthed = false;
  public isGuest = false;

  private readonly pageSize = 20;
  private lastSearch?: string;
  private entityController = new EntityController();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store,
  ) {
    super();
    this.setDisplayName('ScootHeaderComponent');
    this.store
      .select(coreSelectors.getUser)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ isGuest, user }) => {
        this.user = user;
        this.isGuest = isGuest;
        this.isUserAuthed = !!user;
      });

    getFeatureFlagObservable(this.store, LaunchDarklyFlags.admin_transcriptions_page)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((admin_transcriptions_page) => {
        const transcription = { option: 'Transcriptions', route: '/web/admin/transcriptions', isMenu: false };
        if (admin_transcriptions_page && !this.navItems.some((item) => item.route === transcription.route)) {
          this.navItems.push(transcription);
        }
      });

    getFeatureFlagObservable(this.store, LaunchDarklyFlags.ai_summaries)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((aiSummaries) => {
        if (aiSummaries) {
          this.navItems = this.navItems.filter((item) => !(item.option === 'Reports' && !item.isMenu));
        } else {
          this.navItems = this.navItems.filter((item) => !(item.option === 'Reports' && item.isMenu));
        }
      });
  }

  public ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounce(() => timer(1000)),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: async (val) => {
          if (val != null && typeof val === 'string' && (val || '').trim().length > 0) {
            this.lastSearch = val;
            const results = await this.entityController.findEntities(val, new Paging(0, this.pageSize));
            if (this.lastSearch === val) {
              this.results = results;
            }
          } else {
            this.lastSearch = val;
            this.results = [];
          }
        },
      });
  }

  public async signOut(): Promise<void> {
    if (this.authService.isAnonymous()) {
      await signOut(getAuth()).then(async () => {
        await this.cleanAuthVariables();
      });
    } else {
      this.authService
        .signOutUser()
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: async (_res) => {
            await this.cleanAuthVariables();
          },
        });
    }
  }

  private async cleanAuthVariables(): Promise<void> {
    await this.router.navigate(['/welcome']);
    this.store.dispatch(coreActions.RESET_AUTH());
    this.authService.setUser(undefined);
    this.user = undefined;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  protected async navigateToWelcome(): Promise<void> {
    await this.router.navigate(['/welcome']);
  }

  protected navigateToPricing(): void {
    window.open('https://scoot.app/pricing/', '_blank');
  }
}
