import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as amplitude from 'amplitude-js';
import { ReplaySubject, filter, takeUntil } from 'rxjs';

import { coreSelectors } from 'app/core/state';
import { socialAppSelectors } from 'app/modules/social/state/app';
import { AppState } from 'app/types';
import { Loggable } from 'app/utils/logging/loggable';
import { notNil } from 'app/utils/stream-util';
import Company from 'entity/Company';
import Social from 'entity/Social';
import User from 'entity/User';

import { isInMicrosoftTeams } from '../../utilities/env-util';

import AmplitudeEvents from './amplitudeEvents';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService extends Loggable {
  public client?: amplitude.AmplitudeClient;
  private destroyed$ = new ReplaySubject<boolean>(1);
  private socialId?: string;
  private socialCompanyId?: string;
  private socialCompanyName?: string;
  private socialName?: string;
  private socialSessionId?: string;
  private socialRoomSetId?: string;
  private socialSize?: number;
  private socialServer?: string;
  private socialMaxParticipants?: number;
  private user?: User;

  public constructor(store: Store<AppState>) {
    super();

    this.setDisplayName('AmplitudeService');
    store
      .select(coreSelectors.getUser)
      .pipe(filter(notNil))
      .subscribe(({ isGuest, user }) => {
        if (user) {
          this.user = user;
          this.setUserId(user?.userId);
          this.setUserInfo(isGuest);
        }
      });

    // Social
    store
      .select(socialAppSelectors.getCurrentSocial)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((social) => {
        if (social) {
          // Set social specific info
          this.setSocialInfo(social);
          // Set social info for service.  This let's us compare if the information has changed before making calls to amplitude to update it
          this.socialId = social.socialId;
          this.socialCompanyId = social.companyId;
          this.socialCompanyName = social.companyName;
          this.socialName = social.name;
          this.socialSessionId = social.sessionId;
          this.socialRoomSetId = social.socialRoomSetId;
          this.socialSize = social.startingHeight;
          this.socialServer = social.socialMeta?.audioHost;
          this.socialMaxParticipants = social.maxParticipants;
        }
      });
  }

  public initialize(apiKey?: string): void {
    const host = location.origin.replace(location.protocol, '');
    const apiEndpoint = `${host}/amplitude`;
    const forceHttps = location.hostname !== 'localhost';
    if (!apiKey) {
      return this.error('Amplitude initialized without apiKey');
    }
    if (isInMicrosoftTeams()) {
      this.client = amplitude.getInstance('msTeams_project');
      this.client.init(apiKey, undefined, {
        apiEndpoint,
        forceHttps,
        platform: 'MsTeams',
      });
    } else {
      this.client = amplitude.getInstance();
      this.client.init(apiKey, undefined, {
        apiEndpoint,
        forceHttps,
      });
    }
  }

  public logEvent(event: AmplitudeEvents, eventProperty?: Record<string, unknown>): void {
    if (eventProperty != null) {
      this.client?.logEvent(event, eventProperty);
    } else {
      this.client?.logEvent(event);
    }
  }

  public setUserId(userId?: string): void {
    this.client?.setUserId(userId || null);
  }

  public setUserInfo(isGuest = false): void {
    const i = new amplitude.Identify();
    i.set('isGuest', isGuest);
    if (this.user != null && this.user.emailAddress != null) {
      i.set('emailAddress', this.user.emailAddress);
    }
    if (this.user != null && this.user.firstName != null) {
      i.set('firstName', this.user.firstName);
    }
    if (this.user != null && this.user.lastName != null) {
      i.set('lastName', this.user.lastName);
    }
    this.client?.identify(i);
  }

  public setCompanyInfo(company?: Company): void {
    if (company && company.companyId != null) {
      const i = new amplitude.Identify();
      i.set('companyId', company.companyId);
      i.set('companyName', company.companyName || '');
      this.client?.identify(i);
    }
  }

  // Set social specific info but only if the value has changed
  public setSocialInfo(social: Social): void {
    const i = new amplitude.Identify();
    let didChange = false;
    if (social.socialId != null && social.socialId !== this.socialId) {
      i.set('socialId', social.socialId);
      didChange = true;
    }
    if (social.companyId != null && social.companyId !== this.socialCompanyId) {
      i.set('socialCompanyId', social.companyId);
      didChange = true;
    }
    if (social.companyName != null && social.companyName !== this.socialCompanyName) {
      i.set('socialCompanyName', social.companyName);
      didChange = true;
    }
    if (social.name != null && social.name !== this.socialName) {
      i.set('socialName', social.name);
      didChange = true;
    }
    if (social.sessionId != null && social.sessionId !== this.socialSessionId) {
      i.set('socialSessionId', social.sessionId);
      didChange = true;
    }
    if (social.socialRoomSetId != null && social.socialRoomSetId !== this.socialRoomSetId) {
      i.set('socialRoomSetId', social.socialRoomSetId);
      didChange = true;
    }
    if (social.startingHeight != null && social.startingHeight !== this.socialSize) {
      i.set('socialSize', social.startingHeight);
      didChange = true;
    }
    if (social.socialMeta?.audioHost != null && social.socialMeta?.audioHost !== this.socialServer) {
      i.set('socialServer', social.socialMeta?.audioHost);
      didChange = true;
    }
    if (social.maxParticipants != null && social.maxParticipants !== this.socialMaxParticipants) {
      i.set('socialMaxParticipants', social.maxParticipants);
      didChange = true;
    }
    if (didChange) {
      this.client?.identify(i);
    }
  }
}
