import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalEventService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _eventSubjects: Subject<any>[] = [];
  private length = 0;

  public push(): void {
    if (this._eventSubjects) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._eventSubjects.push(new Subject<any>());
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._eventSubjects = [new Subject<any>()];
    }

    this.length = this._eventSubjects.length;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public peek(): Subject<any> {
    return this._eventSubjects[this.length - 1];
  }

  public pop(): void {
    const es = this._eventSubjects.pop();
    this.length = this._eventSubjects.length;
    es?.unsubscribe();
  }
}
