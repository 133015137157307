export const DEFAULT_MAX_VOLUME = 90;
export const DEFAULT_PRESENTER_VOLUME = 100;
export const DEFAUlT_MIX_VOLUME = 100;
export const DEFAULT_PARTICIPANT_MAX_VOLUME_PRESENTATION = 30;

// Used for announce phase of convo as well as toasts
export const DEFAULT_PARTICIPANT_MAX_VOLUME_TOAST = 5;

export const DEFAULT_MUSIC_VOLUME_TOAST = 5;

export const NO_GROUP_FAR_VOLUME = 30;
export const NO_GROUP_MID_VOLUME = 50;
export const NO_GROUP_NEAR_VOLUME = 90;

export const GROUP_FAR_VOLUME = 2;
export const GROUP_MID_VOLUME = 15;
export const GROUP_NEAR_VOLUME = 30;

export const NEAR_VOLUME = 50;
export const MID_VOLUME = 13;
export const FAR_VOLUME = 0;
