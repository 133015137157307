// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import User, { processUser } from "./User";

export default interface Image {
    imageId?: string;
    userId?: string;
    imageGroup?: string;
    imageName?: string;
    imageSize?: string;
    imageType?: string;
    imageColor?: string;
    visibleToPublic?: boolean;
    active?: boolean;
    imageURI?: string;
    user?: User;
    error?: string;
}

export function processImage(value?: Image): void {
  if (value) {
    processUser(value.user);
  }
}

export function processImageArray(array?: Image[]): void {
  if (array) {
    for (const v of array) {
      processImage(v);
    }
  }
}
