import { createAction, props } from '@ngrx/store';

import ClientConfiguration from 'entity/ClientConfiguration';
import Company from 'entity/Company';
import User from 'entity/User';
import UserProfile from 'entity/UserProfile';
import LicenseType from 'enums/LicenseType';

import { LaunchDarklyFlagValues } from '../services/launch-darkly/launch-darkly.types';

import { AuthenticationState } from './types';

export const INIT = createAction('Core/INIT');
export const AUTH_PROCESSING = createAction('Core/AUTH_PROCESSING');
export const AUTH_COMPLETE = createAction('Core/AUTH_COMPLETE', props<{ status: AuthenticationState }>());
export const RESET_AUTH = createAction('core/RESET_AUTH');

export const USER_AUTH = createAction('Core/USER_AUTH', props<{ user: User; isGuest: boolean }>());
export const SET_USER = createAction('Core/SET_USER', props<{ user: User }>());
export const USER_LOG_OUT = createAction('Core/USER_LOG_OUT');

export const SET_COMPANY = createAction('Core/COMPANY_RSP', props<{ company: Company }>());

export const SET_LICENSE = createAction('Core/LICENSE_RSP', props<{ license: LicenseType }>());

export const FETCH_CONFIG_REQ = createAction('Core/FETCH_CONFIG_REQ');
export const FETCH_CONFIG_RSP = createAction('Core/FETCH_CONFIG_RSP', props<{ config: ClientConfiguration }>());
export const FETCH_CONFIG_ERR = createAction('Core/FETCH_CONFIG_ERR', props<{ error: unknown }>());

export const GEO_MUSIC_RSP = createAction('Core/GEO_MUSIC_RSP', props<{ allowed: boolean }>());

export const SET_USER_PROFILE = createAction('Core/SET_USER_PROFILE', props<{ userProfile: UserProfile }>());

// #region Feature Flags
export const FLAG_CHANGE = createAction('Core/FLAG_CHANGE', props<{ flags: Partial<LaunchDarklyFlagValues> }>());
// #endregion Feature Flags
