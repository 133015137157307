import { LogService } from 'app/services/logging.service';

import { AppModuleServices } from '../app-module-util';

export class Loggable {
  public displayName = '<unknown>';
  public logger: LogService;

  public constructor() {
    this.logger = AppModuleServices.injector.get<LogService>(LogService);
  }

  public setDisplayName(name: string): void {
    this.displayName = name;
  }

  /**
   * Log that a user took an action
   * @param component The component that was interacted with
   * @param msg An arbitrary message
   * @param args Any additional logging context
   */
  public logUserAction(component: string, msg: string, ...args: unknown[]): void {
    const userActionMsg = `USER ACTION[${component}] ${msg}`;
    this.logger.info({ class: this.displayName, component, userAction: true }, userActionMsg, ...args);
  }

  public trace(msg: string, ...args: unknown[]): void {
    this.logger.trace({ class: this.displayName }, msg, ...args);
  }

  public debug(msg: string, ...args: unknown[]): void {
    this.logger.debug({ class: this.displayName }, msg, ...args);
  }

  public info(msg: string, ...args: unknown[]): void {
    this.logger.info({ class: this.displayName }, msg, ...args);
  }

  public warn(msg: string, ...args: unknown[]): void {
    this.logger.warn({ class: this.displayName }, msg, ...args);
  }

  public error(msg: string, ...args: unknown[]): void {
    this.logger.error({ class: this.displayName }, msg, ...args);
  }

  public fatal(msg: string, ...args: unknown[]): void {
    this.logger.fatal({ class: this.displayName }, msg, ...args);
  }
}
