import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { coreSelectors } from 'app/core/state';
import { Timeout, AppState } from 'app/types';
import LogRequestBody from 'entity/LogRequestBody';
import AppLogController from 'rest/AppLogController';

import { AppModuleServices } from '../app-module-util';
import { isBot, userAgent } from '../user-agent';

declare const __VERSION__: string;

const THROTTLE_INTERVAL_MS = 3000;
const MAX_LOG_ATTEMPTS = 5;
const MAX_RECORD_COUNT = 500;

interface CountableRecord extends Record<string, unknown> {
  msg: string;
  time: Date;
  count?: number;
}

const controller = new AppLogController();

let sequence = 0;

export class ServerStream {
  private sequenceToken?: string;
  private currentThrottleTimeout: Timeout | null = null;
  private records: Record<string, CountableRecord> = {};
  private logAttempts = 0;
  private authenticated = false;
  private userId?: string;

  private destroyed$ = new ReplaySubject<boolean>(1);
  private store: Store<AppState>;

  public constructor() {
    this.store = AppModuleServices.injector.get<Store<AppState>>(Store);
    this.store
      .select(coreSelectors.isAuthenticated)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((authenticated) => (this.authenticated = authenticated));

    this.store
      .select(coreSelectors.getUserId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userId) => (this.userId = userId));

    this.start();
    addEventListener('beforeunload', () => this.onUnload(), false);
  }

  public start(): void {
    const throttleRequests = () => {
      this.currentThrottleTimeout = setTimeout(async () => {
        const recs = this.recordsAsArray();

        if (!recs.length || !this.authenticated) {
          throttleRequests();
          return;
        }

        try {
          await this.sendRecords(recs);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn('Server stream log write failed', e);
          if (this.logAttempts >= MAX_LOG_ATTEMPTS) {
            // Dump failed logs to avoid infinitely growing pending log records
            this.records = {};
            this.logAttempts = 0;

            throw e;
          }
        } finally {
          throttleRequests();
        }
      }, THROTTLE_INTERVAL_MS);
    };

    throttleRequests();
  }

  public stop(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    setTimeout(() => {
      if (this.currentThrottleTimeout) {
        clearTimeout(this.currentThrottleTimeout);
        this.currentThrottleTimeout = null;
      }
    }, 1);
  }

  private async sendRecords(records: CountableRecord[]): Promise<void> {
    const logEvents = records.map(({ levelName, msg, time, ...rest }) => {
      const message = {
        msg: `[${levelName}] ${msg}`,
        levelName,
        userId: this.userId,
        version: __VERSION__,
        seq: sequence++,
        ...rest,
      };
      return {
        message: JSON.stringify(message),
        timestamp: time.getTime(),
      };
    });

    this.logAttempts += 1;
    const logRequest: LogRequestBody = { logEvents, sequenceToken: this.sequenceToken };
    try {
      const result = await controller.cloudwatchLog(logRequest);
      this.logAttempts = 0;
      this.sequenceToken = result;
      this.records = {};
    } catch (e) {
      const { status } = e as HttpErrorResponse;
      if (status === 401) {
        this.authenticated = false;
      }
    }
  }

  public write(rec: CountableRecord): void {
    // Cap the maximum number of records we will store before flush
    if (Object.keys(this.records).length > MAX_RECORD_COUNT) {
      return;
    }

    rec.url = location?.href ?? '';
    rec.userAgent = userAgent;
    if (!this.writeCondition()) {
      return;
    }

    if (!this.currentThrottleTimeout) {
      return;
    }

    const record = this.records[rec.msg];
    if (record) {
      record.count = record.count ? record.count + 1 : 1;
      return;
    }

    rec.count = 1;
    this.records[rec.msg] = rec;
  }

  private onUnload(): void {
    if (this.currentThrottleTimeout) {
      clearTimeout(this.currentThrottleTimeout);
    }

    const recs = this.recordsAsArray();
    if (!recs.length) {
      return;
    }

    if (this.authenticated) {
      void this.sendRecords(recs);
    }
  }

  private recordsAsArray(): CountableRecord[] {
    return [...Object.values(this.records)];
  }

  private writeCondition(): boolean {
    return navigator?.onLine && !isBot;
  }
}
