import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, firstValueFrom, from, map, Observable, Subject, tap } from 'rxjs';

import { isTokenExpired } from 'app/utils/jwt';
import { Loggable } from 'app/utils/logging/loggable';
import { notUndefined } from 'app/utils/stream-util';
import GuestUser from 'entity/GuestUser';
import User from 'entity/User';
import { GET_USER_IF_EXISTS } from 'rest/constants';
import GuestController from 'rest/GuestController';
import { RestController } from 'rest/RestController';

import { LocalStorageService } from './localStorage.service';

const guestController = new GuestController();

const GUEST_USER_KEY = 'preciateGuestUser';

/**
 * Get a value of a query param.
 */
const getQueryParam = (name: string): string | undefined => {
  const search = window?.location?.search ?? '';
  const params = new URLSearchParams(search);
  return params.get(name) ?? undefined;
};

/**
 * Create a guest user from the query params of the route.
 */
const getDataPassUser = (): User => {
  const firstName = getQueryParam('firstname');
  const lastName = getQueryParam('lastname');
  const emailAddress = getQueryParam('email');
  return firstName && lastName
    ? {
        firstName,
        lastName,
        emailAddress,
      }
    : {};
};

export const isDataPassUser = (): boolean => {
  const firstName = getQueryParam('firstname');
  const lastName = getQueryParam('lastname');
  return !!firstName && !!lastName;
};

/**
 * Guest authentication service
 */
@Injectable({
  providedIn: 'root',
})
export class GuestAuthService extends Loggable {
  private bearerToken?: string;
  private refreshBearer?: string;
  private user?: User;
  private authSubject = new Subject<User | undefined>();

  public auth$ = this.authSubject.asObservable();

  public constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private router: Router,
  ) {
    super();
    this.setDisplayName('GuestAuthService');
  }

  public init() {
    if (this.isGuestRoute) {
      this.authSubject.next(undefined);
      return;
    }

    const dataPass = isDataPassUser() ? getDataPassUser() : undefined;
    let guest;
    try {
      guest = this.localStorageService.retrieve(GUEST_USER_KEY, (guestJson) => JSON.parse(guestJson) as GuestUser);
    } catch (e) {
      this.error('Error retrieving guest user', e);
    }
    if (!guest) {
      this.authSubject.next(undefined);
      return;
    }

    // If the guest user has changed, clear the auth so the data pass user can be used.
    const { user = {} } = guest;
    if (
      dataPass &&
      (user.firstName !== dataPass.firstName ||
        user.lastName !== dataPass.lastName ||
        user.emailAddress !== dataPass.emailAddress)
    ) {
      this.authSubject.next(undefined);
      return;
    }
    this.handleGuestUser(guest);
  }

  public get authenticated(): boolean {
    return !!this.user;
  }

  public async getAuthToken(): Promise<string> {
    const refreshJwt = this.refreshBearer?.replace('Guest ', '');
    if (refreshJwt && isTokenExpired(refreshJwt)) {
      this.info('Refresh bearer is expired, user is not authenticated');
      return '';
    }

    const bearerJwt = this.bearerToken?.replace('Guest ', '') ?? '';
    if (isTokenExpired(bearerJwt)) {
      const guestUser = await this.refreshGuestUser();
      if (guestUser) {
        const { bearer, refreshBearer, user } = guestUser;
        this.bearerToken = bearer;
        this.refreshBearer = refreshBearer;
        this.user = user;
      }
    }

    return this.bearerToken ?? '';
  }

  public guestSignIn(_user?: User): Observable<User> {
    const newUser = _user ?? getDataPassUser();
    return from(guestController.createGuestUser(newUser)).pipe(
      tap((guest) => this.handleGuestUser(guest)),
      map(({ user }) => user),
      filter(notUndefined),
    );
  }

  public async registrationGuestSignIn(_user?: User) {
    const newUser = _user ?? getDataPassUser();
    await guestController
      .createGuestUser(newUser)
      .then((guest) => this.handleGuestUser(guest))
      .catch((err) => this.error('Error while creating guest user', err));
  }

  public reinitAuth(): void {
    this.clearAuth();
  }

  private get isGuestRoute(): boolean {
    return window?.location.pathname === '/web/guest';
  }

  /**
   * Get the user associated with a bearer token.
   *
   * XXX: This must be use instead of the RestController infrastructure. This guarantees which token is used for user
   * verification. The rest controller will use the first token provided by any authentication service.
   * @param token Bearer token
   * @returns An observable of the guest user or null
   */
  private getCurrentUser(token: string): Observable<User | null> {
    const AppVersion = RestController.version;
    const headers = new HttpHeaders({ authorization: token, AppVersion });
    return this.http.get<User | null>(GET_USER_IF_EXISTS, { headers });
  }

  /**
   * Refresh the authorization token.
   */
  private async refreshGuestUser(): Promise<GuestUser | null> {
    const AppVersion = RestController.version;
    if (!this.refreshBearer) {
      throw new Error('refreshToken: No bearer token available for refresh');
    }
    const headers = new HttpHeaders({ authorization: this.refreshBearer, AppVersion });
    return await firstValueFrom(this.http.get<GuestUser | null>('api/v1/guest/user', { headers }));
  }

  public handleGuestUser(guest: GuestUser): void {
    const { bearer, refreshBearer } = guest;
    this.refreshBearer = refreshBearer;
    if (!bearer) {
      this.authSubject.next(undefined);
      return;
    }
    this.getCurrentUser(bearer).subscribe({
      next: (user) => {
        if (!user) {
          this.clearAuth();
          return;
        }
        this.bearerToken = guest.bearer;
        this.user = user;
        this.authSubject.next(this.user);
        try {
          this.localStorageService.store(GUEST_USER_KEY, guest);
        } catch (e) {
          this.error('Error storing guest user', e);
        }
      },
      error: (e) => {
        this.warn('handleGuestUser: Error getting current user', e);
        this.clearAuth();
      },
    });
  }

  public async handleLeaveRoom(): Promise<void> {
    await this.router.navigate(['/web/guest']);
    try {
      this.localStorageService.clearItem(GUEST_USER_KEY);
    } catch (e) {
      this.error('Error clearing guest user', e);
    }
  }

  private clearAuth(): void {
    this.bearerToken = undefined;
    this.user = undefined;
    this.authSubject.next(undefined);
    try {
      this.localStorageService.clearItem(GUEST_USER_KEY);
    } catch (e) {
      this.error('Error clearing guest user', e);
    }
  }
}
