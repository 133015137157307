import ClientConfiguration from 'entity/ClientConfiguration';
import Company from 'entity/Company';
import User from 'entity/User';
import UserProfile from 'entity/UserProfile';
import LicenseType from 'enums/LicenseType';

import { LaunchDarklyFlagValues } from '../services/launch-darkly/launch-darkly.types';

export enum AuthenticationState {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED',
  INIT = 'INIT',
  PROCESSING = 'PROCESSING',
}

export interface CoreAppState {
  authenticationState: AuthenticationState;
  user?: User;
  company?: Company;
  license?: LicenseType;
  config?: ClientConfiguration;
  configErrorCount: number;
  flags?: LaunchDarklyFlagValues;
  isTestUser: boolean;
  isGuestUser: boolean;
  geoMusicAllowed: boolean;
  userProfile?: UserProfile;
}
