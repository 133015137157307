import { createAction, props } from '@ngrx/store';

import { SimpleMediaDeviceInfo } from './types';

export const MUTE_AUDIO = createAction('Social/media/MUTE_AUDIO');
export const UNMUTE_AUDIO = createAction('Social/media/UNMUTE_AUDIO');
export const MUTE_VIDEO = createAction('Social/media/MUTE_VIDEO');
export const UNMUTE_VIDEO = createAction('Social/media/UNMUTE_VIDEO');

export const AVAILABLE_CAMERAS_CHANGED = createAction(
  'Social/media/AVAILABLE_CAMERAS_CHANGED',
  props<{ cameras: SimpleMediaDeviceInfo[] }>(),
);
export const AVAILABLE_MICS_CHANGED = createAction(
  'Social/media/AVAILABLE_MICS_CHANGED',
  props<{ mics: SimpleMediaDeviceInfo[] }>(),
);
export const AVAILABLE_SPEAKERS_CHANGED = createAction(
  'Social/media/AVAILABLE_SPEAKERS_CHANGED',
  props<{ speakers: SimpleMediaDeviceInfo[] }>(),
);

export const CAMERA_SELECTED = createAction(
  'Social/media/CAMERA_SELECTED',
  props<{ camera: SimpleMediaDeviceInfo | null }>(),
);
export const MIC_SELECTED = createAction('Social/media/MIC_SELECTED', props<{ mic: SimpleMediaDeviceInfo | null }>());
export const SPEAKER_SELECTED = createAction(
  'Social/media/SPEAKER_SELECTED',
  props<{ speaker: SimpleMediaDeviceInfo | null }>(),
);

export const CAMERA_READABLE = createAction(
  'Social/media/CAMERA_READABLE',
  props<{ deviceId: string; readable: boolean }>(),
);
