export default class Util {
  public static toDate(date: string): Date | undefined {
    if (date) {
      return new Date(date);
    }
    return undefined;
  }

  public static toDates(array?: Date[] | string[]): void {
    if (array) {
      let i = 0;
      for (const date of array) {
        if (date) {
          array[i] = new Date(date);
        }
        ++i;
      }
    }
  }

  // returns human readable timezone
  public static toTimezone(date: Date): string {
    return date.toString().split(' ').slice(6, -1).join(' ').replace(/[()]/g, '') || '';
  }

  // returns UTC offset
  public static toUTC(date: Date): string {
    return date.toString().split(' ')[5] || '';
  }

  /**
   * Returns the brightness level of an hexadecimal color, the more closer to 255, the more
   * closer to be a white-tone color.
   * @param color hex color string without #
   * @returns The level of brightness from 0 to 255
   */
  public static getBrightnessLevel(color: string): number {
    const rgb = parseInt(color, 16);
    const red = (rgb >> 16) & 0xff;
    const green = (rgb >> 8) & 0xff;
    const blue = (rgb >> 0) & 0xff;

    return 0.2126 * red + 0.7152 * green + 0.0722 * blue;
  }

  /**
   *
   * @param hex hex color string without #
   * @returns whether a given string is a valid hex color.
   */
  public static isHexColor(hex: string): boolean {
    return typeof hex === 'string' && (hex.length === 6 || hex.length === 3) && !isNaN(Number('0x' + hex));
  }
}

/**
 * Compares two arrays, useful to find difference between two arrays.
 *
 * @param left The primary array to compare
 * @param right The array to compare with
 * @param compareFunction
 * @returns an array with the objects that are in the first array but not in the second one.
 */
export const onlyInLeft = <T>(left: T[], right: T[], compareFunction: (a: T, b: T) => boolean) =>
  left.filter((leftValue) => !right.some((rightValue) => compareFunction(leftValue, rightValue)));

export const enumToArray = <T extends object>(enumerable: T): string[] =>
  Object.keys(enumerable).filter((v) => isNaN(Number(v)));
