import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Inject, Injector, Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ModalComponent } from './modal-component/modal.component';

export const CONTAINER_DATA = new InjectionToken<Record<string, unknown>>('CONTAINER_DATA');

@Directive()
@Injectable()
export class ModalService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Inject(CONTAINER_DATA) public componentData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dialogRef?: any;

  public constructor(
    public dialog: MatDialog,
    private _injector: Injector,
  ) {}

  public createInjector(dataToPass: unknown): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public open(data: any, compRef: ComponentPortal<any>, options?: any): Observable<any> {
    this.dialogRef = this.dialog.open(ModalComponent, {
      data: {
        body: compRef,
        ...data,
      },
      ...options,
    });

    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      }),
    );
  }

  public close(): void {
    if (this.dialogRef) {
      return this.dialogRef.close();
    }
  }
}
