// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

export enum LicenseType {
    BASIC,
    TEAM,
    BUSINESS,
    PREMIUM,
    ENTERPRISE

}
export default LicenseType
