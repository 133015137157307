import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import ConfigurationController from 'rest/ConfigurationController';

import { FETCH_CONFIG_ERR, FETCH_CONFIG_RSP, FETCH_CONFIG_REQ } from './actions';

@Injectable()
export class CoreEffects {
  public constructor(private actions$: Actions) {}

  // Fetch the config from the server
  public fetchConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_CONFIG_REQ),
      mergeMap(() =>
        from(new ConfigurationController().getClientConfiguration()).pipe(
          map((config) => FETCH_CONFIG_RSP({ config })),
          catchError((error) => of(FETCH_CONFIG_ERR({ error }))),
        ),
      ),
    ),
  );
}
