import CTAItem from 'entity/CTAItem';
import GuestUserRoomSettings from 'entity/GuestUserRoomSettings';
import NavigationItem from 'entity/NavigationItem';
import Social from 'entity/Social';
import SocialArtifact from 'entity/SocialArtifact';
import SocialRoomSet from 'entity/SocialRoomSet';
import SocialSettings from 'entity/SocialSettings';
import StartingPoint from 'entity/StartingPoint';
import User from 'entity/User';
import {
  BaseUser,
  Presentation,
  Presenter,
  ScreenShare,
  SocialEventType,
  SocialState,
} from 'messages/websocket.messages';

import { StageSizeType } from '../../performer-participant/performer-participant.types';
import { GalleryTabs } from '../../presentation/presentation-drawer/gallery/constants';
import { BadgingState } from '../../services/badging/badging-state';
import { VisualIndicatorInfo } from '../../services/ring-colors/ring-colors.service';
import { SubscriptionReason, SubscriptionType } from '../../services/subscription/subscription.types';
import { PresenterVideoQuality } from '../../types/media';
import { SubscriptionState } from '../../types/webrtc';

export interface ParticipantMediaState {
  videoMuted: boolean;
  audioMuted: boolean;
  volume?: number;
}

export type ConnectedRoomCounts = Record<string, number> | undefined;
export type SubscriptionStatus = {
  id: string;
  name: string;
  peerTime: number;
  reason?: SubscriptionReason;
  state: SubscriptionState;
  type: SubscriptionType;

  audioNeeded: boolean;
  videoNeeded: boolean;
};
export type UserSubscriptionsState = Record<string, SubscriptionStatus>;
export type ParticipantsState = Record<string, ParticipantMediaState>;

export interface ExtendedPresenter extends Presenter {
  isLocal: boolean;
  videoMuted: boolean;
  audioMuted?: boolean;
}

export interface CrossRoomPresentationStatus {
  isPresenting: boolean;
  isCrossRoom: boolean;
  restrictedEvent: SocialEventType;
  presentation: Presentation | undefined;
}

// #region VideoEffect
export enum VideoEffectsMode {
  NONE = 'none',
  BLUR = 'blur',
  BACKGROUND_IMAGE = 'background_image',
  BACKGROUND_COLOR = 'background_color',
}

export interface VideoEffectBase {
  enabled: boolean;
  mode: VideoEffectsMode;
}

export interface NoVideoEffect extends VideoEffectBase {
  mode: VideoEffectsMode.NONE;
}

export interface VideoEffectBlur extends VideoEffectBase {
  mode: VideoEffectsMode.BLUR;
  blurRadius: number;
}

export interface BackgroundImageEffect extends VideoEffectBase {
  mode: VideoEffectsMode.BACKGROUND_IMAGE;
  image: string;
}

export interface BackgroundColorVideoEffect extends VideoEffectBase {
  mode: VideoEffectsMode.BACKGROUND_COLOR;
  color: string;
}
export type VideoEffect = NoVideoEffect | VideoEffectBlur | BackgroundImageEffect | BackgroundColorVideoEffect;

// #endregion VideoEffect
export interface SocialAppState {
  artifacts: SocialArtifact[];
  audience?: User[];
  gallery?: User[] | BaseUser[];
  connectedRoomCounts?: ConnectedRoomCounts;
  connectedRoomSetEntity?: SocialRoomSet;
  fullClusters: number[];
  participants: ParticipantsState;
  region?: string;
  restrictedEventType?: SocialEventType;
  roomSettings?: SocialSettings;
  shared?: SocialState;
  socialEntity: Social | null;
  subscriptions?: UserSubscriptionsState;
  publishConnectionState?: RTCPeerConnectionState;
  mixVolume: number;
  joined: boolean;
  videoEffect: VideoEffect;
  customerAPIEnabled?: boolean;
  guestRoomSettings?: GuestUserRoomSettings;
  ctaItems: CTAItem[];
  navItems: NavigationItem[];
  chatEnabled: boolean;
  badgeOpen: BadgingState;
  presenterQualityOverride?: PresenterVideoQuality;
  presentationStageSize?: StageSizeType;
  groupId?: number;
  startingPoint: StartingPoint;
  searchParticipantsOpen: boolean;
  shouldOpenMap: FindParticipantState;
  isPopOut: PopOutState;
  isBadgingModalOpen: boolean;
  visualIndicatorInfo?: VisualIndicatorInfo;
  userIsSpeaking: boolean;
  activeSpeakers?: string[];
  isWideRecording?: boolean;
  galleryTabs: GalleryTabs[];
}

// #region Group screen share

export interface GroupScreenShare {
  [userId: string]: ScreenShare;
}

export interface FindParticipantState {
  userId: string;
  open: boolean;
}

export type PopOutMode = 'PRESENTER' | 'SCREENSHARE' | 'NONE';

export interface PopOutState {
  mode: PopOutMode;
  open: boolean;
}

// #endregion Group screen share
