import { RestRequest } from './RestController';

export interface PagingData {
  page: number;
  size: number;
  sort?: string[];
}

export default class Paging {
  page: number;
  size: number;
  sort?: string[];

  constructor(page: number, size: number, sort?: string[]) {
    this.page = page;
    this.size = size;
    this.sort = sort;
  }

  addPaging(qualifier: string, request: RestRequest<unknown>): void {
    request.add(qualifier + 'page', '' + this.page);
    request.add(qualifier + 'size', '' + this.size);
    this.sort?.filter((s) => !!s).forEach((s) => request.add(qualifier + 'sort', s));
  }
}
