// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import LicenseType from "enums/LicenseType";
import User, { processUser } from "./User";

export default interface UserExt extends User {
    license?: LicenseType;
}

export function processUserExt(value?: UserExt): void {
  if (value) {
    processUser(value);
  }
}
