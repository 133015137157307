<h2 mat-dialog-title tabindex="0" class="title">{{ data.title }}</h2>
<div mat-dialog-content>
  <p tabindex="0">{{ data.text }}</p>
</div>
<div mat-dialog-actions style="justify-content: end">
  <button mat-button (click)="onCancel()" data-testid="cancel-button">Cancel</button>
  @if (data.customFillButton) {
    <button
      mat-button
      (click)="onOK()"
      data-testid="confirm-button"
      class="confirmation-button"
      [ngStyle]="{ color: data.actionButtonColor, 'background-color': data.actionButtonFillColor }"
    >
      {{ data.customConfirmationButton || 'OK' }}
    </button>
  } @else {
    <button mat-button (click)="onOK()" data-testid="confirm-button" [ngStyle]="{ color: data.actionButtonColor }">
      {{ data.customConfirmationButton || 'OK' }}
    </button>
  }
</div>
