import { Pipe, PipeTransform } from '@angular/core';

import { getResizeUri } from 'app/utils/image';

@Pipe({ name: 'imageSize' })
export class ImageSizePipe implements PipeTransform {
  public transform(imageUri: string, width: number, height: number): string | undefined {
    if (!imageUri) {
      return;
    }
    return getResizeUri({ uri: imageUri, width, height });
  }
}
