import { HttpClient, HttpHeaders } from '@angular/common/http';

import assertNever from 'app/utils/assert-never';

import Paging from './Paging';
import { firstValueFrom } from 'rxjs';
type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type QueryParams = Record<string, string | number | boolean | Date >;

export class RestRequest<T> {
  static HOST_PREFIX: string = (typeof process == 'object' && process.env.PRECIATE_HOST_PREFIX) || '';
  path: string;
  method: RequestMethod;
  body: T;
  separator = '?';

  constructor(path: string, method: RequestMethod, postData: T, queryItems: QueryParams = {}) {
    this.path = RestRequest.HOST_PREFIX + path;
    this.method = method;
    this.body = postData;
    for (const name in queryItems) {
      const value = queryItems[name];
      if (value !== undefined && value !== null) {
        this.path += this.separator + name + '=' + value;
        this.separator = '&';
      }
    }
  }

  addPaging(qualifier: string, paging: Paging): void {
    paging.addPaging(qualifier, this);
  }

  add(name: string, value: string): void {
    this.path += this.separator + name + '=' + value;
    this.separator = '&';
  }
}

type ProcessCallback<T> = (data: T) => void;

interface RequestHeaders {
  [key: string]: string | string[];
}
export class RestController {
  static idToken: string;
  static http: HttpClient;
  static version = 'web-1.0';
  private static getAuthToken: () => Promise<string> = () => new Promise(resolve => resolve(''));

  static setAuthConfiguration(http: HttpClient, callback: () => Promise<string>): void {
    this.http = http;
    this.getAuthToken = callback;
  }


  protected async request<T, U>(request: RestRequest<U>, convert: ProcessCallback<T> | null): Promise<T> {
    const token = await RestController.getAuthToken();
    const headers: RequestHeaders = { AppVersion: RestController.version };
    if (token) {
      headers.Authorization = token;
    }
    const config = { headers: new HttpHeaders(headers) };
    const { body, method, path } = request;
    let response: T;
    switch (method) {
      case 'GET': {
        response = await firstValueFrom(RestController.http.get(path, config)) as T;
        break;
      }
      case 'POST': {
        response = await firstValueFrom(RestController.http.post(path, body, config)) as T;
        break;
      }
      case 'PATCH': {
        response = await firstValueFrom(RestController.http.patch(path, body, config)) as T;
        break;
      }
      case 'PUT': {
        response = await firstValueFrom(RestController.http.put(path, body, config)) as T;

        break;
      }
      case 'DELETE': {
        response = await firstValueFrom(RestController.http.delete(path, config)) as T;

        break;
      }
      default:
        assertNever(method);
    }
    convert?.(response);
    return response;
  }

  protected async requestVoid<U>(request: RestRequest<U>): Promise<void> {
    const token = await RestController.getAuthToken();
    const headers: RequestHeaders = { AppVersion: RestController.version };
    if (token) {
      headers.Authorization = token;
    }
    const config = { headers: new HttpHeaders(headers) };
    const { body, method, path } = request;
    switch (method) {
      case 'GET': {
        await RestController.http.get(path, config).toPromise();
        break;
      }
      case 'POST': {
        await RestController.http.post(path, body, config).toPromise();
        break;
      }
      case 'PATCH': {
        await RestController.http.patch(path, body, config).toPromise();
        break;
      }
      case 'PUT': {
        await RestController.http.put(path, body, config).toPromise();
        break;
      }
      case 'DELETE': {
        await RestController.http.delete(path, config).toPromise();
        break;
      }
      default:
        assertNever(method);
    }
  }
}
