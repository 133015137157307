import { ErrorHandler, Injectable, NgZone, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, ReplaySubject } from 'rxjs';

import { ModalConfirmComponent, ModalConfirmData } from 'app/shared/modal-confirm/modal-confirm.component';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {
  private hasOpened = false;
  private destroyed$ = new ReplaySubject<boolean>(1);

  public constructor(
    private ngZone: NgZone,
    private dialog: MatDialog,
  ) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      this.ngZone.run(() => {
        this.openReloadModal();
      });
    }
    // eslint-disable-next-line no-console
    console.error(error);
  }

  private openReloadModal(): void {
    if (this.hasOpened) {
      return;
    }
    const dialogRef = this.dialog.open<ModalConfirmComponent, ModalConfirmData>(ModalConfirmComponent, {
      data: {
        title: `New Version`,
        text: `Please reload the page to get the latest version of the application`,
        customConfirmationButton: 'Reload',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(filter((shouldReload) => shouldReload))
      .subscribe(() => {
        window.location.reload();
      });
    this.hasOpened = true;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
