<div class="header">
  <div class="navigation-container">
    <span>
      <a href="https://scoot.app" class="logo-wrapper">
        <img src="assets/images/logos/bubble-cream-3-color.svg" alt="Bubble Cream 3 Color Logo" />
      </a>
    </span>

    <nav-items *ngIf="isUserAuthed" [navItems]="navItems"></nav-items>
  </div>
  <div class="profile-container">
    @if (!isUserAuthed || isGuest) {
      <button data-testid="login-button" class="login-button" mat-button (click)="navigateToWelcome()">
        Login/Sign Up
      </button>
    } @else {
      <span class="profile-dropdown items-center" [matMenuTriggerFor]="profileDropdown">
        <profile-picture class="profile-picture" [user]="user" [size]="32"></profile-picture>
        <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
      </span>
      <mat-menu role="menu" #profileDropdown="matMenu" xPosition="before" class="saved-rooms-options-menu">
        <button mat-menu-item role="menuitem" routerLink="/web/settings" data-testid="user-settings">
          My Settings
        </button>
        <button mat-menu-item role="menuitem" (click)="navigateToPricing()" data-testid="pricing">Pricing</button>
        <button mat-menu-item role="menuitem" (click)="signOut()" data-testid="sign-out">Sign Out</button>
      </mat-menu>
    }
  </div>
</div>
