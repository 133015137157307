import { createAction, props } from '@ngrx/store';

import AlternateHost from 'entity/AlternateHost';
import Social from 'entity/Social';
import SocialSettings from 'entity/SocialSettings';
import Paging, { PagingData } from 'rest/Paging';

export const INIT = createAction('Social/HomePage/INIT');

export const FETCH_SAVED_ROOMS_REQ = createAction('Social/HomePage/FETCH_SAVED_ROOMS_REQ', props<PagingData>());
export const FETCH_SAVED_ROOMS_RSP = createAction(
  'Social/HomePage/FETCH_SAVED_ROOMS_RSP',
  props<{ rooms: Social[] }>(),
);
export const FETCH_SAVED_ROOMS_ERR = createAction('Social/HomePage/FETCH_SAVED_ROOMS_ERR', props<{ error: unknown }>());

export const EDIT_ROOM_REQ = createAction('Social/HomePage/EDIT_ROOM_REQ', props<{ room: Social; open?: boolean }>());
export const EDIT_ROOM_RSP = createAction('Social/HomePage/EDIT_ROOM_RSP', props<{ room: Social }>());
export const EDIT_ROOM_ERR = createAction('Social/HomePage/EDIT_ROOM_ERR', props<{ error: unknown }>());

export const DELETE_DRAFT_ROOM_REQ = createAction('Social/HomePage/DELETE_DRAFT_ROOM_REQ', props<{ room: Social }>());
export const DELETE_DRAFT_ROOM_RSP = createAction(
  'Social/HomePage/DELETE_DRAFT_ROOM_RSP',
  props<{ roomId: string | undefined }>(),
);
export const DELETE_DRAFT_ROOM_ERR = createAction('Social/HomePage/DELETE_DRAFT_ROOM_ERR', props<{ error: unknown }>());

export const DELETE_ROOM_REQ = createAction('Social/HomePage/DELETE_ROOM_REQ', props<{ room: Social }>());
export const DELETE_ROOM_RSP = createAction('Social/HomePage/DELETE_ROOM_RSP', props<{ roomId: string | undefined }>());
export const DELETE_ROOM_ERR = createAction('Social/HomePage/DELETE_ROOM_ERR', props<{ error: unknown }>());

export const CREATE_ROOM_REQ = createAction('Social/HomePage/CREATE_ROOM_REQ', props<{ room: Social }>());
export const CREATE_ROOM_RSP = createAction('Social/HomePage/CREATE_ROOM_RSP', props<{ room: Social }>());
export const CREATE_ROOM_ERR = createAction('Social/HomePage/CREATE_ROOM_ERR', props<{ error: unknown }>());

export const CLEAR_DELETE_RSP = createAction('Social/HomePage/CLEAR_DELETE_RSP');

export const SAVE_SOCIAL_SETTINGS_REQ = createAction(
  'Social/HomePage/SAVE_SOCIAL_SETTINGS_REQ',
  props<{ settings: SocialSettings; roomId: string }>(),
);
export const SAVE_SOCIAL_SETTINGS_RSP = createAction('Social/HomePage/SAVE_SOCIAL_SETTINGS_RSP');
export const SAVE_SOCIAL_SETTINGS_ERR = createAction(
  'Social/HomePage/SAVE_SOCIAL_SETTINGS_ERR',
  props<{ error: unknown }>(),
);

export const SAVE_ROOMSET_SETTINGS_REQ = createAction(
  'Social/HomePage/SAVE_ROOMSET_SETTINGS_REQ',
  props<{ settings: SocialSettings; roomSetId: string }>(),
);

export const RESET_SOCIAL_PAGE = createAction('Social/HomePage/RESET_SOCIAL_PAGE');

export const SET_LOADING = createAction('Social/HomePage/SET_LOADING', props<{ isLoading: boolean }>());

export const GEO_MUSIC_RSP = createAction('Social/HomePage/GEO_MUSIC_RSP', props<{ allowed: boolean }>());

export const FETCH_ALTERNATE_HOSTS_CANDIDATES_REQ = createAction(
  'Social/HomePage/FETCH_ALTERNATE_HOSTS_CANDIDATES_REQ',
  props<{ socialId: string; paging: Paging; text: string }>(),
);

export const FETCH_ALTERNATE_HOSTS_CANDIDATES_ROOMSET_REQ = createAction(
  'Social/HomePage/FETCH_ALTERNATE_HOSTS_CANDIDATES_ROOMSET_REQ',
  props<{ socialRoomSetId: string; paging: Paging; text: string }>(),
);
export const FETCH_ALTERNATE_HOSTS_CANDIDATES_RSP = createAction(
  'Social/HomePage/FETCH_ALTERNATE_HOSTS_CANDIDATES_RSP',
  props<{ alternateHostCandidates: AlternateHost[] }>(),
);
export const FETCH_ALTERNATE_HOSTS_CANDIDATES_ERR = createAction(
  'Social/HomePage/FETCH_ALTERNATE_HOSTS_CANDIDATES_ERR',
  props<{ error: unknown }>(),
);
export const RESET_ALTERNATE_HOSTS_CANDIDATES = createAction('Social/HomePage/RESET_ALTERNATE_HOSTS_CANDIDATES');

export const UPDATE__ALTERNATE_HOSTS_CANDIDATES_REQ = createAction(
  'Social/HomePage/UPDATE_ALTERNATE_HOSTS_CANDIDATES_REQ',
  props<{ socialId: string; alternateHosts: AlternateHost[] }>(),
);

export const UPDATE__ALTERNATE_HOSTS_CANDIDATES_ROOMSET_REQ = createAction(
  'Social/HomePage/UPDATE__ALTERNATE_HOSTS_CANDIDATES_ROOMSET_REQ',
  props<{ socialRoomSetId: string; alternateHosts: AlternateHost[] }>(),
);
export const UPDATE__ALTERNATE_HOSTS_CANDIDATES_RSP = createAction(
  'Social/HomePage/UPDATE_ALTERNATE_HOSTS_CANDIDATES_RSP',
);
export const UPDATE__ALTERNATE_HOSTS_CANDIDATES_ERR = createAction(
  'Social/HomePage/UPDATE_ALTERNATE_HOSTS_CANDIDATES_ERR',
  props<{ error: unknown }>(),
);
