// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import User, { processUser } from "./User";

export default interface GuestUser {
    user?: User;
    bearer?: string;
    refreshBearer?: string;
}

export function processGuestUser(value?: GuestUser): void {
  if (value) {
    processUser(value.user);
  }
}
