// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import EmailAddress from "entity/EmailAddress";
import Paging from "./Paging";
import RoleType from "enums/RoleType";
import TimeZoneLocale from "entity/TimeZoneLocale";
import User, { processUser, processUserArray } from "entity/User";
import UserExt, { processUserExt } from "entity/UserExt";
import UserRole from "entity/UserRole";

import { QueryParams, RestController, RestRequest } from "./RestController";

export default class UserController extends RestController {

    /*
     Add an email address for the user. If email address exists and has not been verified, a new verification email is sent, otherwise if it was already verified a Duplicate email exception is thrown.
     */

    addEmail(emailAddress: string): Promise<void> {
        let request = new RestRequest("/api/v1/user/email/" + emailAddress + "/", "POST", null, undefined);
        return this.requestVoid(request);
    }

    /*
     Add guest email.
     */

    addGuestEmail(emailAddress: string): Promise<void> {
        let request = new RestRequest("/api/v1/user/guest/email/" + emailAddress + "/", "POST", null, undefined);
        return this.requestVoid(request);
    }

    /*
     Add new unregistered user into system so that it can be referenced.
     */

    addUnregisteredUser(user: User): Promise<User> {
        let request = new RestRequest("/api/v1/user/add/unregistered", "POST", user, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    /*
     Add role for a user.
     */

    addUserRole(userId: string, role: RoleType): Promise<UserRole> {
        let request = new RestRequest("/api/v1/user/" + userId + "/role/" + role + "", "POST", null, undefined);
        return this.request(request, null) as Promise<UserRole>;
    }

    adminConsolidateEmails(): Promise<string> {
        let request = new RestRequest("/api/v1/user/admin/consolidate/emails", "POST", null, undefined);
        return this.request(request, null) as Promise<string>;
    }

    adminMergeRegisteredUsers(fromUserId: string, toUserId: string): Promise<string> {
        let request = new RestRequest("/api/v1/user/admin/merge/registered/users/" + fromUserId + "/" + toUserId + "", "POST", null, undefined);
        return this.request(request, null) as Promise<string>;
    }

    adminMergeUsers(fromUserId: string, toUserId: string): Promise<string> {
        let request = new RestRequest("/api/v1/user/admin/merge/users/" + fromUserId + "/" + toUserId + "", "POST", null, undefined);
        return this.request(request, null) as Promise<string>;
    }

    adminSendVerifyEmail(userId: string): Promise<string> {
        let request = new RestRequest("/api/v1/user/admin/send/verify/email/" + userId + "", "POST", null, undefined);
        return this.request(request, null) as Promise<string>;
    }

    apiKeyDeleteUser(userId: string): Promise<void> {
        let request = new RestRequest("/api/v1/user/api/key", "DELETE", null, { userId: userId });
        return this.requestVoid(request);
    }

    apiKeyDeleteUserDataNameCheck(userId: string, name: string): Promise<Object[]> {
        let request = new RestRequest("/api/v1/user/api/key/data/" + userId + "/check/" + name + "", "DELETE", null, undefined);
        return this.request(request, null) as Promise<Object[]>;
    }

    apiKeyMergeRegisteredUsers(fromUserId: string, toUserId: string): Promise<string> {
        let request = new RestRequest("/api/v1/user/api/key/merge/registered/users/" + fromUserId + "/" + toUserId + "", "POST", null, undefined);
        return this.request(request, null) as Promise<string>;
    }

    apiKeyMergeUsers(fromUserId: string, toUserId: string): Promise<string> {
        let request = new RestRequest("/api/v1/user/api/key/merge/users/" + fromUserId + "/" + toUserId + "", "POST", null, undefined);
        return this.request(request, null) as Promise<string>;
    }

    checkPrivilege(role: RoleType): Promise<boolean> {
        let request = new RestRequest("/api/v1/user/check/role/" + role + "", "GET", null, undefined);
        return this.request(request, null) as Promise<boolean>;
    }

    createFirebaseUser(msToken?: string): Promise<User> {
        const queryParams: QueryParams = { };
        if (msToken !== undefined) {
          queryParams.msToken = msToken;
        }
        let request = new RestRequest("/api/v1/user/firebase", "POST", null, queryParams);
        return this.request(request, processUser) as Promise<User>;
    }

    createUser(): Promise<User> {
        let request = new RestRequest("/api/v1/user", "POST", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    deleteUser(): Promise<void> {
        let request = new RestRequest("/api/v1/user", "DELETE", null, undefined);
        return this.requestVoid(request);
    }

    deleteUserData(userId: string): Promise<Object[]> {
        let request = new RestRequest("/api/v1/user/data/" + userId + "", "DELETE", null, undefined);
        return this.request(request, null) as Promise<Object[]>;
    }

    deleteUserDataNameCheck(userId: string, name: string): Promise<Object[]> {
        let request = new RestRequest("/api/v1/user/data/" + userId + "/check/" + name + "", "DELETE", null, undefined);
        return this.request(request, null) as Promise<Object[]>;
    }

    findOrgUsers(text?: string, page?: Paging): Promise<User[]> {
        const queryParams: QueryParams = { };
        if (text !== undefined) {
          queryParams.text = text;
        }
        let request = new RestRequest("/api/v1/user/organization/find", "GET", null, queryParams);
        if (page) request.addPaging("", page);

        return this.request(request, processUserArray) as Promise<User[]>;
    }

    findUsers(text: string, page?: Paging): Promise<User[]> {
        let request = new RestRequest("/api/v1/user/find", "GET", null, { text: text });
        if (page) request.addPaging("", page);

        return this.request(request, processUserArray) as Promise<User[]>;
    }

    /*
     Get all emails for user
     */

    getAllEmails(): Promise<EmailAddress[]> {
        let request = new RestRequest("/api/v1/user/email/all", "GET", null, undefined);
        return this.request(request, null) as Promise<EmailAddress[]>;
    }

    /*
     Gets User that is currently signed in.
     */

    getCurrentUser(): Promise<User> {
        let request = new RestRequest("/api/v1/user/current", "GET", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    /*
     Gets User (extended model) that is currently signed in.
     */

    getCurrentUserExt(): Promise<UserExt> {
        let request = new RestRequest("/api/v1/user/current/ext", "GET", null, undefined);
        return this.request(request, processUserExt) as Promise<UserExt>;
    }

    /*
     Gets user if one exists
     */

    getCurrentUserIfExists(): Promise<User> {
        let request = new RestRequest("/api/v1/user/if/exists", "GET", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    /*
     Get roles for the user. Returns global roles and roles for the current company.
     */

    getRoles(page?: Paging): Promise<UserRole[]> {
        let request = new RestRequest("/api/v1/user/role", "GET", null, undefined);
        if (page) request.addPaging("", page);

        return this.request(request, null) as Promise<UserRole[]>;
    }

    /*
     Gets an User by id.
     */

    getUser(userId: string): Promise<User> {
        let request = new RestRequest("/api/v1/user/" + userId + "", "GET", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    /*
     Returns User with given email address.
     */

    getUserByEmail(emailAddress: string): Promise<User> {
        let request = new RestRequest("/api/v1/user/email/" + emailAddress + "/", "GET", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    /*
     Returns User with given mobile phone number.
     */

    getUserByMobilePhone(mobilePhone: string): Promise<User> {
        let request = new RestRequest("/api/v1/user/phone/" + mobilePhone + "", "GET", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    getUserData(userId: string): Promise<Object[]> {
        let request = new RestRequest("/api/v1/user/data/" + userId + "", "GET", null, undefined);
        return this.request(request, null) as Promise<Object[]>;
    }

    getUsers(page?: Paging): Promise<User[]> {
        let request = new RestRequest("/api/v1/user", "GET", null, undefined);
        if (page) request.addPaging("", page);

        return this.request(request, processUserArray) as Promise<User[]>;
    }

    removeActAsUser(): Promise<User> {
        let request = new RestRequest("/api/v1/user/remove/act/as", "POST", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    /*
     Remove the email address from the user profile.
     */

    removeEmail(emailAddress: string): Promise<void> {
        let request = new RestRequest("/api/v1/user/email/" + emailAddress + "/", "DELETE", null, undefined);
        return this.requestVoid(request);
    }

    /*
     Remove role from a user.
     */

    removeUserRole(userId: string, role: RoleType): Promise<UserRole> {
        let request = new RestRequest("/api/v1/user/" + userId + "/role/" + role + "", "DELETE", null, undefined);
        return this.request(request, null) as Promise<UserRole>;
    }

    sendVerifyEmail(emailAddress: string): Promise<void> {
        let request = new RestRequest("/api/v1/user/send/verify/email", "POST", emailAddress, undefined);
        return this.requestVoid(request);
    }

    setActAsUser(userId: string): Promise<User> {
        let request = new RestRequest("/api/v1/user/set/act/as/" + userId + "", "POST", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    /*
     Set email address as a primary email address.
     */

    setPrimaryEmail(emailAddress: string): Promise<void> {
        let request = new RestRequest("/api/v1/user/email/" + emailAddress + "/primary", "POST", null, undefined);
        return this.requestVoid(request);
    }

    setTimeZone(timeZone: string): Promise<void> {
        let request = new RestRequest("/api/v1/user/timezone/" + timeZone + "", "POST", null, undefined);
        return this.requestVoid(request);
    }

    setTimeZoneAndLocale(tzl: TimeZoneLocale): Promise<void> {
        let request = new RestRequest("/api/v1/user/timezone/and/locale", "POST", tzl, undefined);
        return this.requestVoid(request);
    }

    /*
     Sign out User
     */

    signOut(): Promise<void> {
        let request = new RestRequest("/api/v1/user/sign/out", "POST", null, undefined);
        return this.requestVoid(request);
    }

    updateMobilePhone(mobilePhone: string, oldToken: string): Promise<User> {
        let request = new RestRequest("/api/v1/user/mobile/phone/" + oldToken + "/" + mobilePhone + "", "PUT", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    updateUser(user: User): Promise<User> {
        let request = new RestRequest("/api/v1/user", "PUT", user, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    validateAndUpdateUser(verificationCode: string): Promise<void> {
        let request = new RestRequest("/api/v1/user/validate/" + verificationCode + "", "POST", null, undefined);
        return this.requestVoid(request);
    }

    verifyEmail(verificationCode: string): Promise<void> {
        let request = new RestRequest("/api/v1/user/verify/email/" + verificationCode + "", "POST", null, undefined);
        return this.requestVoid(request);
    }

}
