import { Subject, Observable, ReplaySubject } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

export const notNull = <T>(value: T | null): value is T => value !== null;
export const isNull = <T>(value: T | null): value is T => !notNull(value);

export const notUndefined = <T>(value: T | undefined): value is T => value !== undefined;
export const isUndefined = <T>(value: T | null): value is T => !notUndefined(value);

export const notNil = <T>(value: T | null | undefined): value is T => notNull(value) && notUndefined(value);
export const isNil = <T>(value: T | null | undefined): value is T => isNull(value) || isUndefined(value);

/**
 * Provide a resettable observable.
 * @param factory A factory function that will construct the replay subject.
 *
 *  This preserves subscriptions while allowing a replay subject to have its state reset.
 * @returns Object containing the observable, a reset function, and the resettable subject.
 */
export function resettableSubject<T>(factory: () => ReplaySubject<T>): ResettableSubject<T> {
  const resetter = new Subject<unknown>();
  const source = new Subject<T>();
  let destination = factory();
  let subscription = source.subscribe(destination);
  return {
    observable: resetter.asObservable().pipe(
      startWith<unknown, unknown[]>(null),
      switchMap(() => destination),
    ),
    reset: () => {
      subscription.unsubscribe();
      destination = factory();
      subscription = source.subscribe(destination);
      resetter.next(null);
    },
    subject: source,
  };
}

export interface ResettableSubject<T> {
  observable: Observable<T>;
  reset(): void;
  subject: Subject<T>;
}
