import { InteractionMode, PresentationSettings } from 'messages/websocket.messages';

/**
 * Size of the room before subscription load becomes a concern and the user experience
 * updates to provide some guard rails
 */
export const LARGE_ROOM_SIZE = 25;

// Maximum number of active presenters
export const MAX_PRESENTERS = 4;
export class InteractionModePresets {
  public static readonly SPEECH_MODE: PresentationSettings = {
    defaultFullScreen: true,
    interactionMode: InteractionMode.MANY_PUSH_TO_SPEAK,
    allowGroupConversations: false,
    requireFullScreen: false,
  };

  public static readonly GROUP_ACTIVITY: PresentationSettings = {
    defaultFullScreen: false,
    interactionMode: InteractionMode.SINGLE_PUSH_TO_SPEAK,
    allowGroupConversations: true,
    requireFullScreen: false,
  };

  public static readonly ALL_HANDS: PresentationSettings = {
    defaultFullScreen: false,
    interactionMode: InteractionMode.MANY_PUSH_TO_SPEAK,
    allowGroupConversations: true,
    requireFullScreen: false,
  };

  public static readonly CLASSROOM: PresentationSettings = {
    defaultFullScreen: true,
    interactionMode: InteractionMode.QUEUED_Q_AND_A,
    allowGroupConversations: false,
    requireFullScreen: false,
  };

  public static readonly NO_AUDIENCE_PARTICIPATION: PresentationSettings = {
    defaultFullScreen: true,
    interactionMode: InteractionMode.NONE,
    allowGroupConversations: false,
    requireFullScreen: false,
  };

  public static readonly OPEN_AUDIENCE_PARTICIPATION: PresentationSettings = {
    defaultFullScreen: false,
    interactionMode: InteractionMode.OPEN,
    allowGroupConversations: true,
    requireFullScreen: false,
  };

  public static getDefaultPreset(numParticipants: number): PresentationSettings {
    return numParticipants > LARGE_ROOM_SIZE ? this.DEFAULT_LARGE_ROOM_PRESET : this.DEFAULT_SMALL_ROOM_PRESET;
  }

  /** Preset corresponding to numParticipants < LARGE_ROOM_SIZE */
  public static readonly DEFAULT_SMALL_ROOM_PRESET = InteractionModePresets.OPEN_AUDIENCE_PARTICIPATION;
  /** Preset corresponding to numParticipants > LARGE_ROOM_SIZE */
  public static readonly DEFAULT_LARGE_ROOM_PRESET = InteractionModePresets.OPEN_AUDIENCE_PARTICIPATION;
}
