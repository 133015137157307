import { createSelector } from '@ngrx/store';

import { AppState } from 'app/types';
import Social from 'entity/Social';
import SocialRoomSet from 'entity/SocialRoomSet';

import { initialState } from './reducer';
import { ScootaversePageState } from './types';

type Scootaverses = ScootaversePageState['scootaverses'];
type Rooms = ScootaversePageState['rooms'];

export const getScootaversePageState = (state: AppState): ScootaversePageState =>
  state.social?.scootaversePage ?? initialState;

export const getScootaverses = createSelector(
  getScootaversePageState,
  (state: ScootaversePageState) => state.scootaverses,
);
export const scootaversesLoading = createSelector(getScootaverses, ({ loading }: Scootaverses) => loading);
export const getRoomSets = createSelector(getScootaverses, (roomSet: Scootaverses): SocialRoomSet[] => roomSet.data);
export const getSelectedScootaverseId = createSelector(
  getScootaversePageState,
  (state: ScootaversePageState) => state.scootaverseId,
);

export const getSocials = createSelector(getScootaversePageState, (state: ScootaversePageState) => state.rooms);
export const roomsLoading = createSelector(getSocials, ({ loading }: Scootaverses) => loading);
export const getRooms = createSelector(getSocials, (rooms: Rooms): Social[] => rooms.data);
