// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

export const TOAST = "TOAST";
export const CONVO = "CONVO";
export const GIVE_RECOGNITION = "GIVE_RECOGNITION";
export const PRESENT = "PRESENT";
// "AUTO", "STANDARD", "720p", "1080p"
export const PRESENTER_VIDEO_QUALITY = "PRESENTER_VIDEO_QUALITY";
export const SHARE_LINK = "SHARE_LINK";
export const COMPANY_BANNER = "COMPANY_BANNER";
export const PHOTO_BOOTH = "PHOTO_BOOTH";
export const ANYONE_CAN_PRESENT = "ANYONE_CAN_PRESENT";
export const ANYONE_CAN_SHARE = "ANYONE_CAN_SHARE";
export const ANYONE_CAN_SHARE_TO_GROUP = "ANYONE_CAN_SHARE_TO_GROUP";
export const ANYONE_CAN_CHANGE_MUSIC = "ANYONE_CAN_CHANGE_MUSIC";
export const MAX_GROUP_SIZE = "MAX_GROUP_SIZE";
export const MOVEMENT_DISABLED = "MOVEMENT_DISABLED";
export const MODERATED_CHAT = "MODERATED_CHAT";
export const HOST_CHAT = "HOST_CHAT";
export const MINIMAP_MODE = "MINIMAP_MODE";
export const REGION = "REGION";

export default interface SocialSettings {
    settings?: Record<string, unknown>;
    setSettings?: Record<string, unknown>;
}
