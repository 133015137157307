import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DeepLinkNavService } from '../../modules/deep-link/deepLinkNav.service';
import { TermsService } from '../../services/terms.service';

@Component({
  selector: 'app-updated-terms-blocker',
  templateUrl: './updated-terms-blocker.component.html',
  styleUrls: ['./updated-terms-blocker.component.scss'],
})
export class UpdatedTermsBlockerComponent implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public constructor(
    private termsService: TermsService,
    private deepNavService: DeepLinkNavService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {}

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public async onAcceptButtonClicked(): Promise<void> {
    await this.termsService.acceptTerms();
    this.route.queryParams
      .pipe(
        filter((params) => params.return),
        takeUntil(this.destroyed$),
      )
      .subscribe(async (params) => {
        await this.deepNavService.navToDeepLink(params.return, this.router);
      });
  }
}
