// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import { RestController, RestRequest } from "./RestController";

export default class TermsController extends RestController {

    /*
     Persist that user has accepted latest terms
     */

    acceptTerms(): Promise<void> {
        let request = new RestRequest("/api/v1/terms/has/accepted", "POST", null, undefined);
        return this.requestVoid(request);
    }

    /*
     See if user has accepted latest terms
     */

    didAcceptTerms(): Promise<boolean> {
        let request = new RestRequest("/api/v1/terms/has/accepted", "GET", null, undefined);
        return this.request(request, null) as Promise<boolean>;
    }

}
