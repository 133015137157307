import { createAction, props } from '@ngrx/store';

import SocialRoomSet from 'entity/SocialRoomSet';
import { PagingData } from 'rest/Paging';

export const INIT = createAction('Scootaverses/INIT');

// Fetch Scootaverses
export const FETCH_SCOOTAVERSES_REQ = createAction('Scootaverses/FETCH_SCOOTAVERSES_REQ', props<PagingData>());
export const FETCH_SCOOTAVERSES_RSP = createAction(
  'Scootaverses/FETCH_SCOOTAVERSES_RSP',
  props<{ roomSets: SocialRoomSet[] }>(),
);
export const FETCH_SCOOTAVERSES_ERR = createAction('Scootaverses/FETCH_SCOOTAVERSES_ERR', props<{ error: unknown }>());
export const SET_SELECTED_SCOOTAVERSE = createAction(
  'Scootaverses/SET_SELECTED_SCOOTAVERSE',
  props<{ scootaverseId: string }>(),
);
