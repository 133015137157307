<div class="flex flex-column">
  <button
    type="button"
    mat-button
    (click)="resetLocalStorage()"
    class="m-b-12 bg-color-warning-red text-color-white font-s uppercase"
    *ngIf="showResetButton()"
  >
    Reset Local Storage
  </button>
  <div class="flex w-full justify-between items-center gap-12" *ngFor="let flag of flags | keyvalue">
    <p>{{ flag.key }}</p>
    @if (isBoolean(flag.value)) {
      <mat-slide-toggle
        [checked]="flag.value"
        (change)="onToggleChange(flag.key, $event)"
        hideIcon="true"
        class="custom-toggle"
      ></mat-slide-toggle>
    } @else if (isString(flag.value) || isNumber(flag.value)) {
      <mat-form-field appearance="fill">
        <mat-label>Value</mat-label>
        <input matInput [value]="getString(flag.value)" (blur)="onInputBlur(flag.key, $event)" />
      </mat-form-field>
    } @else {
      <p>Unsupported type</p>
    }
    @if (flag.key === 'pixi_inspector') {
      <div class="break"></div>
      <code>
        <pre>{{ pixiInspector | json }}</pre>
      </code>
    }
  </div>
</div>
