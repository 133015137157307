import { Injectable, inject } from '@angular/core';
import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  UrlTree,
  CanActivateFn,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, merge, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

import { AppState } from 'app/types';
import { Loggable } from 'app/utils/logging/loggable';

import { AuthService } from '../../services/auth.service';
import { TermsService } from '../../services/terms.service';
import { AuthenticationState, coreSelectors } from '../state';

/**
 * @deprecated - use canActivateTermsGuard
 *  Class-based Route guards are deprecated in favor of functional guards.
 *  An injectable class can be used as a functional guard using the
 *  inject function: canActivate: [() => inject(myGuard).canActivate()]
 */
@Injectable({
  providedIn: 'root',
})
export class TermsGuardService extends Loggable implements CanActivate {
  public constructor(
    public router: Router,
    public authService: AuthService,
    public termsService: TermsService,
    private store: Store<AppState>,
  ) {
    super();
    this.setDisplayName('TermsGuardService');
  }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const signedOut$ = this.store.select(coreSelectors.getAuthenticationState).pipe(
      filter((authState) => authState === AuthenticationState.UNAUTHENTICATED),
      tap(() => this.navigateToWelcome()),
      switchMap(() => of(false)),
    );

    const signedIn$ = this.store.select(coreSelectors.getAuthenticationState).pipe(
      filter((authState) => authState === AuthenticationState.AUTHENTICATED),
      switchMap(async (): Promise<boolean | UrlTree> => {
        if (!this.authService.isAnonymous()) {
          return true;
        }

        try {
          await firstValueFrom(this.authService.signOutUser());
        } catch (error) {
          this.error('Error signing out user', error);
        } finally {
          return this.navigateToWelcome();
        }
      }),
      catchError((error: unknown): Observable<boolean | UrlTree> => {
        this.error('Error in canActivate', error);
        return of(this.navigateToWelcome());
      }),
    );

    return merge(signedIn$, signedOut$);
  }

  public navigateToWelcome(): UrlTree {
    return this.router.createUrlTree(['welcome']);
  }
}

export const canActivateTermsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  return inject(TermsGuardService).canActivate(route, state);
};
