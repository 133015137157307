import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { delay, distinctUntilChanged, filter, pairwise, take, tap } from 'rxjs/operators';

import { AppState } from 'app/types';
import { notNil } from 'app/utils/stream-util';
import ClientConfiguration from 'entity/ClientConfiguration';

import { coreActions, coreSelectors } from '../state';

@Injectable({ providedIn: 'root' })
export class AwsService {
  public configErrors = 0;

  private config$ = new ReplaySubject<ClientConfiguration>(1);

  public constructor(store: Store<AppState>) {
    // Fetch the config if there is not one
    store
      .select(coreSelectors.getConfig)
      .pipe(distinctUntilChanged())
      .subscribe((config) => {
        if (!config) {
          store.dispatch(coreActions.FETCH_CONFIG_REQ());
        }
      });

    // Drive the observable with the config
    store
      .select(coreSelectors.getConfig)
      .pipe(filter(notNil), take(1))
      .subscribe((config) => {
        this.config$.next(config);
        this.config$.complete();
      });

    // On error, retry fetching the config every second
    store
      .select(coreSelectors.getConfigErrorCount)
      .pipe(
        pairwise(),
        filter(([oldCount, count]) => count > oldCount),
        delay(1000),
        tap(() => store.dispatch(coreActions.FETCH_CONFIG_REQ())),
      )
      .subscribe(([_, count]) => (this.configErrors = count));
  }

  /**
   * @deprecated Prefer using the store directly to fetch the config with coreSelectors.getConfig
   */
  public getAWSCognitoConfig(): Observable<ClientConfiguration> {
    return this.config$.asObservable();
  }
}
