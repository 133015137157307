import { BasicUser } from 'app/modules/social/services/subscription/subscription.service';
import assertDefined from 'app/utils/assert-defined';
import { safeString } from 'app/utils/string-util';
import User from 'entity/User';

import CONSTANTS from '../constants';

export class UserUtil {
  private user: User;

  public constructor(user: User) {
    this.user = user;
  }

  public static getProfileColor(user?: User): string {
    const { profileImageBackground = '0' } = user ?? {};
    const index = parseInt(profileImageBackground[0], 10) % CONSTANTS.PROFILE_COLORS.length;
    return CONSTANTS.PROFILE_COLORS[index];
  }
  public static getProfileColorInt(user?: User): number {
    return parseInt(UserUtil.getProfileColor(user).substring(1), 16);
  }

  public getColor(): string {
    return UserUtil.getProfileColor(this.user);
  }

  public static trimName(maxLength: number, name: string): string {
    if (name.length <= maxLength) {
      return name;
    }
    let newName = name.slice(0, maxLength);
    newName += '...';
    return newName;
  }
}

export const isTestUser = (user?: User): boolean => {
  const email = user?.emailAddress;
  const id = 'test-0001110';
  return email?.startsWith(id) ?? false;
};

/**
 * Format a user name as First name last initial and userId.
 *
 * This is useful for logging user info
 *
 * @param user The user to format
 */
export const prettyPlayerId = ({ firstName, lastName, userId }: User) => {
  const name = `${safeString(firstName)} ${safeString(lastName)[0] ?? ''}`.trim();

  return `${name} ${userId}`.trim();
};

/**
 * Get the user's initials
 * @param user The user to get initials for
 * @returns Initials as a string
 */
export const getUserInitials = (user: User): string => {
  const { firstName, lastName } = user;
  const firstInitial = firstName?.[0] ?? '';
  const lastInitial = lastName?.[0] ?? '';
  if (firstInitial === '' && lastInitial === '') {
    return '-';
  }
  return `${firstInitial}${lastInitial}`;
};

/**
 * Format a user's name
 * @param user The use to format
 * @returns User name as a string
 */
export const formatUserName = (user: Pick<User, 'firstName' | 'lastName'>): string => {
  return `${user.firstName || ''} ${user.lastName || ''}`;
};

export const getBasicUser = (u: User): BasicUser => {
  assertDefined(u.userId);
  return { userId: u.userId, firstName: u.firstName ?? '', lastName: u.lastName ?? '' };
};

export default UserUtil;
