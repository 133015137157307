<div class="item-container">
  @for (item of navItems; track $index) {
    <span class="nav-item" *ngIf="optionIsEnabled(item.option)">
      <button type="button" mat-button (click)="openMenu(trigger, $index, item)">
        {{ item.option }}
        <mat-icon class="arrow-icon" *ngIf="item.isMenu">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menuList="matMenu" class="custom-menu nav-items-menu">
        <button
          type="button"
          mat-menu-item
          *ngFor="let secondary of item.secondaryItems"
          (click)="navigateToRoute(secondary.route)"
        >
          {{ secondary.option }}
        </button>
      </mat-menu>
      <mat-menu-trigger #trigger="matMenuTrigger" [matMenuTriggerFor]="menuList"></mat-menu-trigger>
    </span>
  }
</div>
