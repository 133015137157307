import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { getResizeUri } from 'app/utils/image';
import User from 'entity/User';

import UserUtil from '../utilities/user-util';

@Component({
  selector: 'profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent implements OnInit, ICellRendererAngularComp {
  @Input() public user?: User;
  private _size?: number | undefined;
  public sizeProp100 = '100%';
  public sizeProp90 = '90%';
  public sizeProp50 = '50%';
  // Table Styling
  public initialsSizing?: string;

  @Input() public get size(): number | undefined {
    return this._size;
  }
  public set size(size: number | undefined) {
    this._size = size;
    this.sizeProp100 = this.getSizeWithMultiplier(1);
    this.sizeProp50 = this.getSizeWithMultiplier(0.5);
    this.sizeProp90 = this.getSizeWithMultiplier(0.9);
    this.initialsSizing = this.sizeProp50;
  }

  @Input() public borderColor?: string;
  @Input() public borderWidth = '3';
  @Input() public header = false;
  @Input() public addPicture = false;
  @Input() public routing = false;
  @Input() public responsive = false;

  public name = '';
  public avatarBackground?: string;
  public profileImageURI?: string;
  public isReady = false;
  public iframe = false;
  public imageHasError = false;

  public constructor(private router: Router) {}

  public ngOnInit(): void {
    this.setUpPicture();
    this.checkIframe();
  }

  // AG-GRID Table
  public agInit(params: ICellRendererParams): void {
    this._size = 42;
    this.user = params.data.user;
    this.initialsSizing = '18px';
  }

  public refresh(): boolean {
    return false;
  }

  private getSizeWithMultiplier(multiplier: number): string {
    const newSize = this.size ? `${+this.size * multiplier}px` : `${100 * multiplier}%`;
    return newSize;
  }

  //Used to update profile picture when navigating to new users on the portrait.
  public ngOnChanges(): void {
    this.setUpPicture();
  }

  public onImgError(_event: unknown): void {
    this.imageHasError = true;
  }

  private setUpPicture(): void {
    this.avatarBackground = UserUtil.getProfileColor(this.user);
    if (this.user) {
      this.profileImageURI = this.getScaledImageURI(this.user?.profileImageURI ?? '');
      this.isReady = true;
      this.name = this.user.name ?? '';
    }
  }

  private getScaledImageURI(uri: string): string {
    if (!this.size) {
      return uri;
    }
    return getResizeUri({ uri, width: this.size });
  }

  private checkIframe(): void {
    if (window.location !== window.parent.location) {
      this.iframe = true;
    }
  }

  public get profileBorder(): string {
    return this.borderColor ? `${this.borderWidth}px solid ${this.borderColor}` : `${this.borderWidth}px solid #FFFFFF`;
  }
}
