const PROFILE_COLORS: readonly string[] = ['#003c10', '#ffbd00', '#091d50', '#cc9ced', '#0c58c6'];

export const CONSTANTS = {
  BASE_URL: '/api/v1',
  PHONE_REGEX: /^(\([0-9]{3}\) ?|[0-9]{3}-?)[0-9]{3}-?[0-9]{4}$/,
  BRANCH_KEY: 'key_live_dhFYuKCPk9Ty2dGSolTbSlodABmUiM5F',
  BRANCH_KEY_DEV: 'key_live_mktZZBkhgX3BPInT1CMzMedlzvnYJTmm',
  CAPTCHA_SITE_KEY: '6LewDmEUAAAAAJD05rThI82Jg401pWXEMAv7S6Jt',
  PROFILE_COLORS,
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  ANY_COMPANY: '11111111-1111-1111-1111-111111111111',
  NO_USER_ID: '00000000-0000-0000-0000-000000000000',
  FIREBASE_ID_TOKEN: 'firebaseIdToken',
  FIREBASE_REFRESH_TOKEN: 'firebaseRefreshToken',
  FIREBASE_EXP: 'firebaseExp',
  IS_FIREBASE: 'isFirebase',
  // CHURN_ZERO_URL: 'https://preciate.us2app.churnzero.net/churnzero.js',
  // CHURN_DEV_ZERO_URL: 'https://preciate-dev.us2app.churnzero.net/churnzero.js',
};
export default CONSTANTS;

export const enum LSPersistenceKeys {
  VIDEO_EFFECT = 'preciateVideoEffect',
}

export interface Item {
  option: string;
  route: string;
}
export interface NavItem {
  option: string;
  route: string;
  isMenu: boolean;
  secondaryItems?: Item[];
}
