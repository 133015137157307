<div class="modal-wrapper">
  <div class="modal-content-area flex flex-column justify-center items-center">
    <span class="success font-s m-b-40 uppercase weight-bold letter-m">
      You do not yet have a Scoot account associated with this email.
    </span>
    <span class="font-l m-b-50">
      <strong>
        Do you want to create a new Scoot account using the following email?
        <br />{{ this.email }}
      </strong>
    </span>
    <span class="font-m m-b-40">
      Double check that this is the email you want to use. If you think you already have an account, go back to sign in
      and use a different email or phone number.
    </span>
    <mat-checkbox [(ngModel)]="termsAndConditionsAccepted" class="m-b-15">
      I agree to the
      <a rel="noopener" href="https://scoot.app/terms-of-use-agreement" target="_blank">Terms of Service</a>
    </mat-checkbox>
    <button
      mat-button
      class="button dark-gray m-b-10"
      (click)="shouldCreateAccount(true)"
      [disabled]="!termsAndConditionsAccepted"
    >
      Yes, Create an Account
    </button>
    <button mat-button class="button" (click)="shouldCreateAccount(false)">No, Go Back</button>
  </div>
</div>
