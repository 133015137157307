// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import Image, { processImage, processImageArray } from "entity/Image";
import Paging from "./Paging";
import User, { processUser } from "entity/User";
import UserProfile from "entity/UserProfile";

import { RestController, RestRequest } from "./RestController";

export default class ProfileController extends RestController {

    deleteUserPicture(imageId: string): Promise<User> {
        let request = new RestRequest("/api/v1/profile/picture/" + imageId + "", "DELETE", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    getUserPictures(page?: Paging): Promise<Image[]> {
        let request = new RestRequest("/api/v1/profile/picture", "GET", null, undefined);
        if (page) request.addPaging("", page);

        return this.request(request, processImageArray) as Promise<Image[]>;
    }

    getUserProfile(): Promise<UserProfile> {
        let request = new RestRequest("/api/v1/profile/user/details", "GET", null, undefined);
        return this.request(request, null) as Promise<UserProfile>;
    }

    setProfileImage(imageId: string): Promise<User> {
        let request = new RestRequest("/api/v1/profile/picture/" + imageId + "", "POST", null, undefined);
        return this.request(request, processUser) as Promise<User>;
    }

    updateUserProfile(userProfile: UserProfile): Promise<UserProfile> {
        let request = new RestRequest("/api/v1/profile/user/details", "POST", userProfile, undefined);
        return this.request(request, null) as Promise<UserProfile>;
    }

    uploadProfilePicture(pictureName: string, type: string, width: number, height: number, data: any): Promise<Image> {
        let request = new RestRequest("/api/v1/profile/uploadPicture/" + pictureName + "/" + type + "/" + width + "/" + height + "", "POST", data, undefined);
        return this.request(request, processImage) as Promise<Image>;
    }

}
