/* eslint-disable no-console */
// Adapted from browser-bunyan's console-formatted-stream
import { TRACE, DEBUG, INFO, WARN, ERROR, FATAL } from '@browser-bunyan/levels';
import * as moment from 'moment';

const css = {
  levels: {
    trace: 'color: DeepPink',
    debug: 'color: GoldenRod',
    info: 'color: DarkTurquoise',
    warn: 'color: Purple',
    error: 'color: Crimson',
    fatal: 'color: Black',
  },
  default: 'color: DimGray',
  msg: 'color: SteelBlue',
  class: 'color: DimGray; font-style: italic; font-size: 0.9em',
};

export class CustomConsoleFormattedStream {
  private logByLevel: boolean;

  public constructor({ logByLevel = false } = {}) {
    this.logByLevel = logByLevel;
  }

  public write(rec: Record<string, unknown>): void {
    let levelCss = css.levels.info;
    let consoleMethod = console.log;

    const loggerName = rec.childName ? rec.name + '/' + rec.childName : rec.name;

    let levelName: string = (rec.levelName as string | undefined) ?? 'unknown';
    const formattedLevelName = (Array(6 - levelName.length).join(' ') + levelName).toUpperCase();

    if (this.logByLevel) {
      if (rec.level === TRACE) {
        levelName = 'debug';
      } else if (rec.level === FATAL) {
        levelName = 'error';
      }
      switch (levelName) {
        case 'trace':
        case 'debug':
        case 'info':
        case 'warn':
        case 'error':
          consoleMethod = console[levelName];
          break;
        default:
          consoleMethod = console.log;
          break;
      }
    } else {
      consoleMethod = console.log;
    }
    const level = rec.level as number;

    if (level < DEBUG) {
      levelCss = css.levels.trace;
    } else if (level < INFO) {
      levelCss = css.levels.debug;
    } else if (level < WARN) {
      levelCss = css.levels.info;
    } else if (level < ERROR) {
      levelCss = css.levels.warn;
    } else if (level < FATAL) {
      levelCss = css.levels.error;
    } else {
      levelCss = css.levels.fatal;
    }

    const time = rec.time as Date;
    const dateStr = moment(time).format('HH:mm:ss.SSS');

    // [time] level: (class): msg
    const msg = `[${dateStr}]%c${formattedLevelName}%c: %c%s%c %c${rec.msg}`;

    const logArgs = [msg, levelCss, css.default, css.class, rec.class ? `(${rec.class})` : '', loggerName, css.msg];

    if (rec.obj) {
      logArgs.push('\n');
      logArgs.push(rec.obj);
    }

    const err = rec.err as Error;
    if (err?.stack) {
      logArgs.push('\n');
      logArgs.push(err.stack);
    }
    consoleMethod.apply(console, logArgs);
  }
}
