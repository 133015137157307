import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialogState } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { SimpleMessageComponent } from './components/simple-message/simple-message.component';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class ModalSimpleMessageService {
  public constructor(private modalService: ModalService) {}

  public throw(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any = {},
    showDone = false,
    showSecondaryButton = false,
    newModalStyle = false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this.modalService.open(
      {
        title: options.title || false,
        actionButtonTitle: options.actionButtonTitle || 'Yes',
        showDone: showDone,
        showSecondaryButton: showSecondaryButton,
        newModalStyle: newModalStyle,
        ...options,
      },
      new ComponentPortal(
        SimpleMessageComponent,
        null,
        this.modalService.createInjector({
          message: options.message || 'Error',
        }),
      ),
      { ...options, panelClass: 'info' },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public confirm(options: any = {}): Observable<any> {
    return this.throw(options, true);
  }

  public close(): void {
    return this.modalService.close();
  }

  public checkIfModalOpen(): boolean {
    if (this.modalService.dialogRef && this.modalService.dialogRef.getState() === MatDialogState.OPEN) {
      return true;
    }
    return false;
  }
}

export type ModalType = 'throw' | 'confirm';
