// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import RequestStatusType from "enums/RequestStatusType";
import Util from "PreciateUtil";

export default interface RequestStatus {
    requestStatusId?: string;
    userId?: string;
    requestId?: string;
    status?: RequestStatusType;
    progress?: number;
    failureReason?: string;
    startedDateTime?: Date;
    endedDateTime?: Date;
}

export function processRequestStatus(value?: RequestStatus): void {
  if (value) {
    value.startedDateTime = Util.toDate((value as any).startedDateTime);
    value.endedDateTime = Util.toDate((value as any).endedDateTime);
  }
}

export function processRequestStatusArray(array?: RequestStatus[]): void {
  if (array) {
    for (const v of array) {
      processRequestStatus(v);
    }
  }
}
