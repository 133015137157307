/* eslint-disable @typescript-eslint/no-unused-vars */
import { Overlay } from '@angular/cdk/overlay';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Injector, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { merge, ReplaySubject } from 'rxjs';
import { filter, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

import ClientConfiguration from 'entity/ClientConfiguration';
import User from 'entity/User';

import { ModalThirdPartySignInCheckerComponent } from './commons/modal-third-party-sign-in-checker/modal-third-party-sign-in-checker.component';
import { CONSTANTS } from './core/constants';
import { AmplitudeService } from './core/services/Amplitude/amplitude.service';
import AmplitudeEvents from './core/services/Amplitude/amplitudeEvents';
import { AwsService } from './core/services/aws.service';
import { FooterService } from './core/services/footer.service';
import { LaunchDarklyService } from './core/services/launch-darkly/launch-darkly.service';
import { LaunchDarklyFlags } from './core/services/launch-darkly/launch-darkly.types';
import { coreSelectors, getFeatureFlagObservable } from './core/state';
import { AuthService } from './services/auth.service';
import { FirebaseService } from './services/firebase.service';
import { TermsService } from './services/terms.service';
import { IconHelperService } from './shared/services/icon-helper.service';
import { AppState } from './types';
import { setUseImageCdn } from './utils/image';
import { Loggable } from './utils/logging/loggable';
import { notNil } from './utils/stream-util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends Loggable implements OnInit, OnDestroy {
  private static INIT_FIREBASE = true;
  public static PLATFORM_ID: Record<string, unknown>;

  private alive = true;
  public showloader = true;
  public branchReady = false;
  private hostname: string | undefined;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private router: Router,
    private footerService: FooterService,
    private zone: NgZone,
    private amplitudeService: AmplitudeService,
    private _injector: Injector,
    private overlay: Overlay,
    private termsService: TermsService,
    private launchDarklyService: LaunchDarklyService,
    private firebaseService: FirebaseService,
    private store: Store<AppState>,
    _authService: AuthService,
    _awsService: AwsService,
    private iconHelperService: IconHelperService,
  ) {
    super();
    AppComponent.PLATFORM_ID = this.platformId;

    this.iconHelperService.registerIcons();

    getFeatureFlagObservable(this.store, LaunchDarklyFlags.image_resize_cdn)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((imageResizeCdn: boolean) => setUseImageCdn(imageResizeCdn));
  }

  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e): e is NavigationStart => e instanceof RouterEvent),
        takeWhile(() => this.alive),
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((event: RouterEvent) => {
        this.navigationInterceptor(event);
      });

    this.store
      .select(coreSelectors.getConfig)
      .pipe(filter(notNil), takeUntil(this.destroyed$))
      .subscribe((config: ClientConfiguration) => {
        this.initConfig(config);
        this.branchReady = true;
        if (!isPlatformServer(this.platformId)) {
          this.initBranch();
        }
      });

    this.firebaseService.newUserSignup$
      .pipe(takeWhile(() => this.alive))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(this.openThirdPartyCheckerModal.bind(this));
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.alive = false;
    void this.launchDarklyService.close();
  }

  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showloader = true;
      return;
    }
    if (event instanceof NavigationEnd) {
      this.showloader = false;
      if (isPlatformBrowser(this.platformId)) {
        const link: HTMLLinkElement = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        link.setAttribute('href', 'https://' + this.hostname + event.urlAfterRedirects);
      }
    }

    if (event instanceof NavigationCancel) {
      this.info('NavigationCancel', event);
      this.showloader = false;
    }
    if (event instanceof NavigationError) {
      this.error('NavigationError', event.error);
      this.showloader = false;
    }
    this.showloader = false;
  }

  public onBannerClick(): void {
    void this.footerService.downloadApp();
  }

  private initConfig(config: ClientConfiguration): void {
    //initialize amplitude
    if (isPlatformBrowser(this.platformId)) {
      this.amplitudeService.initialize(config.amplitudeKey);
    }

    this.hostname = config.hostname;
  }

  private initBranch(): void {
    if (window.parent === window) {
      let branchKey: string;
      if (
        location.hostname === 'dev.preciate.org' ||
        location.hostname === 'localhost' ||
        location.hostname === 'preciateweb.ngrok.io' ||
        location.hostname === 'dev.preciate.com' ||
        location.hostname === 'qa.preciate.com'
      ) {
        branchKey = CONSTANTS.BRANCH_KEY_DEV;
      } else {
        branchKey = CONSTANTS.BRANCH_KEY;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window?.branch?.init(branchKey, (err: unknown, data: any) => {
        this.zone.run(() => {
          if (!err && data.data_parsed?.path != null) {
            this.router
              .navigateByUrl(data.data_parsed.path)
              .then(() => {
                this.branchReady = true;
              })
              .catch((error: Error) => {
                throw error;
              });
          } else {
            this.branchReady = true;
          }
        });
      });
    } else {
      this.branchReady = true;
    }
  }

  // MODAL: Third Party Checker
  private createInjector(dataToPass: unknown): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(MAT_DIALOG_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  private openThirdPartyCheckerModal(user: User): void {
    window?.dataLayer?.push({ event: 'signUpFirebase' });
    this.amplitudeService.setUserId(user.userId);
    this.amplitudeService.logEvent(AmplitudeEvents.REGISTRATION_USER_SIGN_UP);

    const portalRef = new ComponentPortal(
      ModalThirdPartySignInCheckerComponent,
      null,
      this.createInjector({
        user: this.firebaseService.firebaseUser,
      }),
    );

    const overlayRef = this.overlay.create();
    const closed$ = overlayRef.attach(portalRef).instance.closed.pipe(tap(() => overlayRef.detach()));

    // If the user accepts, we sign them in and say they accepted the terms
    const shouldCreateAccount$ = closed$.pipe(
      filter((res) => res),
      tap(() => this.amplitudeService.logEvent(AmplitudeEvents.IDENTITY_PROVIDER_CREATE_ACCOUNT)),
      switchMap(() => this.firebaseService.createFirebaseUser()),
      switchMap(() => this.termsService.acceptTerms()),
    );

    // If they cancelled, sign them out and reload the window
    const cancelled$ = closed$.pipe(
      filter((res) => !res),
      switchMap(() => this.firebaseService.signOut()),
      tap(() => {
        this.showloader = false;
        window.location?.reload();
      }),
    );

    merge(shouldCreateAccount$, cancelled$).pipe(takeUntil(this.destroyed$)).subscribe();
  }
}
