// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import ClientConfiguration from "entity/ClientConfiguration";

import { RestController, RestRequest } from "./RestController";

export default class ConfigurationController extends RestController {

    getClientConfiguration(): Promise<ClientConfiguration> {
        let request = new RestRequest("/api/v1/client/config", "GET", null, undefined);
        return this.request(request, null) as Promise<ClientConfiguration>;
    }

}
