// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import Company, { processCompany, processCompanyArray } from "entity/Company";
import CompanyReport from "entity/CompanyReport";
import CompanyReportType from "enums/CompanyReportType";
import CompanyReportUrl from "entity/CompanyReportUrl";
import Employee, { processEmployeeArray } from "entity/Employee";
import Paging from "./Paging";
import User, { processUserArray } from "entity/User";

import { QueryParams, RestController, RestRequest } from "./RestController";

export default class CompanyController extends RestController {

    addCompanyAdminRole(companyId: string, userId: string): Promise<void> {
        let request = new RestRequest("/api/v1/company/" + companyId + "/add/admin/" + userId + "", "POST", null, undefined);
        return this.requestVoid(request);
    }

    apiKeyCreateCompany(company: Company): Promise<Company> {
        let request = new RestRequest("/api/v1/company/api/key/create", "POST", company, undefined);
        return this.request(request, processCompany) as Promise<Company>;
    }

    apiKeyDeleteCompanyData(companyId: string): Promise<Object[]> {
        let request = new RestRequest("/api/v1/company/api/key/data/" + companyId + "", "DELETE", null, undefined);
        return this.request(request, null) as Promise<Object[]>;
    }

    /*
     Block user from a company.
     */

    blockUser(companyId: string, userId: string): Promise<void> {
        let request = new RestRequest("/api/v1/company/" + companyId + "/user/block/" + userId + "", "POST", null, undefined);
        return this.requestVoid(request);
    }

    convertMyCompany(company: Company): Promise<Company> {
        let request = new RestRequest("/api/v1/company/convert", "POST", company, undefined);
        return this.request(request, processCompany) as Promise<Company>;
    }

    createCompany(company: Company): Promise<Company> {
        let request = new RestRequest("/api/v1/company/create", "POST", company, undefined);
        return this.request(request, processCompany) as Promise<Company>;
    }

    deleteCompanyData(companyId: string): Promise<Object[]> {
        let request = new RestRequest("/api/v1/company/data/" + companyId + "", "DELETE", null, undefined);
        return this.request(request, null) as Promise<Object[]>;
    }

    /*
     Search users belonging to the company. This method returns also blocked users. Requires company admin privilege.
     */

    findEmployees(companyId: string, text?: string, page?: Paging): Promise<Employee[]> {
        const queryParams: QueryParams = { };
        if (text !== undefined) {
          queryParams.text = text;
        }
        let request = new RestRequest("/api/v1/company/admin/" + companyId + "/employees/find", "GET", null, queryParams);
        if (page) request.addPaging("", page);

        return this.request(request, processEmployeeArray) as Promise<Employee[]>;
    }

    /*
     Get list of all the companies. Operation is paginated.
     */

    getAllCompanies(page?: Paging): Promise<Company[]> {
        let request = new RestRequest("/api/v1/company/all", "GET", null, undefined);
        if (page) request.addPaging("", page);

        return this.request(request, processCompanyArray) as Promise<Company[]>;
    }

    /*
     Gets a company.
     */

    getCompany(companyId: string): Promise<Company> {
        let request = new RestRequest("/api/v1/company/" + companyId + "", "GET", null, undefined);
        return this.request(request, processCompany) as Promise<Company>;
    }

    /*
     Gets company count.
     */

    getCompanyCount(): Promise<Number> {
        let request = new RestRequest("/api/v1/company/count", "GET", null, undefined);
        return this.request(request, null) as Promise<Number>;
    }

    getCompanyData(companyId: string): Promise<Object[]> {
        let request = new RestRequest("/api/v1/company/data/" + companyId + "", "GET", null, undefined);
        return this.request(request, null) as Promise<Object[]>;
    }

    /*
     Get company report URL
     */

    getCompanyReportUrl(companyReportId: number): Promise<CompanyReportUrl> {
        let request = new RestRequest("/api/v1/company/report/url", "GET", null, { companyReportId: companyReportId });
        return this.request(request, null) as Promise<CompanyReportUrl>;
    }

    getCompanyReports(page?: Paging, type?: CompanyReportType): Promise<CompanyReport[]> {
        const queryParams: QueryParams = { };
        if (type !== undefined) {
          queryParams.type = type;
        }
        let request = new RestRequest("/api/v1/company/report", "GET", null, queryParams);
        if (page) request.addPaging("", page);

        return this.request(request, null) as Promise<CompanyReport[]>;
    }

    /*
     Gets the current company for the user.
     */

    getCurrentCompany(): Promise<Company> {
        let request = new RestRequest("/api/v1/company/user", "GET", null, undefined);
        return this.request(request, processCompany) as Promise<Company>;
    }

    /*
     Get users belonging to the company. This method returns also blocked users. Requires company admin privilege.
     */

    getEmployees(companyId: string, page?: Paging): Promise<Employee[]> {
        let request = new RestRequest("/api/v1/company/admin/" + companyId + "/employees", "GET", null, undefined);
        if (page) request.addPaging("", page);

        return this.request(request, processEmployeeArray) as Promise<Employee[]>;
    }

    /*
     Get users belonging to my company.
     */

    getMyCompanyUsers(page?: Paging): Promise<User[]> {
        let request = new RestRequest("/api/v1/company/my/users", "GET", null, undefined);
        if (page) request.addPaging("", page);

        return this.request(request, processUserArray) as Promise<User[]>;
    }

    /*
     Gets a company by name.
     */

    getPublicCompany(companyName: string): Promise<Company> {
        let request = new RestRequest("/api/v1/company/public/" + encodeURIComponent(companyName) + "", "GET", null, undefined);
        return this.request(request, processCompany) as Promise<Company>;
    }

    /*
     Gets the current company for a user.
     */

    getUserCompany(userId: string): Promise<Company> {
        let request = new RestRequest("/api/v1/company/user/" + userId + "", "GET", null, undefined);
        return this.request(request, processCompany) as Promise<Company>;
    }

    /*
     Get users belonging to the company.
     */

    getUsers(companyId: string, page?: Paging): Promise<User[]> {
        let request = new RestRequest("/api/v1/company/" + companyId + "/users", "GET", null, undefined);
        if (page) request.addPaging("", page);

        return this.request(request, processUserArray) as Promise<User[]>;
    }

    /*
     Check if user has verified company email.
     */

    hasVerifiedCompanyEmail(companyId: string): Promise<Boolean> {
        let request = new RestRequest("/api/v1/company/" + companyId + "/has/verified/company/email", "POST", null, undefined);
        return this.request(request, null) as Promise<Boolean>;
    }

    isCompanyAvailable(companyName: string): Promise<boolean> {
        let request = new RestRequest("/api/v1/company/check/available", "POST", null, { companyName: companyName });
        return this.request(request, null) as Promise<boolean>;
    }

    isDomainAvailable(domain: string): Promise<boolean> {
        let request = new RestRequest("/api/v1/company/check/domain/available", "POST", null, { domain: domain });
        return this.request(request, null) as Promise<boolean>;
    }

    removeCompanyAdminRole(companyId: string, userId: string): Promise<void> {
        let request = new RestRequest("/api/v1/company/" + companyId + "/remove/admin/" + userId + "", "POST", null, undefined);
        return this.requestVoid(request);
    }

    /*
     Remove the current company from the user.
     */

    removeCurrentCompany(): Promise<void> {
        let request = new RestRequest("/api/v1/company/user", "DELETE", null, undefined);
        return this.requestVoid(request);
    }

    /*
     Remove user from a company.
     */

    removeUser(companyId: string, userId: string): Promise<void> {
        let request = new RestRequest("/api/v1/company/" + companyId + "/user/remove/" + userId + "", "POST", null, undefined);
        return this.requestVoid(request);
    }

    /*
     Request a new company.
     */

    requestCompany(companyName: string, user: User): Promise<void> {
        let request = new RestRequest("/api/v1/company/request/" + companyName + "", "POST", user, undefined);
        return this.requestVoid(request);
    }

    /*
     Search companies. This does like %search% operation on the company name.
     */

    searchCompanies(search?: string, page?: Paging): Promise<Company[]> {
        let request = new RestRequest("/api/v1/company/search/" + search + "/", "GET", null, undefined);
        if (page) request.addPaging("", page);

        return this.request(request, processCompanyArray) as Promise<Company[]>;
    }

    /*
     Sets the current company for the user.
     */

    setCurrentCompany(company: Company): Promise<Company> {
        let request = new RestRequest("/api/v1/company/user", "POST", company, undefined);
        return this.request(request, processCompany) as Promise<Company>;
    }

    /*
     Set company email domain verified if user has a matching verified email
     */

    setEmailDomainVerified(emailDomain: string): Promise<boolean> {
        let request = new RestRequest("/api/v1/company/set/company/email/verified", "POST", null, { emailDomain: emailDomain });
        return this.request(request, null) as Promise<boolean>;
    }

    /*
     Updates the company information
     */

    updateCompany(company: Company, companyId: string): Promise<Company> {
        let request = new RestRequest("/api/v1/company/" + companyId + "", "PATCH", company, undefined);
        return this.request(request, processCompany) as Promise<Company>;
    }

}
