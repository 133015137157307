export const STRINGS = {
  SCOOT_WELCOME_TITLE: 'Scoot | Build Strong Relationships',
  ALL_COMPANIES: 'All Companies',
  DEFAULT_ERROR_MESSAGE: 'An error occurred processing your request. Please contact support.',
  CONNECTION_SEARCH_ERROR_MESSAGE: 'You have entered an invalid string. Please do not include special characters.',
  MAGIC_CODE_TITLE: 'Magic Code Applied',
  VERIFY_EMAIL_TITLE: 'Confirm Email',
  VERIFY_EMAIL:
    'Follow the link in the email we sent to $1 to confirm your email address and help secure your account.',
  INVALID_PASSWORD_TITLE: 'Invalid Password',
  INVALID_PASSWORD_MSG: 'Your current password was not correct. Check it and please try again.',
  PASSWORD_CHANGED_TITLE: 'Password Changed',
  PASSWORD_CHANGED_MSG: 'Your password has been updated.',
  DISMISS_REQUEST: 'Dismiss Request',
  DISMISS_TEXT:
    "If you dismiss the connection request, %1 will not be able to see your profile and no longer able to request a connection. If you change your mind in the future, you can request a connection from %1's profile.",
  PRECIATE_INTEGRATION_AUTH_ERROR_TEXT:
    'There was an issue processing this request. Please try again or reach out to support at support.preciate.com',
  SCOOT_INTEGRATION_AUTH_ERROR_TEXT:
    'There was an issue processing this request. Please try again or reach out to support at support.scoot.app',
};

export const PRECIATE_CONTACT_SALES_LINK = 'https://preciate.com/demo/';
export const SCOOT_CONTACT_SALES_LINK = 'https://scoot.app/demo/';

export default STRINGS;
