// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import Company, { processCompany } from "./Company";
import User, { processUser } from "./User";

export default interface UserResult {
    user?: User;
    company?: Company;
}

export function processUserResult(value?: UserResult): void {
  if (value) {
    processUser(value.user);
    processCompany(value.company);
  }
}
