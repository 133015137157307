import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { initialize, LDClient, LDFlagSet, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { isEqual } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { take, filter, tap } from 'rxjs/operators';

import { AuthenticationState, coreActions, coreSelectors } from 'app/core/state';
import { formatUserName } from 'app/core/utilities/user-util';
import { CUSTOM_FEATURE_FLAGS_KEY } from 'app/modules/social/debugger/feature-flag-debugger/feature-flag-debugger.component';
import { socialAppSelectors } from 'app/modules/social/state/app';
import { AppState, Timeout } from 'app/types';
import { isBot } from 'app/utils';
import { Loggable } from 'app/utils/logging/loggable';
import { notNil } from 'app/utils/stream-util';
import ClientConfiguration from 'entity/ClientConfiguration';
import Company from 'entity/Company';
import Social from 'entity/Social';
import User from 'entity/User';

import { ContextKey, LaunchDarklyFlagType, LaunchDarklyFlagValues, LD_FLAG_DEFAULTS } from './launch-darkly.types';
import { UserKey } from './launch-darkly.types';

const LD_TIMEOUT = 10000;

interface BrowserAttributes {
  version: string;
  versionMinor: string;
  versionPatch: string;
  os: string;
  browser: string;
}

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService extends Loggable {
  private ldClient?: LDClient;
  public flags: LDFlagValue = LD_FLAG_DEFAULTS;
  private company?: Company;
  private user?: User;
  private social?: Social;
  private ldTimeout?: Timeout;
  private ready = false;

  public constructor(
    private deviceDetectorService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private store: Store<AppState>,
  ) {
    super();
    this.setDisplayName('LaunchDarklyService');

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Get LD Auth State and Set LD Context
    this.store
      .select(coreSelectors.getLaunchDarklyAuthenticationState)
      .pipe(
        filter(({ state }) => state !== AuthenticationState.INIT),
        tap((state) => {
          this.user = state.user;
          this.company = state.company;
        }),
        filter(() => !!this.ldClient),
      )
      .subscribe(() => this.changeUserContext());

    // Get Current Social and Set LD Context
    this.store
      .select(socialAppSelectors.getCurrentSocial)
      .pipe(
        tap((social) => (this.social = social)),
        filter(() => !!this.ldClient),
      )
      .subscribe(() => this.changeUserContext());

    // Get Config
    this.store.select(coreSelectors.getConfig).pipe(filter(notNil), take(1)).subscribe(this.init.bind(this));
  }

  private init(config: ClientConfiguration): void {
    const contextKey = this.generateContextKey();
    this.ldClient = initialize(config.ldId ?? '', contextKey);

    this.ldClient.on('ready', this.handleReady.bind(this));
    this.ldClient.on('change', this.handleChange.bind(this));

    // Set defaults if LD doesn't respond
    this.ldTimeout = setTimeout(() => {
      this.store.dispatch(
        coreActions.FLAG_CHANGE({
          flags: {
            ...LD_FLAG_DEFAULTS,
          },
        }),
      );
    }, LD_TIMEOUT);
  }

  private async changeUserContext(): Promise<void> {
    const { companyId, companyName, socialId, userId } = this.social ?? {};
    const key = this.generateContextKey(companyId, companyName, socialId, userId);

    if (!this.ldClient) {
      this.error('Attempted to change user context without ldClient');
      return;
    }

    if (key.custom) {
      key.custom['currentGuestAccountId'] = companyId;
      key.custom['currentGuestAccountName'] = companyName;
    }

    this.info('Changing user context in room', { key, user: this.user });
    const customFlags = JSON.parse(localStorage.getItem(CUSTOM_FEATURE_FLAGS_KEY) ?? '{}');
    await this.ldClient.identify(key).then((flags: LDFlagSet) => (this.flags = { ...customFlags, ...flags }));
  }

  public async updateUserContext() {
    await this.changeUserContext();
  }

  public async close(): Promise<void> {
    await this.ldClient?.close(() => {
      process.exit(0);
    });
  }

  /**
   * Handle the launch darkly client ready callback.
   */
  private handleReady(): void {
    if (!this.ldClient) {
      return;
    }
    if (this.ldTimeout) {
      clearTimeout(this.ldTimeout);
    }
    const customFlags = JSON.parse(localStorage.getItem(CUSTOM_FEATURE_FLAGS_KEY) ?? '{}');
    this.flags = this.ldClient.allFlags();
    this.info('Received initial flags from LaunchDarkly', this.flags);
    this.ready = true;
    this.store.dispatch(
      coreActions.FLAG_CHANGE({
        flags: {
          ...LD_FLAG_DEFAULTS,
          ...this.flags,
          ...customFlags,
        },
      }),
    );
  }

  /**
   * Handle the launch darkly client ready change callback
   */
  private handleChange(flags: LDFlagSet): void {
    this.info('Received new flags from LaunchDarkly', flags);

    if (this.ldTimeout) {
      clearTimeout(this.ldTimeout);
    }
    // Typing through here is very loose, because launch darkly can't provide typing guarantees, and liberally uses
    // any within LDFlagSet. This attempts to provide the specific flags that have changed with values mapped directly.
    const flagValues = Object.entries(flags).reduce(
      (acc, [_flagName, valueChange]) => {
        const flagName = _flagName as LaunchDarklyFlagType;
        if (!isEqual(this.flags[flagName], valueChange.current)) {
          acc[flagName] = valueChange.current;
        }
        return acc;
      },
      {} as Record<LaunchDarklyFlagType, unknown>,
    ) as Partial<LaunchDarklyFlagValues>;

    // Only emit when a value changes
    if (Object.keys(flagValues).length > 0) {
      this.store.dispatch(coreActions.FLAG_CHANGE({ flags: flagValues }));
    }

    this.flags = {
      ...this.flags,
      ...flagValues,
    };
  }

  private getFlagValue(flagKey: string): LDFlagValue {
    if (this.ldClient) {
      return this.ldClient.variation(flagKey, false);
    }
    return false;
  }

  private maxTriesForLdClientIsLoaded = 10;
  private currentTriesForLdClientIsLoaded = 0;

  public resolveWhenLdClientIsLoaded(): Promise<LDClient | undefined> {
    return new Promise<LDClient | undefined>((resolve) => {
      if (this.ldClient && this.ready) {
        resolve(this.ldClient);
      } else {
        const interval = window.setInterval(() => {
          this.currentTriesForLdClientIsLoaded += 1;
          if (this.ldClient && this.ready) {
            window.clearInterval(interval);
            resolve(this.ldClient);
          } else if (this.currentTriesForLdClientIsLoaded >= this.maxTriesForLdClientIsLoaded) {
            this.error('Waiting for ldClient tries exceeded max tries');
            return resolve(undefined);
          }
        }, 1000);
      }
    });
  }

  public waitForFlagLoad(flagKey: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.resolveWhenLdClientIsLoaded()
        .then((ldClient) => {
          if (ldClient) {
            // Current Flag Value
            resolve(this.getFlagValue(flagKey));
          } else {
            this.error('Error waiting for ldClient');
            resolve(this.getFlagValue(flagKey));
          }
        })
        .catch((error) => {
          this.error('Error waiting for flag load', error);
          resolve(this.getFlagValue(flagKey));
        });
    });
  }

  public getBrowserAttributes(): BrowserAttributes {
    const [version, versionMinor, versionPatch] = this.deviceDetectorService.browser_version.split('.');
    const browser = this.deviceDetectorService.browser;
    const os = this.deviceDetectorService.os;
    return { version, versionMinor, versionPatch, os, browser };
  }

  /**
   * @deprecated - since LD SDK v3.0, use changeUserContext instead
   */
  private generateUserKey(
    socialCompanyId?: string,
    socialCompanyName?: string,
    socialId?: string,
    hostedBy?: string,
  ): UserKey {
    const { company, user } = this;
    if (user) {
      const companyId = company?.companyId ?? 'N/A';
      const companyName = company?.companyName ?? 'N/A';
      const name = formatUserName(user) || 'N/A';
      const email = user?.emailAddress ?? 'N/A';

      const browserAttributes = this.getBrowserAttributes();
      return {
        key: user.userId,
        name: name,
        email: email,
        anonymous: false,
        custom: {
          companyId: companyId,
          companyName: companyName,
          hostedBy: hostedBy,
          currentSocial: socialId,
          currentGuestAccountName: socialCompanyName,
          currentGuestAccountId: socialCompanyId,
          ...browserAttributes,
        },
      };
    }

    // Anonymous users that look like bot get a hard-coded key of the sha1sum of 'bot'
    if (isBot) {
      return { anonymous: true, key: '54dfe465cd872456c380cbc4db45daa5cc5e01d3' };
    }

    return {
      anonymous: true,
      custom: {
        hostedBy: hostedBy,
        currentSocial: socialId,
        currentGuestAccountName: socialCompanyName,
        currentGuestAccountId: socialCompanyId,
      },
    };
  }

  /*
   * The version 3.0 of the Launch Darkly SDK uses context instead of user,
   * with that the object send to initialize the LDClient changes and start to
   * use the kind attribute. In order to finish this implementation, we need to
   * create the contexts on the Launch Darkly account.
   */
  private generateContextKey(
    socialCompanyId?: string,
    socialCompanyName?: string,
    socialId?: string,
    hostedBy?: string,
  ): ContextKey {
    const { company, user } = this;
    if (user) {
      const companyId = company?.companyId ?? 'N/A';
      const companyName = company?.companyName ?? 'N/A';
      const name = formatUserName(user) || 'N/A';
      const email = user?.emailAddress ?? 'N/A';

      const browserAttributes = this.getBrowserAttributes();
      return {
        kind: 'user',
        key: user.userId,
        name: name,
        email: email,
        anonymous: false,
        custom: {
          companyId: companyId,
          companyName: companyName,
          hostedBy: hostedBy,
          currentSocial: socialId,
          currentGuestAccountName: socialCompanyName,
          currentGuestAccountId: socialCompanyId,
          ...browserAttributes,
        },
      };
    }

    // Anonymous users that look like bot get a hard-coded key of the sha1sum of 'bot'
    if (isBot) {
      return { kind: 'user', anonymous: true, key: '54dfe465cd872456c380cbc4db45daa5cc5e01d3' };
    }

    return {
      kind: 'user',
      anonymous: true,
      custom: {
        hostedBy: hostedBy,
        currentSocial: socialId,
        currentGuestAccountName: socialCompanyName,
        currentGuestAccountId: socialCompanyId,
      },
    };
  }
}
