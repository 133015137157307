import { PRECIATE_CONTACT_SALES_LINK } from 'app/core/strings';
import { HelpCenterUrls } from 'app/utils/help-center';
import {
  EVENT_GUIDE_TITLE,
  EVENT_GUIDE_SUB_TITLE,
  EVENT_GUIDE_ITEMS,
  ROOM_ARTIFACT_SET,
} from 'app/utils/launch-darkly.util';

export interface WarningMessage {
  description: string;
  link: {
    text: string;
    url: string;
  };
  title: string;
}

export interface EarlyAccessMessage {
  message?: string;
  link?: {
    text: string;
    url: string;
  };
}

type LDCustomUserOptions = Partial<{
  companyId: string;
  companyName: string;
  currentSocial: string;
  hostedBy: string;
  browser: string;
  version: string;
  currentGuestAccountName?: string;
  currentGuestAccountId?: string;
  versionMinor?: string;
  versionPatch?: string;
  os: string;
}>;
export interface UserKey {
  key?: string;
  name?: string;
  email?: string;
  anonymous: boolean;
  custom?: LDCustomUserOptions;
}

export interface ContextKey {
  kind: string;
  key?: string;
  name?: string;
  email?: string;
  anonymous: boolean;
  custom?: LDCustomUserOptions;
}

export interface ShareScreenVideoCodec {
  mimeType: string;
  sdpFormatFilter: string;
}

export interface ContentPanelItem {
  paragraph: string;
  learnMoreLink: string;
  subtitle: string;
}

export interface LaunchDarklyContentPanel {
  title: string;
  items: ContentPanelItem[];
}

export interface EventGuideItem {
  iconPath: string;
  name: string;
  redirectTo: string;
  redirectText: string;
}

export interface SocialRecordingRegion {
  regions: string[];
}

export interface LaunchDarklyEventGuide {
  title: string;
  subTitle: string;
  items: EventGuideItem[];
}

export interface RoomArtifact {
  name: string;
  id: string;
}

export interface LaunchDarklyRoomArtifacts {
  rooms: RoomArtifact[];
}

export interface CustomRoomTutorial {
  tutorial_url?: string;
  title?: string;
  subtitle?: string;
}

export enum pixiInspector {
  OFF = 'OFF',
  MAIN_OFFICIAL = 'MAIN_OFFICIAL',
  MAP_OFFICIAL = 'MAP_OFFICIAL',
  MAIN_COMMUNITY = 'MAIN_COMMUNITY',
  MAP_COMMUNITY = 'MAP_COMMUNITY',
  ITEM_EDITOR_OFFICIAL = 'ITEM_EDITOR_OFFICIAL',
  ITEM_EDITOR_COMMUNITY = 'ITEM_EDITOR_COMMUNITY',
}

export interface LaunchDarklyFlagValues {
  /* Social Feature Flags */
  browser_check: boolean;
  custom_room_tutorial: CustomRoomTutorial;
  pixi_inspector: pixiInspector;
  device_check: boolean;
  enable_group_screen_share_recording: boolean;
  enhanced_screen_share: boolean;
  enable_group_screen_share: boolean;
  enable_watchrtc: boolean;
  hd_presenter: boolean;
  hd_presenter_1080p: boolean;
  peer_stats_monitoring: boolean;
  presentation_audio_mix: boolean;
  presentation_recording: boolean;
  presentation_settings: boolean;
  share_screen_video_codec: ShareScreenVideoCodec;
  screenshare_start_message: boolean;
  social_alternate_hosts: boolean;
  social_android_fullscreen: boolean;
  social_artifacts: boolean;
  social_audio_range: boolean;
  social_chat: boolean;
  moderated_chat: boolean;
  social_connection_status_indicators: boolean;
  social_convo: boolean;
  social_cross_room_presentation: boolean;
  social_debugger: boolean;
  social_disable_intro_video: boolean;
  social_fallback_canvas_video: boolean;
  social_firefox: boolean;
  social_gain_control: boolean;
  social_host_admission: boolean;
  social_in_room_settings: boolean;
  social_live_stream_management: boolean;
  social_low_bandwidth_mode: boolean;
  social_marginal_connection_indicator: boolean;
  social_mouse_movement: boolean;
  social_new_side_menu: boolean;
  social_new_tutorial: boolean;
  social_performer: boolean;
  social_photo_booth: boolean;
  social_playlist: boolean;
  social_presentation_settings: boolean;
  social_presentation_subscription_warning: boolean;
  social_recording_region: SocialRecordingRegion;
  social_safari: boolean;
  social_set_group_size: boolean;
  social_share_link: boolean;
  social_share_screen: boolean;
  social_show_invite_modal: boolean;
  social_toast: boolean;
  social_unrestricted_zoom: boolean;
  social_zoom: boolean;
  video_background_blur: boolean;
  enable_new_room_sizes: boolean;
  enable_center_viewport: boolean;
  enable_auto_zoom: boolean;
  enhanced_media_permissions: boolean;
  pinch_zoom: boolean;
  map_teleport: boolean;
  emotes: boolean;
  fit_tiles_to_room: boolean;
  vimeo_embed: boolean;
  sprite_circle_border: boolean;
  detect_black_circles: boolean;

  events: boolean;

  /*Presentation V1 */
  presentation_my_group_v1: boolean;
  speaking_participants_v1: boolean;
  group_conversations_v1: boolean;

  /* Presentation V2 - Gallery Tabs, Presentation Favorites, Active Speaker */
  presentation_v2: boolean;

  /* Pop out video */
  pop_out_video: boolean;

  /* Smart Badging */
  smart_badging: boolean;
  search_v1: boolean;
  tutorial_v1: boolean;

  /* Authentication */
  disable_register_with_mobile: boolean;
  oauth_with_redirect: boolean;

  /* GUEST API */
  enable_guest_api: boolean;

  /* Social Page */
  social_content_panel: LaunchDarklyContentPanel;
  learn_more_url: string;
  social_event_guide: LaunchDarklyEventGuide;
  social_room_artifact_set: LaunchDarklyRoomArtifacts;

  /* Social Banner */
  social_error_banner: WarningMessage;
  social_early_access_banner: EarlyAccessMessage;

  /* Admin */
  new_admin_navigation: boolean;
  admin_recording_page: boolean;
  admin_transcriptions_page: boolean;
  admin_reporting_page: boolean;
  admin_user_properties_page: boolean;

  // Logging
  server_logging: boolean;
  console_logging: boolean;
  enable_failfast_logic: boolean;

  // Site Wide
  image_resize_cdn: boolean;

  // Help Center
  help_link_screen_share_error: string;

  visual_indicators: boolean;

  /* Closed Captions */
  closed_captions: boolean;

  /* SVC */
  scalable_video_coding: boolean;

  // Entry flows
  entry_flows_v1: boolean;

  // Create Items Editor
  item_editor: boolean;

  // Onboarding Experience
  onboarding_experience: boolean;

  // AI Conversation Summaries
  ai_summaries: boolean;
}

export type LaunchDarklyFlagType = keyof LaunchDarklyFlagValues;

export enum LaunchDarklyFlags {
  /* Social Feature Flags */
  browser_check = 'browser_check',
  custom_room_tutorial = 'custom_room_tutorial',
  pixi_inspector = 'pixi_inspector',
  device_check = 'device_check',
  enable_group_screen_share = 'enable_group_screen_share',
  enable_group_screen_share_recording = 'enable_group_screen_share_recording',
  enhanced_screen_share = 'enhanced_screen_share',
  enable_watchrtc = 'enable_watchrtc',
  hd_presenter = 'hd_presenter',
  hd_presenter_1080p = 'hd_presenter_1080p',
  scalable_video_coding = 'scalable_video_coding',
  peer_stats_monitoring = 'peer_stats_monitoring',
  presentation_audio_mix = 'presentation_audio_mix',
  presentation_recording = 'presentation_recording',
  presentation_settings = 'presentation_settings',
  share_screen_video_codec = 'share_screen_video_codec',
  screenshare_start_message = 'screenshare_start_message',
  social_alternate_hosts = 'social_alternate_hosts',
  social_android_fullscreen = 'social_android_fullscreen',
  social_artifacts = 'social_artifacts',
  social_audio_range = 'social_audio_range',
  social_chat = 'social_chat',
  moderated_chat = 'moderated_chat',
  social_connection_status_indicators = 'social_connection_status_indicators',
  social_convo = 'social_convo',
  social_cross_room_presentation = 'social_cross_room_presentation',
  social_debugger = 'social_debugger',
  social_disable_intro_video = 'social_disable_intro_video',
  social_fallback_canvas_video = 'social_fallback_canvas_video',
  social_firefox = 'social_firefox',
  social_gain_control = 'social_gain_control',
  social_host_admission = 'social_host_admission',
  social_in_room_settings = 'social_in_room_settings',
  social_live_stream_management = 'social_live_stream_management',
  social_low_bandwidth_mode = 'social_low_bandwidth_mode',
  social_marginal_connection_indicator = 'social_marginal_connection_indicator',
  social_mouse_movement = 'social_mouse_movement',
  social_new_side_menu = 'social_new_side_menu',
  social_new_tutorial = 'social_new_tutorial',
  social_performer = 'social_performer',
  social_photo_booth = 'social_photo_booth',
  social_playlist = 'social_playlist',
  social_presentation_settings = 'social_presentation_settings',
  social_presentation_subscription_warning = 'social_presentation_subscription_warning',
  social_recording_region = 'social_recording_region',
  social_safari = 'social_safari',
  social_set_group_size = 'social_set_group_size',
  social_share_link = 'social_share_link',
  social_share_screen = 'social_share_screen',
  social_show_invite_modal = 'social_show_invite_modal',
  social_toast = 'social_toast',
  social_unrestricted_zoom = 'social_unrestricted_zoom',
  social_zoom = 'social_zoom',
  video_background_blur = 'video_background_blur',
  enable_new_room_sizes = 'enable_new_room_sizes',
  enable_center_viewport = 'enable_center_viewport',
  enable_auto_zoom = 'enable_auto_zoom',
  enhanced_media_permissions = 'enhanced_media_permissions',
  pinch_zoom = 'pinch_zoom',
  map_teleport = 'map_teleport',
  emotes = 'emotes',
  fit_tiles_to_room = 'fit_tiles_to_room',
  vimeo_embed = 'vimeo_embed',
  sprite_circle_border = 'sprite_circle_border',
  detect_black_circles = 'detect_black_circles',

  events = 'events',

  /*Presentation V1 */
  presentation_my_group_v1 = 'presentation_my_group_v1',
  speaking_participants_v1 = 'speaking_participants_v1',
  group_conversations_v1 = 'group_conversations_v1',

  /* Presentation V2 - Gallery Tabs, Presentation Favorites, Active Speaker */
  presentation_v2 = 'presentation_v2',

  /* Pop out video */
  pop_out_video = 'pop_out_video',

  /* Smart Badging */
  smart_badging = 'smart_badging',
  search_v1 = 'search_v1',
  tutorial_v1 = 'tutorial_v1',

  /* Authentication */
  disable_register_with_mobile = 'disable_register_with_mobile',
  oauth_with_redirect = 'oauth_with_redirect',

  /* Guest API */
  enable_guest_api = 'enable_guest_api',

  /* Social Page */
  social_content_panel = 'social_content_panel',
  learn_more_url = 'learn_more_url',
  social_event_guide = 'social_event_guide',
  social_room_artifact_set = 'social_room_artifact_set',

  /* Social Banner */
  social_error_banner = 'social_error_banner',
  social_early_access_banner = 'social_early_access_banner',

  /* Admin */
  new_admin_navigation = 'new_admin_navigation',
  admin_recording_page = 'admin_recording_page',
  admin_transcriptions_page = 'admin_transcriptions_page',
  admin_reporting_page = 'admin_reporting_page',
  admin_user_properties_page = 'admin_user_properties_page',

  // Logging
  server_logging = 'server_logging',
  console_logging = 'console_logging',
  enable_failfast_logic = 'enable_failfast_logic',

  // Site Wide
  image_resize_cdn = 'image_resize_cdn',

  // Help Center
  help_link_screen_share_error = 'help_link_screen_share_error',

  visual_indicators = 'visual_indicators',

  /* Closed Captions */
  closed_captions = 'closed_captions',

  svc_performance_check = 'svc_performance_check',

  // Entry Flow
  entry_flows_v1 = 'entry_flows_v1',

  // Create Items Editor
  item_editor = 'item_editor',

  // Onboarding Experience
  onboarding_experience = 'onboarding_experience',

  // AI Conversation Summaries
  ai_summaries = 'ai_summaries',
}

export const LD_FLAG_DEFAULTS: LaunchDarklyFlagValues = {
  browser_check: true,
  custom_room_tutorial: {},
  pixi_inspector: pixiInspector.OFF,
  device_check: true,
  enable_group_screen_share_recording: false,
  enhanced_screen_share: false,
  enable_group_screen_share: false,
  enable_watchrtc: false,
  hd_presenter: false,
  hd_presenter_1080p: false,
  scalable_video_coding: false,
  peer_stats_monitoring: false,
  presentation_audio_mix: true,
  presentation_recording: false,
  presentation_settings: true,
  share_screen_video_codec: { mimeType: 'video/H264', sdpFormatFilter: 'profile-level-id=42e01f' },
  screenshare_start_message: false,
  social_alternate_hosts: false,
  social_android_fullscreen: true,
  social_artifacts: true,
  social_audio_range: false,
  social_chat: true,
  moderated_chat: false,
  social_connection_status_indicators: false,
  social_convo: true,
  social_cross_room_presentation: false,
  social_debugger: false,
  social_disable_intro_video: false,
  social_fallback_canvas_video: false,
  social_firefox: true,
  social_gain_control: false,
  social_host_admission: true,
  social_in_room_settings: false,
  social_live_stream_management: false,
  social_low_bandwidth_mode: false,
  social_marginal_connection_indicator: false,
  social_mouse_movement: true,
  social_new_side_menu: true,
  social_new_tutorial: false,
  social_performer: true,
  social_photo_booth: true,
  social_playlist: true,
  social_presentation_settings: false,
  social_presentation_subscription_warning: false,
  social_recording_region: { regions: [] },
  social_safari: true,
  social_set_group_size: false,
  social_share_link: true,
  social_share_screen: true,
  social_show_invite_modal: true,
  social_toast: true,
  social_unrestricted_zoom: false,
  social_zoom: true,
  video_background_blur: false,
  enable_new_room_sizes: false,
  enable_center_viewport: false,
  enable_auto_zoom: false,
  enhanced_media_permissions: false,
  pinch_zoom: false,
  map_teleport: false,
  emotes: false,
  fit_tiles_to_room: false,
  vimeo_embed: false,
  sprite_circle_border: false,
  detect_black_circles: false,

  events: false,

  /*Presentation V1 */
  presentation_my_group_v1: false,
  speaking_participants_v1: false,
  group_conversations_v1: false,

  /* Presentation V2 - Gallery Tabs, Presentation Favorites, Active Speaker */
  presentation_v2: false,

  /* Pop out video */
  pop_out_video: false,

  /* Smart Badging */
  smart_badging: false,
  search_v1: false,
  tutorial_v1: false,

  /* Authentication */
  disable_register_with_mobile: false,
  oauth_with_redirect: false,

  /* Guest API */
  enable_guest_api: false,

  /* Social Page */
  social_content_panel: { title: '', items: [] },
  learn_more_url: PRECIATE_CONTACT_SALES_LINK,
  social_event_guide: { title: EVENT_GUIDE_TITLE, subTitle: EVENT_GUIDE_SUB_TITLE, items: EVENT_GUIDE_ITEMS },
  social_room_artifact_set: { rooms: ROOM_ARTIFACT_SET },

  /* Social Banner */
  social_error_banner: {
    description: '',
    link: {
      text: '',
      url: '',
    },
    title: '',
  } as WarningMessage,
  social_early_access_banner: {} as EarlyAccessMessage,

  /* Admin */
  new_admin_navigation: false,
  admin_recording_page: false,
  admin_transcriptions_page: false,
  admin_reporting_page: false,
  admin_user_properties_page: false,

  // Logging
  server_logging: true,
  console_logging: false,
  enable_failfast_logic: false,

  // Site Wide
  image_resize_cdn: false,

  // Help Center
  help_link_screen_share_error: HelpCenterUrls.SCREEN_SHARE_ERROR,

  /** Visual indicators flag */
  visual_indicators: false,

  /** Closed captions */
  closed_captions: false,

  // Entry Flows
  entry_flows_v1: false,

  // Create Items Editor
  item_editor: false,

  // Onboarding Experience
  onboarding_experience: false,

  // AI Conversation Summaries
  ai_summaries: false,
};

/**
 * Get the default value of a flag
 * @param flag The flag to retrieve
 * @returns The default value of the flag
 */
export const getDefaultFlagValue = <T extends keyof LaunchDarklyFlagValues>(flag: T): LaunchDarklyFlagValues[T] => {
  const value = LD_FLAG_DEFAULTS[flag];
  return value;
};
