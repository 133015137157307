// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import Company, { processCompany } from "./Company";
import UserResult, { processUserResult } from "entity/UserResult";

export default interface ResultEntity {
    userResult?: UserResult;
    company?: Company;
}

export function processResultEntity(value?: ResultEntity): void {
  if (value) {
    processUserResult(value.userResult);
    processCompany(value.company);
  }
}

export function processResultEntityArray(array?: ResultEntity[]): void {
  if (array) {
    for (const v of array) {
      processResultEntity(v);
    }
  }
}
