import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, filter } from 'rxjs/operators';

import { AppState } from 'app/types';
import { notNil } from 'app/utils/stream-util';

import { LaunchDarklyFlagValues } from '../services/launch-darkly/launch-darkly.types';

import * as actions from './actions';
import { coreReducer, initialState as initialCoreState } from './reducer';
import * as selectors from './selectors';

export * from './types';
const coreSelectors = selectors;
const coreActions = actions;

/**
 * Create an observable of a given feature flag from the store.
 *
 * This will emit when the flag value changes.
 *
 * @param store The ngrx store
 * @param flag The Launch Darkly flag to observe
 * @returns An observable that emits when the flag value changes.
 */
export const getFeatureFlagObservable = <T extends keyof LaunchDarklyFlagValues>(
  store: Store<AppState>,
  flag: T,
): Observable<LaunchDarklyFlagValues[T]> => {
  return store.select(coreSelectors.getFeatureFlags).pipe(
    filter(notNil),
    map((flags) => flags[flag]),
    distinctUntilChanged(),
  );
};

export { actions, coreActions, selectors, coreReducer, initialCoreState, coreSelectors };
