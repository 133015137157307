import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// disabling per comment: * Using this method is discouraged as the Angular CLI will set production mode when using the `optimization` option.
// if (environment.production) {
//   enableProdMode();
// }

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // Disabling for the time being
  // Breaks login flow for users in DEV
  // .then((moduleRef) => {
  //   if (isInDev()) {
  //     const applicationRef = moduleRef.injector.get(ApplicationRef);
  //     const componentRef = applicationRef.components[0];
  //     enableDebugTools(componentRef);
  //   }
  // })
  // eslint-disable-next-line no-console
  .catch((err) => console.log(err));
