import { Component, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ReplaySubject, takeUntil } from 'rxjs';

import { Loggable } from 'app/utils/logging/loggable';

import { NavItem } from '../constants';
import { LaunchDarklyFlags } from '../services/launch-darkly/launch-darkly.types';
import { getFeatureFlagObservable } from '../state';

@Component({
  selector: 'nav-items',
  templateUrl: './nav-items.component.html',
  styleUrls: ['./nav-items.component.scss'],
})
export class NavItemsComponent extends Loggable implements OnDestroy {
  @ViewChildren(MatMenu) public menuList?: QueryList<MatMenu>;
  @Input() public navItems?: NavItem[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  protected eventsEnabled?: boolean;

  public constructor(
    private store: Store,
    private router: Router,
  ) {
    super();
    this.setDisplayName('NavItemsComponent');

    getFeatureFlagObservable(this.store, LaunchDarklyFlags.events)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((eventsEnabled) => (this.eventsEnabled = eventsEnabled));
  }

  public async openMenu(trigger: MatMenuTrigger, index: number, item: NavItem): Promise<void> {
    if (!item.isMenu) {
      await this.navigateToRoute(item.route).catch((error) => {
        this.error(`Unable to navigate to route`, error);
      });
      return;
    }
    const menuListArray = this.menuList?.toArray() ?? [];
    if (menuListArray.length > index) {
      const menu = menuListArray[index];
      trigger.menu = menu;
      trigger.openMenu();
    }
  }

  public async navigateToRoute(route: string): Promise<void> {
    await this.router.navigate([route]).catch((error) => {
      this.error(`Error routing to ${route}`, error);
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public optionIsEnabled(option: string): boolean {
    if (option === 'Events') {
      return this.eventsEnabled ?? false;
    }
    return true;
  }
}
