import { Action, on } from '@ngrx/store';

import { createImmerReducer } from 'app/utils/createImmerReducer';
import { notUndefined } from 'app/utils/stream-util';
import { ScreenShareStatus, SocialEventType, SocialState } from 'messages/websocket.messages';

import { DEFAUlT_MIX_VOLUME } from '../../constants/volume';
import DEFAULT_SOCIAL_SETTINGS from '../../social-permission/constants';

import * as actions from './actions';
import { SocialAppState, VideoEffectsMode } from './types';

export const initialSharedState: SocialState = {
  eventType: SocialEventType.NONE,
  socialEvent: {
    eventType: SocialEventType.NONE,
  },
  navigationMarkersEnabled: false,
  screenShareStatus: ScreenShareStatus.NONE,
  livestreams: {
    queuedLivestreams: {},
  },
  hosts: [],
  bannedUsers: [],
  readOnly: {
    bannedUsers: {},
    waitingUsers: {},
  },
};

export const initialState: SocialAppState = {
  socialEntity: null,
  fullClusters: [],
  participants: {},
  artifacts: [],
  ctaItems: [],
  navItems: [],
  mixVolume: DEFAUlT_MIX_VOLUME,
  joined: false,
  videoEffect: {
    mode: VideoEffectsMode.NONE,
    enabled: false,
  },
  chatEnabled: false,
  badgeOpen: { open: false, userId: '', isSelectedInViewport: false },
  startingPoint: {},
  searchParticipantsOpen: false,
  shouldOpenMap: { open: false, userId: '' },
  isPopOut: { mode: 'NONE', open: false },
  isBadgingModalOpen: false,
  visualIndicatorInfo: {},
  userIsSpeaking: false,
  activeSpeakers: [],
  galleryTabs: [],
};

const _reducer = createImmerReducer(
  initialState,
  on(actions.INIT, (state: SocialAppState) => state),

  on(actions.LEAVE, (state: SocialAppState) => {
    state.shared = undefined;
    return state;
  }),

  on(actions.RECEIVE_SHARED_STATE, (state: SocialAppState, { region, sharedState, socialEvent }) => {
    state.restrictedEventType = socialEvent;
    state.shared = sharedState;
    if (notUndefined(region)) {
      state.region = region;
    }
    return state;
  }),

  on(actions.LOAD_SOCIAL, (state: SocialAppState, { social }) => {
    state.socialEntity = social;
    return state;
  }),

  on(actions.UPDATE_SOCIAL, (state: SocialAppState, { social }) => {
    state.socialEntity = social;
    return state;
  }),

  on(actions.JOIN_SOCIAL, (state: SocialAppState, { social }) => {
    state.socialEntity = social;
    state.joined = true;
    return state;
  }),

  on(actions.START_SOCIAL, (state: SocialAppState, { social }) => {
    state.socialEntity = social;
    state.joined = true;
    return state;
  }),

  on(actions.LEAVE_SOCIAL, (_: SocialAppState) => {
    return Object.assign({}, initialState);
  }),

  on(actions.ROOM_STARTED, (state: SocialAppState, { websocketUrl }) => {
    if (state.socialEntity) {
      if (state.socialEntity.socialMeta) {
        state.socialEntity.socialMeta.peerConnectionURL = websocketUrl;
      }
      state.socialEntity.isOpen = true;
    }
    return state;
  }),

  on(actions.CHANGE_PLAYLIST, (state: SocialAppState, { playlistId }) => {
    if (!state.socialEntity) {
      return state;
    }
    state.socialEntity.playlistId = playlistId;
    return state;
  }),

  on(actions.SELECT_CONNECTED_ROOM, (state: SocialAppState, { social }) => {
    state.socialEntity = social;
    state.shared = initialState.shared;
    return state;
  }),

  on(actions.UPDATE_CONNECTED_ROOM_COUNT, (state: SocialAppState, { updatedRoomCounts }) => {
    const oldConnectedRoomCounts = state.connectedRoomCounts ?? {};
    state.connectedRoomCounts = { ...oldConnectedRoomCounts, ...updatedRoomCounts };
    return state;
  }),

  on(actions.LOAD_CONNECTED_ROOM_SET, (state: SocialAppState, { set }) => {
    state.connectedRoomSetEntity = set;
    return state;
  }),

  on(actions.ADD_FULL_CLUSTER, (state: SocialAppState, { groupId }) => {
    if (!state.fullClusters.includes(groupId)) {
      state.fullClusters = [groupId, ...state.fullClusters];
    }
    return state;
  }),

  on(actions.REMOVE_FULL_CLUSTER, (state: SocialAppState, { groupId }) => {
    state.fullClusters = state.fullClusters.filter((c) => c !== groupId);
    return state;
  }),

  on(actions.CHANGE_SUBSCRIPTION_STATUS, (state: SocialAppState, { status }) => {
    const oldSubscriptions = state.subscriptions ?? {};
    state.subscriptions = {
      ...oldSubscriptions,
      [status.id]: status,
    };
    return state;
  }),

  on(actions.REMOVE_SUBSCRIPTION, (state: SocialAppState, { subscriptionId: userId }) => {
    if (state.subscriptions) {
      delete state.subscriptions[userId];
    }
    return state;
  }),

  on(actions.ADD_PARTICIPANT, (state: SocialAppState, { camera, mic, userId }) => {
    state.participants[userId] = {
      videoMuted: !camera,
      audioMuted: !mic,
    };
    return state;
  }),

  on(actions.SET_PARTICIPANT_VOLUME, (state: SocialAppState, { userId, volume }) => {
    state.participants[userId] = {
      ...state.participants[userId],
      volume,
    };
    return state;
  }),

  on(actions.REMOVE_PARTICIPANT, (state: SocialAppState, { userId }) => {
    delete state.participants[userId];
    return state;
  }),

  on(actions.REMOVE_ALL_PARTICIPANTS, (state: SocialAppState) => {
    state.participants = {};
    return state;
  }),

  on(actions.PARTICIPANT_AUDIO_MUTE_CHANGE, (state: SocialAppState, { muted, userId }) => {
    const participant = state.participants[userId];
    if (!participant) {
      return state;
    }
    state.participants[userId].audioMuted = muted;
    return state;
  }),

  on(actions.PARTICIPANT_VIDEO_MUTE_CHANGE, (state: SocialAppState, { muted, userId }) => {
    const participant = state.participants[userId];
    if (!participant) {
      return state;
    }
    participant.videoMuted = muted;
    return state;
  }),

  on(actions.SET_AUDIENCE, (state: SocialAppState, { audience }) => {
    state.audience = audience;
    return state;
  }),

  on(actions.SET_GALLERY, (state: SocialAppState, { gallery }) => {
    state.gallery = gallery;
    return state;
  }),

  on(actions.SET_ARTIFACTS, (state: SocialAppState, { artifacts }) => {
    state.artifacts = artifacts;
    return state;
  }),

  on(actions.SET_CTA_ITEMS, (state: SocialAppState, { ctaItems }) => {
    state.ctaItems = ctaItems;
    return state;
  }),

  on(actions.SET_NAVIGATION_ITEMS, (state: SocialAppState, { navItems }) => {
    state.navItems = navItems;
    return state;
  }),

  on(actions.SET_SOCIAL_SETTINGS, (state: SocialAppState, { settings }) => {
    state.roomSettings = settings;
    return state;
  }),

  on(actions.GET_STARTING_POINT_RSP, (state: SocialAppState, { startingPoint }) => {
    state.startingPoint = startingPoint;
    return state;
  }),

  on(actions.SET_PRESENTATION_STAGE_SIZE, (state: SocialAppState, { stageSize }) => {
    state.presentationStageSize = stageSize;
    return state;
  }),

  on(actions.FETCH_ROOM_SETTINGS_RSP, (state: SocialAppState, { settings }) => {
    const settingsCount = Object.keys(settings.settings ?? {}).length;
    // If there are no settings, use the default values
    state.roomSettings = settingsCount > 0 ? settings : { settings: DEFAULT_SOCIAL_SETTINGS };
    return state;
  }),

  on(actions.SAVE_ROOM_SETTINGS_RSP, (state: SocialAppState, { settings }) => {
    state.roomSettings = settings;
    return state;
  }),

  on(actions.FETCH_GUEST_ROOM_SETTINGS_RSP, (state: SocialAppState, { settings }) => {
    const settingsCount = Object.keys(settings.settings ?? {}).length;
    // If there are no settings, use the default values
    state.guestRoomSettings = settingsCount > 0 ? settings : { settings: {} };
    return state;
  }),

  on(actions.USE_CUSTOMER_API, (state: SocialAppState, { api }) => {
    state.customerAPIEnabled = api;
    return state;
  }),

  on(actions.PUBLISH_STATE_CHANGE, (state: SocialAppState, { connectionState }) => {
    state.publishConnectionState = connectionState;
    return state;
  }),

  on(actions.SET_MIX_VOLUME, (state: SocialAppState, { volume }) => {
    state.mixVolume = volume;
    return state;
  }),

  on(actions.TOGGLE_VIDEO_EFFECTS, (state: SocialAppState, { enabled }) => {
    state.videoEffect.enabled = enabled;
    return state;
  }),

  on(actions.APPLY_VIDEO_EFFECTS, (state: SocialAppState, { effect }) => {
    state.videoEffect = effect;
    return state;
  }),

  on(actions.TOGGLE_CHAT, (state: SocialAppState, { enabled }) => {
    state.chatEnabled = enabled;
    return state;
  }),

  on(actions.OVERRIDE_QUALITY, (state: SocialAppState, { quality }) => {
    state.presenterQualityOverride = quality;
    return state;
  }),

  on(actions.CLEAR_QUALITY_OVERRIDE, (state: SocialAppState) => {
    state.presenterQualityOverride = undefined;
    return state;
  }),

  on(actions.UPDATE_ROOM_SET_RSP, (state: SocialAppState, { roomSet }) => {
    state.connectedRoomSetEntity = roomSet;
    return state;
  }),

  on(actions.TOGGLE_BADGE_OPEN, (state: SocialAppState, { isSelectedInViewport, open, ringColor, userId }) => {
    state.badgeOpen.open = open;
    state.badgeOpen.userId = userId;
    state.badgeOpen.isSelectedInViewport = isSelectedInViewport;
    state.badgeOpen.ringColor = ringColor;
    return state;
  }),

  on(actions.TOGGLE_SEARCH_PARTICIPANTS, (state: SocialAppState, { open }) => {
    state.searchParticipantsOpen = open;
    return state;
  }),

  on(actions.TOGGLE_MAP_OPEN, (state: SocialAppState, { open, userId }) => {
    state.shouldOpenMap.open = open;
    state.shouldOpenMap.userId = userId;
    return state;
  }),

  on(actions.UPDATE_POP_OUT, (state: SocialAppState, { mode, open }) => {
    state.isPopOut.open = open;
    state.isPopOut.mode = mode;
    return state;
  }),

  on(actions.UPDATE_BADGING_MODAL, (state: SocialAppState, { open }) => {
    state.isBadgingModalOpen = open;
    return state;
  }),

  on(actions.VISUAL_INDICATOR, (state: SocialAppState, { visualIndicatorInfo }) => {
    state.visualIndicatorInfo = visualIndicatorInfo;
    return state;
  }),

  on(actions.UPDATE_USER_IS_SPEAKING, (state: SocialAppState, { isSpeaking }) => {
    state.userIsSpeaking = isSpeaking;
    return state;
  }),

  on(actions.UPDATE_ACTIVE_SPEAKERS, (state: SocialAppState, { activeSpeakers }) => {
    state.activeSpeakers = activeSpeakers;
    return state;
  }),

  on(actions.UPDATE_WIDE_RECORDING, (state: SocialAppState, { enabled }) => {
    state.isWideRecording = enabled;
    return state;
  }),

  on(actions.UPDATE_GALLERY_TABS, (state: SocialAppState, { galleryTabs }) => {
    state.galleryTabs = galleryTabs;
    return state;
  }),
);

export const socialAppReducer = (state: SocialAppState | undefined, action: Action): ReturnType<typeof _reducer> =>
  _reducer(state, action);
