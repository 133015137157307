import * as scootaversePageActions from './actions';
import { scootaversePageReducer, initialState as initialScootaversePageState } from './reducer';
import * as selectors from './selectors';

export * from './types';
const scootaversePageSelectors = selectors;
export {
  selectors,
  scootaversePageReducer,
  initialScootaversePageState,
  scootaversePageSelectors,
  scootaversePageActions,
};
