import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { User as FirebaseUser, getAuth } from 'firebase/auth';
import { catchError, from, Observable, of, Subject, switchMap, tap } from 'rxjs';

import { Loggable } from 'app/utils/logging/loggable';
import ClientConfiguration from 'entity/ClientConfiguration';
import User from 'entity/User';
import { GET_USER_IF_EXISTS } from 'rest/constants';
import { RestController } from 'rest/RestController';
import UserController from 'rest/UserController';

import { isDataPassUser } from './guest-auth.service';

const userController = new UserController();

export interface SignedInUser {
  user?: User;
  firebaseUser: FirebaseUser;
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseService extends Loggable {
  private authSubject = new Subject<FirebaseUser | undefined>();
  private newUserSignupSubject = new Subject<User>();
  private signedInUserEmitter?: EventEmitter<SignedInUser>;
  private initialAuth = true;

  public auth$ = this.authSubject.asObservable();
  public firebaseUser?: FirebaseUser;
  public microsoftToken?: string;
  public newUserSignup$ = this.newUserSignupSubject.asObservable();

  public constructor(private http: HttpClient) {
    super();
    this.setDisplayName('FirebaseService');
    this.authSubject.next(undefined);
  }

  public init(config: ClientConfiguration) {
    if (this.isGuestRoute) {
      this.authSubject.next(undefined);
      return;
    }

    initializeApp({
      authDomain: config.firebaseAuthDomain,
      apiKey: config.firebaseApiKey,
    });

    getAuth().onAuthStateChanged(this.handleAuthStateChanged.bind(this));
  }

  /**
   * Sign the user out of firebase
   */
  public signOut(): Promise<void> {
    this.firebaseUser = undefined;
    this.authSubject.next(undefined);
    return getAuth().signOut();
  }

  public async getAuthToken(): Promise<string> {
    const token = await this.firebaseUser?.getIdToken();
    return token ? `Firebase ${token}` : '';
  }

  public get authenticated(): boolean {
    return !!this.firebaseUser;
  }

  private get isGuestRoute(): boolean {
    return window?.location.pathname === '/web/guest';
  }

  private userAuthenticated(user: FirebaseUser) {
    this.authSubject.next(user);
  }

  /**
   * Get the user associated with a bearer token.
   *
   * XXX: This must be use instead of the RestController infrastructure. This guarantees which token is used for user
   * verification. The rest controller will use the first token provided by any authentication service.
   * @param token Bearer token
   * @returns An observable of the guest user or null
   */
  private getCurrentUser(token: string): Observable<User | null> {
    const authorization = `Firebase ${token}`;
    const AppVersion = RestController.version;
    const headers = new HttpHeaders({ authorization, AppVersion });
    return this.http.get<User | null>(GET_USER_IF_EXISTS, { headers });
  }

  /**
   * Handle firebase auth state change.
   * @param firebaseUser The firebase user that authenticated, or null if the user is not authenticated.
   * @returns
   */
  private handleAuthStateChanged(firebaseUser: FirebaseUser | null): void {
    if (this.isGuestRoute) {
      this.authSubject.next(undefined);
      return;
    }
    this.firebaseUser = firebaseUser ?? undefined;

    // Skip auth if the user is provided via data pass. Sign out of existing users.
    // Only do this for the initial authentication pass. After that, the user should be able to sign-in normally.
    if (this.initialAuth) {
      this.initialAuth = false;
      if (isDataPassUser()) {
        void this.signOut();
        return;
      }
    }

    if (!firebaseUser) {
      this.authSubject.next(undefined);
      return;
    }

    this.info('firebase authenticated', firebaseUser);

    from(firebaseUser.getIdToken())
      .pipe(
        switchMap((token) => this.getCurrentUser(token)),
        catchError((e: HttpErrorResponse): Observable<User | null> => {
          // The token may be expired. Try to refresh it if so.
          // This may happen if the user's time is incorrect.
          if (e.error === 'Expired token') {
            return from(firebaseUser.getIdToken(true)).pipe(switchMap((token) => this.getCurrentUser(token)));
          }
          throw e;
        }),
        switchMap((user: User | null) =>
          user ? of(user) : from(userController.createFirebaseUser(this.microsoftToken)),
        ),
      )
      .subscribe({
        next: (user) => {
          this.userAuthenticated(firebaseUser);
          this.signedInUserEmitter?.emit({ user, firebaseUser });
        },
        error: (error) => {
          this.error('An error occurred while processing firebase user', error);
          // If we've errored at this point, the user is not authenticated via firebase and can reauthenticate.
          this.authSubject.next(undefined);
        },
      });
  }

  public createFirebaseUser(): Observable<User> {
    return from(userController.createFirebaseUser(this.microsoftToken)).pipe(
      tap(() => {
        if (this.firebaseUser) {
          this.userAuthenticated(this.firebaseUser);
        }
      }),
    );
  }

  public setSignedInUserEmitter(emitter: EventEmitter<SignedInUser>): void {
    this.signedInUserEmitter = emitter;
  }

  public reinitAuth(): void {
    this.firebaseUser = undefined;
    void getAuth().signOut();
  }
}

export enum FirebaseErrors {
  'Firebase: Error (auth/account-exists-with-different-credential).' = "You've already registered with Scoot with a different provider",
  'Firebase: Error (auth/user-not-found).' = 'This email is not registered with Scoot',
  'Firebase: Error (auth/email-already-in-use).' = 'This email is already in use',
  'Firebase: Error (auth/invalid-email).' = 'This email is invalid',
  'Firebase: Error (auth/weak-password).' = 'Your password is too weak',
  'Firebase: Error (auth/wrong-password).' = 'Your password is incorrect',
  'Firebase: Error (auth/user-disabled).' = 'Your account has been disabled',
  'Firebase: Error (auth/too-many-requests).' = 'Too many requests. Please try again later',
  'Firebase: Error (auth/operation-not-allowed).' = 'This operation is not allowed',
}
