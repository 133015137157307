// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import CompanyType from "enums/CompanyType";
import Util from "PreciateUtil";

export default interface Company {
    companyId?: string;
    type?: CompanyType;
    companyName?: string;
    companyProfileId?: string;
    companyWebLink?: string;
    companyJobsLink?: string;
    description?: string;
    address1?: string;
    address2?: string;
    city?: string;
    stateCode?: string;
    zipCode?: string;
    emailAddress?: string;
    emailVerified?: boolean;
    emailDomain?: string;
    emailDomainVerified?: boolean;
    wildcardDomain?: boolean;
    ownerUserId?: string;
    mobilePhone?: string;
    mobilePhoneVerified?: boolean;
    fullAddress?: string;
    companyImageId?: string;
    companyImageURI?: string;
    companyImageBackground?: string;
    coverImageId?: string;
    coverImageURI?: string;
    logoImageId?: string;
    logoImageURI?: string;
    registeredDateTime?: Date;
    trackChurnzero?: boolean;
    active?: boolean;
    isPrivate?: boolean;
    emailVerificationPending?: boolean;
}

export function processCompany(value?: Company): void {
  if (value) {
    value.registeredDateTime = Util.toDate((value as any).registeredDateTime);
  }
}

export function processCompanyArray(array?: Company[]): void {
  if (array) {
    for (const v of array) {
      processCompany(v);
    }
  }
}
