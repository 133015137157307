<div class="simple-message {{ mode }} {{ type ? 'type-' + type : '' }}">
  <div class="color-bar"></div>
  @if (icon && isMatIcon) {
    <span class="simple-message-icon">
      <mat-icon>{{ icon }}</mat-icon>
    </span>
  } @else if (icon && !isMatIcon) {
    <span class="simple-message-icon">
      <mat-icon svgIcon="{{ icon }}"></mat-icon>
    </span>
  }
  <div matSnackBarLabel>
    <span class="simple-message-title" [innerHTML]="bodyMessage"> </span>
  </div>
</div>
