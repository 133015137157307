import { createSelector } from '@ngrx/store';

import { AppState } from 'app/types';

import { initialState } from './reducer';
import { MediaState, SimpleMediaDeviceInfo } from './types';

export const getMediaState = (state: AppState): MediaState => state.social?.media ?? initialState;

export const getVideoEnabled = (state: AppState): boolean => getMediaState(state).videoEnabled;
export const getAudioEnabled = (state: AppState): boolean => getMediaState(state).audioEnabled;

export const getCameras = createSelector(
  getMediaState,
  (mediaState?: MediaState): SimpleMediaDeviceInfo[] => mediaState?.cameras ?? [],
);
export const getSelectedCamera = createSelector(
  getMediaState,
  (mediaState?: MediaState): SimpleMediaDeviceInfo | null => mediaState?.selectedCamera ?? null,
);
export const getCameraState = createSelector(
  getCameras,
  getSelectedCamera,
  (cameras: SimpleMediaDeviceInfo[], selectedCamera: SimpleMediaDeviceInfo | null) => ({ selectedCamera, cameras }),
);

export const getReadableCameras = createSelector(getMediaState, ({ readableCameras }) => readableCameras);

export const areAllCamerasUnreadable = createSelector(getReadableCameras, (cameras): boolean => {
  // Check all of the cameras. If there are no cameras, all cameras are unreadable.
  const states = Object.values(cameras);
  if (states.length === 0) {
    return false;
  }
  return Object.values(cameras).every((readable) => !readable);
});

export const isCameraDisabled = createSelector(
  getCameras,
  areAllCamerasUnreadable,
  (cameras: SimpleMediaDeviceInfo[], allUnreadable: boolean): boolean => cameras.length === 0 || allUnreadable,
);

export const getMics = createSelector(
  getMediaState,
  (mediaState?: MediaState): SimpleMediaDeviceInfo[] => mediaState?.mics ?? [],
);
export const getSelectedMic = createSelector(
  getMediaState,
  (mediaState?: MediaState): SimpleMediaDeviceInfo | null => mediaState?.selectedMic ?? null,
);
export const getMicState = createSelector(
  getMics,
  getSelectedMic,
  (mics: SimpleMediaDeviceInfo[], selectedMic: SimpleMediaDeviceInfo | null) => ({ selectedMic, mics }),
);

export const getSpeakers = createSelector(
  getMediaState,
  (mediaState?: MediaState): SimpleMediaDeviceInfo[] => mediaState?.speakers ?? [],
);
export const getSelectedSpeaker = createSelector(
  getMediaState,
  (mediaState?: MediaState): SimpleMediaDeviceInfo | null => mediaState?.selectedSpeaker ?? null,
);
export const getSpeakerState = createSelector(
  getSpeakers,
  getSelectedSpeaker,
  (speakers: SimpleMediaDeviceInfo[], selectedSpeaker: SimpleMediaDeviceInfo | null) => ({ selectedSpeaker, speakers }),
);

export const isVideoEnabled = createSelector(getMediaState, ({ videoEnabled }) => videoEnabled);
export const isMicEnabled = createSelector(getMediaState, ({ audioEnabled }) => audioEnabled);

export const isCurrentCameraReadable = createSelector(
  getSelectedCamera,
  getReadableCameras,
  (camera, readableCameras): boolean => {
    // Imply that the camera is readable if we don't know about it. A non-readable camera is a notable error and
    // stimulates modals.
    return readableCameras[camera?.deviceId ?? ''] ?? true;
  },
);
