import { Injectable } from '@angular/core';

import TermsController from 'rest/TermsController';

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  private termsController: TermsController;
  public constructor() {
    this.termsController = new TermsController();
  }

  public acceptTerms(): Promise<void> {
    return this.termsController.acceptTerms();
  }

  public didAcceptTerms(): Promise<boolean> {
    return this.termsController.didAcceptTerms();
  }
}
