export enum MiniMapState {
  CLOSED,
  OPENED,
  EXPANDED,
  MOBILE,
  /**
   * @deprecated since scoot_home_v1
   */
  NONE,
}
