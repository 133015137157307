// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import RoleType from "enums/RoleType";
import User, { processUser } from "./User";

export default interface Employee {
    user?: User;
    role?: RoleType;
    current?: boolean;
    blocked?: boolean;
}

export function processEmployee(value?: Employee): void {
  if (value) {
    processUser(value.user);
  }
}

export function processEmployeeArray(array?: Employee[]): void {
  if (array) {
    for (const v of array) {
      processEmployee(v);
    }
  }
}
