<div class="blocker-container">
  <div class="left-side flex justify-center items-center">
    <img role="presentation" src="assets/images/terms-blocker.svg" />
  </div>
  <div class="right-side flex flex-column justify-between items-center p-40">
    <img role="presentation" src="assets/images/logos/scoot_logo_primary.png" height="50" />
    <div class="blocker-content flex flex-column justify-center items-center">
      <span class="font-xl weight-bold m-b-20 alignt-center"> We’ve updated our Terms of Service </span>
      <span class="m-b-64 alignt-center">Accept the updated terms to continue to Scoot</span>
      <a rel="noopener" class="weight-medium" href="https://scoot.app/terms-of-use-agreement" target="_blank"
        >Review Updated terms</a
      >
    </div>
    <button mat-button class="button dark-gray" (click)="onAcceptButtonClicked()">Accept and Continue</button>
  </div>
</div>
