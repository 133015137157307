// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT DIRECTLY.

import Util from "PreciateUtil";

export default interface User {
    userId?: string;
    name?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    emailAddress?: string;
    emailVerified?: boolean;
    preload?: boolean;
    mobilePhone?: string;
    mobilePhoneVerified?: boolean;
    mobilePhoneVerifiedWeb?: boolean;
    profileId?: string;
    profileImageURI?: string;
    profileImageId?: string;
    profileImageBackground?: string;
    timeZone?: string;
    registeredDateTime?: Date;
    active?: boolean;
    actedAs?: boolean;
    socialId?: string;
}

export function processUser(value?: User): void {
  if (value) {
    value.registeredDateTime = Util.toDate((value as any).registeredDateTime);
  }
}

export function processUserArray(array?: User[]): void {
  if (array) {
    for (const v of array) {
      processUser(v);
    }
  }
}
