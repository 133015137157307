import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { RecaptchaModule } from 'ng-recaptcha';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';

import { CommonsModule } from '../commons/commons.module';
import { CoreModule } from '../core/core.module';
import { DeepLinkNavService } from '../modules/deep-link/deepLinkNav.service';

import { SharedCropperComponent } from './cropper/cropper-component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { SimpleMessageComponent } from './modals/components/simple-message/simple-message.component';
import { ModalComponent } from './modals/modal-component/modal.component';
import { ModalEventService } from './modals/modal-event.service';
import { ModalSimpleMessageService } from './modals/modal-simple-message.service';
import { ModalService } from './modals/modal.service';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { SocialMediaShareService } from './services/social-media-share.service';
import { SharedRouterModule } from './shared-router.module';
import { SnackBarComponent } from './snackbar/snackbar.component';
import { SocialMediaShareComponent } from './social-media-share/social-media-share.component';
import { SocialMediaShareModalComponent } from './social-media-share-modal/social-media-share-modal.component';
import { UpdatedTermsBlockerComponent } from './updated-terms-blocker/updated-terms-blocker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxMaskModule,
    MatDialogModule,
    MatGridListModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    MatMenuModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    SharedRouterModule,
    ImageCropperModule,
    MatTabsModule,
    CommonsModule,
    CoreModule,
    MatSnackBarModule,
    PortalModule,
    OverlayModule,
    MatIconModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
  ],
  declarations: [
    ModalComponent,
    ModalErrorComponent,
    ModalConfirmComponent,
    SharedCropperComponent,
    PhoneFormatPipe,
    SimpleMessageComponent,
    SocialMediaShareComponent,
    SocialMediaShareModalComponent,
    UpdatedTermsBlockerComponent,
    SnackBarComponent,
  ],

  exports: [
    ModalComponent,
    ModalErrorComponent,
    ModalConfirmComponent,
    PhoneFormatPipe,
    SocialMediaShareComponent,
    SocialMediaShareModalComponent,
    SnackBarComponent,
  ],

  providers: [ModalService, ModalEventService, ModalSimpleMessageService, SocialMediaShareService, DeepLinkNavService],
})
export class SharedModule {}
