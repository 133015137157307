export const EVENT_GUIDE_ITEMS = [
  {
    iconPath: '/assets/images/home-page/cheers.svg',
    name: 'Networking Event',
    redirectTo: 'https://join.preciate.com/virtual-networking',
    redirectText: 'Learn More',
  },
  {
    iconPath: '/assets/images/home-page/group.svg',
    name: 'Team Building Event',
    redirectTo: 'https://join.preciate.com/virtual-team-building',
    redirectText: 'Learn More',
  },
  {
    iconPath: '/assets/images/home-page/sales.svg',
    name: 'Sales Meeting',
    redirectTo: 'https://join.preciate.com/remote-sales',
    redirectText: 'Learn More',
  },
  {
    iconPath: '/assets/images/home-page/stage.svg',
    name: 'All Hands Meeting',
    redirectTo: 'https://join.preciate.com/executive-communications',
    redirectText: 'Learn More',
  },
  {
    iconPath: '/assets/images/home-page/send.svg',
    name: 'Onboarding Session',
    redirectTo: 'https://join.preciate.com/virtual-recruiting-and-onboarding',
    redirectText: 'Learn More',
  },
  {
    iconPath: '/assets/images/home-page/premium.svg',
    name: 'Interactive Training Event',
    redirectTo: 'https://join.preciate.com/interactive-training',
    redirectText: 'Learn More',
  },
];

export const EVENT_GUIDE_TITLE = 'How You Can Use Preciate';
export const EVENT_GUIDE_SUB_TITLE =
  'Preciate makes meetings and events more dynamic and life-like to build team cohesion, elevate trust, and build stronger relationships.';

export const NEW_BRAND_EVENT_GUIDE_TITLE = 'How You Can Use Scoot';
export const NEW_BRAND_EVENT_GUIDE_SUB_TITLE =
  'Scoot makes meetings and events more dynamic and life-like to build team cohesion, elevate trust, and build stronger relationships.';

export const ROOM_ARTIFACT_SET = [
  {
    id: 'cf3f2d6b-0375-47c2-bd06-6bd0b2715b7c',
    name: 'All Hands Meeting',
  },
  {
    id: 'a21c5ea7-04d1-4e3d-bfee-786987ea6a74',
    name: 'Team Building',
  },
  {
    id: '7b5b68d6-4dad-4204-be00-f287518880fd',
    name: 'Presentation',
  },
  {
    id: '442e00a6-b3b0-4aa7-837a-51c27eb40e9a',
    name: 'Social Event',
  },
  {
    id: '1aa064e3-c91d-43d5-9aed-73e9d1d2f4ab',
    name: 'Recruiting Room',
  },
  {
    id: '0ff25824-abec-4392-8ef4-3522423ab54b',
    name: 'Innovation Room',
  },
];
