import { ComponentPortal, Portal, CdkPortalOutlet } from '@angular/cdk/portal';
import { Component, OnInit, Inject, ComponentRef, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, takeUntil } from 'rxjs';

import { ModalEventService } from '../modal-event.service';

@Component({
  selector: 'app-modal-component',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild(CdkPortalOutlet, { read: CdkPortalOutlet, static: true }) public child?: CdkPortalOutlet;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectedPortal: Portal<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentPortal?: ComponentPortal<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentRef?: ComponentRef<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public result: any;
  public actionButtonTitle = 'Done';
  public secondaryButtonTitle = 'Deny';
  public tertiaryButtonTitle = '';
  public tertiaryLink = '#';
  public showDone = true;
  public showSecondaryButton = false;
  public showTertiaryButton = false;
  public showActions = true;
  public showClose = true;
  public emitOnClose = false;
  public hideClose: boolean;
  public alignTitle = 'center';

  public title: string;
  public titleIcon: string;
  public newModalStyle = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: ModalEventService,
  ) {
    this.title = data.title;
    this.titleIcon = data.titleIcon;
    this.newModalStyle = data.newModalStyle;
    this.selectedPortal = data.body;
    if (data.actionButtonTitle) {
      this.actionButtonTitle = data.actionButtonTitle;
    }
    if (data.secondaryButtonTitle) {
      this.secondaryButtonTitle = data.secondaryButtonTitle;
    }
    if (data.tertiaryButtonTitle) {
      this.tertiaryButtonTitle = data.tertiaryButtonTitle;
    }
    if (data.tertiaryLink) {
      this.tertiaryLink = data.tertiaryLink;
    }

    if (data.showDone != null) {
      this.showDone = data.showDone;
    }
    if (data.showSecondaryButton != null) {
      this.showSecondaryButton = data.showSecondaryButton;
    }
    if (data.showTertiaryButton != null) {
      this.showTertiaryButton = data.showTertiaryButton;
    }
    if (data.showActions != null) {
      this.showActions = data.showActions;
    }
    if (data.showClose != null) {
      this.showClose = data.showClose;
    }
    if (data.alignTitle) {
      this.alignTitle = data.alignTitle;
    }
    this.emitOnClose = data.emitOnClose;
    this.hideClose = data.hideClose;
  }

  public ngOnInit(): void {
    this.eventService.push();
    this.eventService
      .peek()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val) => {
        this.result = val;
      });
  }

  public isButtonDisabled(): boolean {
    if ('disableButton' in this.data) {
      return this.data.disableButton;
    }

    if (this.result == null) {
      return true;
    }
    return false;
  }

  public onClick(): void {
    this.dialogRef.close(this.result);
  }

  public close(): void {
    if (this.emitOnClose) {
      this.dialogRef.close(this.result);
    } else {
      this.dialogRef.close();
    }
  }

  public ngOnDestroy(): void {
    if (this.emitOnClose) {
      this.dialogRef.close(this.result);
    }
    this.eventService.pop();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
