import { createSelector } from '@ngrx/store';

import { AppState } from 'app/types';

import { initialState } from './reducer';
import { RecordingPageState } from './types';

export const getRecordingPageState = (state: AppState): RecordingPageState => state.recordingPage ?? initialState;

/**
 * Get the paginated recordings state
 */
export const getRecordings = (state: AppState) => getRecordingPageState(state).recordings;

/**
 * Get the current recordings list
 */
export const getRecordingsData = createSelector(getRecordings, (state: RecordingPageState['recordings']) => state.data);

export const isLoadingRecordings = createSelector(
  getRecordings,
  (state: RecordingPageState['recordings']) => state.loading,
);
