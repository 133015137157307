import { createSelector } from '@ngrx/store';

import { AppState } from 'app/types';
import Social from 'entity/Social';

import { initialState } from './reducer';
import { HomePageState } from './types';

type SavedRooms = HomePageState['savedRooms'];

export const getHomePageState = (state: AppState): HomePageState => state.social?.homePage ?? initialState;

// #region SavedRooms
export const getSavedRooms = createSelector(getHomePageState, (state: HomePageState) => state.savedRooms);
export const isSavedRoomsLoading = createSelector(getSavedRooms, ({ loading }: SavedRooms) => loading);
export const getMyRooms = createSelector(getSavedRooms, (savedRooms: SavedRooms): Social[] => savedRooms.data);
// #endregion

export const isHomePageLoading = createSelector(
  getHomePageState,
  (homePageState: HomePageState): boolean => homePageState.loading,
);

export const socialSettingsHasError = createSelector(
  getHomePageState,
  (homePageState: HomePageState): boolean =>
    (homePageState.saveSettingsError || homePageState.editRoomError || homePageState.saveAlternateHostsError) ?? false,
);

export const editRoomError = createSelector(
  getHomePageState,
  (homePageState: HomePageState): boolean => homePageState.editRoomError ?? false,
);

export const deleteRoomError = createSelector(
  getHomePageState,
  (homePageState: HomePageState): boolean => homePageState.deleteRoomError ?? false,
);

export const deleteRoomSuccess = createSelector(
  getHomePageState,
  (homePageState: HomePageState): boolean => homePageState.deleteRoomSuccess ?? false,
);

export const socialSettingsSuccess = createSelector(
  getHomePageState,
  (homePageState: HomePageState): boolean =>
    (homePageState.saveSettingsSuccess && homePageState.editRoomSuccess && homePageState.saveAlternateHostsSuccess) ??
    false,
);

// #region AlternateHosts
export const getAlternateHostCandidates = createSelector(
  getHomePageState,
  (state: HomePageState) => state.alternateHostCandidates,
);
export const isAlternatesHostCandidatesLoading = createSelector(getSavedRooms, ({ loading }: SavedRooms) => loading);
// #endregion
