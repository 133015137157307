<!-- TODO: update modal design -->
<div *ngIf="!newModalStyle">
  <div [ngClass]="data?.customModalClass ? data.customModalClass : ''">
    <div class="close-button-container" align="right" *ngIf="showClose">
      <mat-icon class="close-button mat-icon" aria-hidden="true" data-mat-icon-type="font" (click)="close()"
        >close</mat-icon
      >
    </div>
    <div class="title-section" *ngIf="title">
      <h2 mat-dialog-title [ngStyle]="{ 'text-align': alignTitle }">{{ title }}</h2>
    </div>
    <div tabindex="0" class="template-container">
      <i class="modal-icon"></i>
      <div class="modal-template">
        <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
      </div>
    </div>
    <div mat-dialog-actions *ngIf="showActions">
      <button class="btn-action btn-deny" data-testid="close-button" mat-button (click)="close()">Cancel</button>
      <button
        id="btnConfirm"
        class="btn-action btn-confirm"
        data-testid="confirm-button"
        mat-button
        (click)="onClick()"
        [disabled]="isButtonDisabled()"
        [ngStyle]="{ color: data.actionButtonColor || '#333' }"
        *ngIf="showDone"
      >
        {{ actionButtonTitle }}
      </button>
    </div>
  </div>
</div>

<div *ngIf="newModalStyle">
  <div class="modal-component" [ngClass]="data?.customModalClass ? data.customModalClass : ''">
    <div class="title-wrap" [ngClass]="titleIcon ? 'title-wrap-center' : ''">
      <div class="title-text-wrap">
        <div *ngIf="titleIcon">
          <mat-icon class="text-icon-title" svgIcon="{{ titleIcon }}"></mat-icon>
        </div>
        <p *ngIf="title" [ngClass]="titleIcon ? 'center-title' : ''">{{ title }}</p>
      </div>
      <div class="new-close-button-container" align="right" *ngIf="showClose">
        <mat-icon class="new-close-button mat-icon" aria-hidden="true" data-mat-icon-type="font" (click)="close()"
          >close</mat-icon
        >
      </div>
    </div>

    <div tabindex="0" class="social-modal-container template-container">
      <i class="modal-icon"></i>
      <div class="modal-template" [ngStyle]="titleIcon ? { 'text-align': 'center' } : {}">
        <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
      </div>
    </div>

    <div class="social-action-modal-footer" *ngIf="showActions">
      <div>
        <a
          href="{{ tertiaryLink }}"
          data-testid="help-button"
          target="_blank"
          rel="noopener"
          *ngIf="showTertiaryButton"
          class="custom-link-button m-t-16 text-color-secondary"
        >
          {{ tertiaryButtonTitle }}
        </a>
      </div>
      <div>
        <button
          id="btnConfirm"
          class="primary-button"
          data-testid="confirm-button"
          (click)="onClick()"
          [disabled]="isButtonDisabled()"
          [ngStyle]="{ color: data.actionButtonColor || 'white' }"
          *ngIf="showDone"
        >
          {{ actionButtonTitle }}
        </button>
        <button *ngIf="showSecondaryButton" class="secondary-button" data-testid="close-button" (click)="close()">
          {{ secondaryButtonTitle }}
        </button>
      </div>
    </div>
  </div>
</div>
