let useImageCdn = false;

// Determine if this browser supports webp.
const getImageExtension = (): string => {
  const elem = document.createElement('canvas');
  try {
    if (elem?.getContext('2d') && elem.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      return '.webp';
    }
  } catch (e) {
    // SSR. Don't use isPlatformServer() here, because that doesn't seem to work.
    if (typeof window === 'undefined') {
      return '.webp';
    }
  }

  return '.png';
};

export const NATIVE_EXTENSION = getImageExtension();

/**
 * Set if the new image CDN should be used
 * @param value Boolean value to determine if we should use the image cdn.
 */
export const setUseImageCdn = (value: boolean) => {
  useImageCdn = value;
};

/**
 * Get a resized image URI
 *
 * @returns A resized image URI
 */
export const getResizeUri = ({
  height: heightParam,
  uri,
  width,
}: {
  uri: string;
  width: number | string;
  height?: number | string;
}): string => {
  const height = heightParam ?? width;

  if (useImageCdn) {
    return `${uri}?w=${width}&h=${height}`;
  }
  return `${uri}/s/${width}x${height}${NATIVE_EXTENSION}`;
};
